//orders
import {
  DateFormatter,
  OrderStatusFormatter,
  OrderAmount,
  UserInfoCell,
  PaymentStatusFormatter,
  TransactionStatusFormatter,
  TransactionTypeFormatter,
  ProductInfoCell,
  CurrencyColumnFormater,
  RaffleTicketFormatter,
  WithdrawStatusFormatter,
  PermissionsFormatter,
  TrackingTimelineFormatter,
  TrackingStatusFormatter,
} from '../../helpers'
import {
  EventActionCell,
  OrderActionCell,
  ProductActionCell,
  ProductCategoryActionCell,
  RaffleActionCell,
  RaffleCategoryActionCell,
  RolesActionCell,
  TrackingActionCell,
  UserActionCell,
  WithdrawReqActionCell,
  CountryActionCell,
} from './ColumnsActions'
import {TrackingStatus} from '../../models/Orders'

const CustomersColumn = [
  {
    name: 'Profile',
    cell: (row: any) => <UserInfoCell user={row} />,
    grow: 3,
  },
  {
    name: 'Email',
    selector: (row: any) => row?.email,
    sortable: true,
  },
  {
    name: 'Phone',
    cell: (row: any) => row?.phone,
  },
  {
    name: 'Country',
    cell: (row: any) => row?.country,
    sortable: true,
  },
  {
    name: 'Role',
    selector: (row: any) => row?.role?.name,
    sortable: true,
  },
  {
    name: 'Verified',
    selector: (row: any) => (row?.verified ? `Yes` : `No`),
    sortable: true,
  },
  {
    name: 'Status',
    selector: (row: any) => (row?.status ? `Yes` : `No`),
    sortable: true,
  },
  {
    cell: (row: any) => <UserActionCell id={row?.id} />,
    sortable: true,
  },
]
const OrdersColumn = [
  {
    name: 'Tracking',
    selector: (row: any) => row?.tracking?.tracking_number,
    sortable: true,
  },
  {
    name: 'Date',
    cell: (row: any) => <DateFormatter date={row?.created} />,
  },
  {
    name: 'Customer',
    cell: (row: any) => <UserInfoCell user={row?.user ? row?.user : row} />,
    grow: 3,
  },
  {
    name: 'Fulfillment',
    cell: (row: any) => <OrderStatusFormatter status={row?.status} />,
    sortable: true,
  },
  {
    name: 'Payment',
    cell: (row: any) => <PaymentStatusFormatter transaction={row?.transaction} />,
    sortable: true,
  },
  {
    name: 'Total',
    cell: (row: any) => <OrderAmount transaction={row?.transaction} />,
    sortable: true,
  },
  {
    cell: (row: any) => <OrderActionCell id={row?.id} />,
    sortable: true,
  },
]
const ProductsColumn = [
  {
    name: 'Title',
    cell: (row: any) => <ProductInfoCell product={row} />,
    sortable: true,
    wrap: true,
    grow: 3,
  },
  {
    name: 'Collection',
    selector: (row: any) => row?.category?.name,
    sortable: true,
  },
  {
    name: 'Price',
    cell: (row: any) => <CurrencyColumnFormater amount={row?.product_price} />,
    sortable: true,
  },
  {
    name: 'Featured',
    selector: (row: any) => (row?.featured ? `Yes` : `No`),
  },
  {
    name: 'Availability',
    selector: (row: any) =>
      row.availability === 1 ? `Website` : row.availability === 2 ? `App` : `All`,
    sortable: true,
  },
  {
    name: 'Inventory',
    selector: (row: any) => `${row?.stock} in stock`,
    sortable: true,
  },
  {
    name: 'Raffle Draw',
    cell: (row: any) => <RaffleTicketFormatter raffle={row?.raffle} />,
    sortable: true,
  },
  {
    name: 'Availability',
    selector: (row: any) => row.availability,
    sortable: true,
  },
  {
    cell: (row: any) => <ProductActionCell id={row?.id} />,
    sortable: true,
  },
]
const TransactionsColumn = [
  {
    name: 'User',
    cell: (row: any) => <UserInfoCell user={row?.user ? row?.user : row} />,
    sortable: true,
  },
  {
    name: 'Amount',
    cell: (row: any) => <CurrencyColumnFormater amount={row?.amount} />,
    sortable: true,
  },
  {
    name: 'Reference',
    selector: (row: any) => row?.reference,
    sortable: true,
  },
  {
    name: 'Type',
    cell: (row: any) => <TransactionTypeFormatter type={row?.type} />,
    sortable: true,
  },
  {
    name: 'Status',
    cell: (row: any) => <TransactionStatusFormatter status={row?.status} />,
    sortable: true,
  },
  {
    name: 'Date',
    cell: (row: any) => <DateFormatter date={row?.created} />,
  },
]
const WithdrawReqColumn = [
  {
    name: 'User',
    cell: (row: any) => <UserInfoCell user={row?.user} />,
    sortable: true,
  },
  {
    name: 'Amount',
    cell: (row: any) => <CurrencyColumnFormater amount={row?.amount} />,
    sortable: true,
  },
  {
    name: 'Reference',
    selector: (row: any) => row?.reference.split(`/`)[0],
    sortable: true,
  },
  {
    name: 'Reason',
    selector: (row: any) => row?.reference.split(`/`)[1],
    sortable: true,
  },
  {
    name: 'Type',
    cell: (row: any) => <TransactionTypeFormatter type={row?.type} />,
    sortable: true,
  },
  {
    name: 'Status',
    cell: (row: any) => <WithdrawStatusFormatter status={row?.status} />,
    sortable: true,
  },
  {
    name: 'Date',
    cell: (row: any) => <DateFormatter date={row?.created} />,
  },
  {
    cell: (row: any) => <WithdrawReqActionCell id={row?.id} />,
  },
]
const TrackingColumn = [
  {
    name: 'Tracking Number',
    selector: (row: any) => row?.tracking_number,
    sortable: true,
  },
  {
    name: 'Status',
    cell: (row: any) => <TrackingStatusFormatter status={row?.order[0]?.status || 1} />,
    sortable: true,
  },
  {
    name: 'User',
    selector: (row: any) => row?.order[0]?.user?.email || '',
    sortable: true,
  },
  {
    name: 'Last Updated',
    cell: (row: any) => <DateFormatter date={row?.updated} />,
  },
  {
    cell: (row: any) => <TrackingActionCell id={row?.tracking_number} />,
    sortable: true,
  },
]
const TrackingTimelineColumn = [
  {
    name: 'Status',
    cell: (row: any) => <TrackingTimelineFormatter status={row?.status} />,
    sortable: true,
  },
  {
    name: 'Comment',
    selector: (row: any) => row?.comment,
    sortable: true,
  },
  {
    name: 'Location',
    selector: (row: any) => row?.location,
    sortable: true,
  },
  {
    name: 'Created On',
    cell: (row: any) => <DateFormatter date={row?.created} />,
  },
  {
    name: 'Last Updated On',
    cell: (row: any) => <DateFormatter date={row?.updated} />,
  },
]
const RaffleColumn = [
  {
    name: 'Name',
    selector: (row: any) => row?.ticket_name,
    sortable: true,
  },
  {
    name: 'Tracking',
    selector: (row: any) => row?.ticket_tracking,
    sortable: true,
  },
  {
    name: 'Featured',
    selector: (row: any) => (row?.featured ? `yes` : `no`),
    sortable: true,
  },
  {
    name: 'Category',
    selector: (row: any) => row?.category?.name,
    sortable: true,
  },
  {
    name: 'Start Date',
    cell: (row: any) => <DateFormatter date={row?.start_date} />,
    sortable: true,
  },
  {
    name: 'End Date',
    cell: (row: any) => <DateFormatter date={row?.end_date} />,
  },
  {
    name: 'Entries',
    cell: (row: any) => row?.entries,
  },
  {
    name: 'Status',
    cell: (row: any) => (row?.status === 1 ? `active` : `inactive`),
  },
  {
    cell: (row: any) => <RaffleActionCell id={row?.id} />,
  },
]
const ProductCategoryColumn = [
  {
    name: 'Name',
    selector: (row: any) => row?.name,
    sortable: true,
  },
  {
    name: 'Description',
    selector: (row: any) => row?.description,
    sortable: true,
  },
  {
    name: 'Status',
    selector: (row: any) => (row?.status ? `active` : `inactive`),
    sortable: true,
  },
  {
    name: 'Created On',
    selector: (row: any) => <DateFormatter date={row?.created} />,
    sortable: true,
  },
  {
    cell: (row: any) => <ProductCategoryActionCell id={row?.id} />,
    sortable: true,
  },
]
const RaffleCategoryColumn = [
  {
    name: 'Name',
    selector: (row: any) => row?.name,
    sortable: true,
  },
  {
    name: 'Description',
    selector: (row: any) => row?.description,
    sortable: true,
  },
  {
    name: 'Status',
    selector: (row: any) => (row?.status ? `active` : `inactive`),
    sortable: true,
  },
  {
    name: 'Created On',
    selector: (row: any) => <DateFormatter date={row?.created} />,
    sortable: true,
  },
  {
    cell: (row: any) => <RaffleCategoryActionCell id={row?.id} />,
    sortable: true,
  },
]
const RaffleResultColumn = [
  {
    name: 'Ticket Name',
    selector: (row: any) => row?.raffledraw?.ticket_name,
    sortable: true,
  },
  {
    name: 'Ticket Winner',
    cell: (row: any) => <UserInfoCell user={row?.winner?.user} />,
    sortable: true,
  },
  {
    name: 'Status',
    selector: (row: any) => (row?.type === 1 ? `winner` : `no winner`),
    sortable: true,
  },
  {
    name: 'Created On',
    selector: (row: any) => <DateFormatter date={row?.created} />,
    sortable: true,
  },
]
const ReviewsColumn = [
  {
    name: 'User',
    cell: (row: any) => <UserInfoCell user={row?.user} />,
    sortable: true,
  },
  {
    name: 'Comment',
    selector: (row: any) => row?.comment,
    sortable: true,
  },
  {
    name: 'Rating',
    selector: (row: any) => row?.rating,
    sortable: true,
  },
  {
    name: 'Created On',
    selector: (row: any) => <DateFormatter date={row?.created} />,
    sortable: true,
  },
]
const EventsColumn = [
  {
    name: 'Title',
    selector: (row: any) => row?.event_name,
    sortable: true,
  },
  {
    name: 'Message',
    selector: (row: any) => row?.event_description,
    sortable: true,
  },
  {
    name: 'Type',
    selector: (row: any) => (row?.type === 2 ? `System` : `User`),
    sortable: true,
  },
  {
    name: 'Status',
    selector: (row: any) => (row?.status === 0 ? `Unread` : `Read`),
    sortable: true,
  },
  {
    name: 'Created On',
    selector: (row: any) => <DateFormatter date={row?.created} />,
    sortable: true,
  },
  {
    cell: (row: any) => <EventActionCell id={row?.id} />,
    sortable: true,
  },
]
const ParticipantsColumn = [
  {
    name: 'Profile',
    cell: (row: any) => <UserInfoCell user={row?.user} />,
    sortable: true,
  },
  {
    name: 'Raffle Name',
    selector: (row: any) => row?.raffledraw?.ticket_name,
    sortable: true,
  },
  {
    name: 'Raffle Name',
    selector: (row: any) => row?.raffle_number,
    sortable: true,
  },
  {
    name: 'Winner',
    selector: (row: any) => (row?.winner ? `Yes` : `No`),
    sortable: true,
  },
  {
    name: 'Created On',
    selector: (row: any) => <DateFormatter date={row?.created} />,
    sortable: true,
  },
  {
    cell: (row: any) => <EventActionCell id={row?.id} />,
    sortable: true,
  },
]
const ResultColumn = [
  {
    name: 'Profile',
    cell: (row: any) => <UserInfoCell user={row?.user} />,
    sortable: true,
  },
  {
    name: 'Raffle Name',
    selector: (row: any) => row?.raffledraw?.ticket_name,
    sortable: true,
  },
  {
    name: 'Raffle Number',
    selector: (row: any) => row?.raffle_number,
    sortable: true,
  },
  {
    name: 'Winner',
    selector: (row: any) => (row?.winner ? `Yes` : `No`),
    sortable: true,
  },
  {
    name: 'Created On',
    selector: (row: any) => <DateFormatter date={row?.created} />,
    sortable: true,
  },
]
const RolesColumn = [
  {
    name: 'Role Name',
    selector: (row: any) => row?.name,
    sortable: true,
  },
  {
    name: 'Permissions',
    selector: (row: any) => <PermissionsFormatter permissions={row?.role_permission} />,
    sortable: true,
    wrap: true,
  },
  {
    name: 'Created On',
    selector: (row: any) => <DateFormatter date={row?.created} />,
    sortable: true,
  },
  {
    cell: (row: any) => <RolesActionCell id={row?.id} />,
    sortable: true,
  },
]
const CountryColumn = [
  {
    name: 'Name',
    selector: (row: any) => row?.name,
    sortable: true,
  },
  {
    name: 'Capital',
    selector: (row: any) => row?.capital,
    sortable: true,
  },
  {
    name: 'Continent',
    selector: (row: any) => row?.continent,
    sortable: true,
  },
  {
    name: 'Currency',
    selector: (row: any) => row?.currency,
    sortable: true,
  },
  {
    name: 'Call Code',
    selector: (row: any) => row?.phone,
    sortable: true,
  },
  {
    name: 'Shipping Fee',
    cell: (row: any) => <CurrencyColumnFormater amount={row?.shipping_fee} />,
    sortable: true,
  },
  {
    name: 'Created On',
    selector: (row: any) => <DateFormatter date={row?.created} />,
    sortable: true,
  },
  {
    cell: (row: any) => <CountryActionCell id={row?.id} />,
    sortable: true,
  },
]
export {
  TrackingTimelineColumn,
  RolesColumn,
  WithdrawReqColumn,
  ResultColumn,
  ParticipantsColumn,
  EventsColumn,
  ReviewsColumn,
  OrdersColumn,
  ProductsColumn,
  CustomersColumn,
  TransactionsColumn,
  TrackingColumn,
  RaffleColumn,
  ProductCategoryColumn,
  RaffleCategoryColumn,
  RaffleResultColumn,
  CountryColumn,
}
