export const PaymentStatus = [
  {
    statusCode: 1,
    name: `paid`,
  },
  {
    statusCode: 2,
    name: `pending`,
  },
  {
    statusCode: 0,
    name: `failed`,
  },
]

export const TransactionType = [
  {
    statusCode: 1,
    name: `product`,
  },
  {
    statusCode: 2,
    name: `wallet`,
  },
  {
    statusCode: 3,
    name: `withdrawal`,
  },
]
