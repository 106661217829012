import {Table} from '../../../lib/table/Table'
import {CustomersColumn} from '../../../lib/table/components/Columns'
import {useEffect, useState} from 'react'
import {axiosGet} from '../../../lib/helpers'

const CustomersList = () => {
  const [data, setData] = useState<any[]>([])

  useEffect(() => {
    ;(async () => {
      const result = await axiosGet({path: `user/list`})
      const users = []
      if (result.isSuccess) {
        for (const x in result.users) {
          if (result.users[x]?.role?.type === 2) {
            users.push(result.users[x])
          }
        }
        setData(users)
      }
    })()
  }, [])
  return (
    <>
      <Table data={data} columns={CustomersColumn} />
    </>
  )
}
export {CustomersList}
