import clsx from 'clsx'
import {useEffect, useState} from 'react'
import {axiosGet, axiosPost} from '../../../lib/helpers'
import {ProductAvailability, ProductStatus} from '../../../lib/models/Product'
import {Media} from '../../media/Media'
import {useFormik} from 'formik'
import * as Yup from 'yup'

const ProductCreate = () => {
  const [cat, setCat] = useState<any[]>([])
  const [raffle, setRaffle] = useState<any[]>([])
  const [showMedia, setShowMedia] = useState(false)
  const [picture, setPicture] = useState(() => {
    const savedPics = localStorage.getItem('pictures')
    if (savedPics) {
      return JSON.parse(savedPics)
    } else {
      return []
    }
  })
  const [loading, setLoading] = useState(false)
  const [checked, setChecked] = useState(false)
  const fetch = async () => {
    const category = await axiosGet({path: 'products/category/list'})
    if (category.isSuccess) {
      const cart = []

      for (const x in category.categories) {
        if (category.categories[x].status === true) {
          cart.push(category.categories[x])
        }
      }
      setCat(cart)
    }
    const draw = await axiosGet({path: 'raffle/list/all'})
    if (draw.isSuccess) {
      const darw = []

      for (const x in draw.raffle) {
        console.log(draw.raffle[x].status)
        if (draw.raffle[x].status === 1) {
          darw.push(draw.raffle[x])
        }
      }
      setRaffle(darw)
    }
  }

  useEffect(() => {
    fetch()
    localStorage.setItem('pictures', JSON.stringify(picture))
  }, [picture])

  const handleDeleteClick = (_id: any) => {
    const removeItem = picture.filter((picture: any) => {
      return picture.id !== _id
    })
    setPicture(removeItem)
  }
  const validationSchema = Yup.object().shape({
    product_name: Yup.string().required('Product name is required'),
    product_description: Yup.string().required('Product Description is required'),
    product_price: Yup.number().required('Product price is required'),
    shipping_fee: Yup.number().required('Shipping fee is required'),
    featured: Yup.boolean().required('Featured is required'),
    stock: Yup.number().required('Stock is required'),
    availability: Yup.number().required('Availability is required'),
    low_stock_alert: Yup.boolean().required('Low stock alert is required'),
    category: Yup.string().required('Category is required'),
    status: Yup.number().required('Status is required'),
  })

  const initialValues = {
    product_name: '',
    product_description: '',
    product_price: '',
    shipping_fee: '',
    featured: '',
    stock: '',
    raffle: '',
    availability: '',
    low_stock_alert: '',
    category: ``,
    status: ``,
  }
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        picture.map((item: any) => {
          delete item.thumbnailUrl
        })

        const result = await axiosPost({
          path: `products/create`,
          data: {
            product_name: values.product_name,
            product_description: values.product_description,
            product_price: parseInt(values.product_price),
            shipping_fee: parseInt(values.shipping_fee),
            featured: values.featured,
            stock: parseInt(values.stock),
            raffledraw: values.raffle,
            availability: parseInt(values.availability),
            low_stock_alert: values.low_stock_alert,
            category: values.category,
            pictures: picture,
            status: parseInt(values.status),
          },
        })
        if (result.isSuccess) {
          localStorage.removeItem(`pictures`)
        }
        setLoading(false)
        setSubmitting(false)
      } catch (error) {
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  const handleFrontChange = (_id: any) => {
    setChecked(_id)
    picture.map((item: any) => {
      if (item.id === _id) {
        const front = Object.assign(item, {front: true})
        localStorage.setItem(`pictures`, JSON.stringify([...picture, {...front}]))
      } else {
        const front = Object.assign(item, {front: false})
        localStorage.setItem(`pictures`, JSON.stringify([...picture, {...front}]))
      }
    })
  }
  const selectCallback = (item: any) => {
    setPicture([...picture, {id: item[0].id, thumbnailUrl: item[0].location}])
    setShowMedia(false)
  }
  return (
    <>
      {showMedia && (
        <Media
          show={showMedia}
          hide={() => setShowMedia(false)}
          selectCallback={(e: any) => selectCallback(e)}
        />
      )}

      <form onSubmit={formik.handleSubmit} noValidate id='product_create_form' autoComplete='off'>
        <div className='row'>
          <div className='d-flex justify-content-end'>
            <div className='col-md-3 me-2'>
              <div className='form-floating'>
                <select
                  id='productraffle'
                  aria-label='status'
                  {...formik.getFieldProps('status')}
                  className={clsx(
                    'form-select',
                    {'is-invalid': formik.touched.status && formik.errors.status},
                    {
                      'is-valid': formik.touched.status && !formik.errors.status,
                    }
                  )}
                  name='status'
                >
                  <option selected>Choose Status</option>
                  {ProductStatus.length > 0 ? (
                    ProductStatus.map((item) => (
                      <option value={item.statusCode}>{item.name}</option>
                    ))
                  ) : (
                    <option>
                      No raffle found - <small>Please add one from the raffle section.</small>
                    </option>
                  )}
                </select>
                <label htmlFor='productraffle'>Product Status</label>
              </div>
              {formik.touched.status && formik.errors.status && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.status}</span>
                  </div>
                </div>
              )}
            </div>
            <button
              type='submit'
              disabled={formik.isSubmitting || !formik.isValid}
              className='btn me-2 afri-btn-bg-primary'
              data-status={1}
            >
              {!loading && <span className='indicator-label'>Publish</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>

          <div className='card shadow-none'>
            <div className='card-header d-flex flex-column'>
              <div className='card-title fw-bold fs-1 mb-3'>Create new product</div>
              <div className='card-head-extra d-flex flex-row justify-content-between align-items-center'>
                <h4 className='fs-4 fw-normal'>General information</h4>
                <i className='bi bi-plus fs-2'></i>
              </div>
            </div>
            <div className='card-body'>
              <h4>Organize</h4>
              <small>To start selling, all you need is a title and a price.</small>

              <div className='row mt-5'>
                <h5 className='mb-5 border-bottom'>General</h5>
                <div className='col-md-10 mb-10 '>
                  <div className='form-floating mb-3'>
                    <input
                      type='text'
                      id='prodname'
                      placeholder='Enter name'
                      {...formik.getFieldProps('product_name')}
                      className={clsx(
                        'form-control',
                        {'is-invalid': formik.touched.product_name && formik.errors.product_name},
                        {
                          'is-valid': formik.touched.product_name && !formik.errors.product_name,
                        }
                      )}
                      style={{
                        height: '50px',
                      }}
                      name='product_name'
                    />
                    <label htmlFor='prodname'>Product Name</label>
                  </div>
                  {formik.touched.product_name && formik.errors.product_name && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.product_name}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='col-md-10 col-lg-10 mb-10'>
                  <div className='form-floating'>
                    <textarea
                      placeholder='Enter description'
                      id='proddesc'
                      style={{height: '100px'}}
                      {...formik.getFieldProps('product_description')}
                      className={clsx(
                        'form-control',
                        {
                          'is-invalid':
                            formik.touched.product_description && formik.errors.product_description,
                        },
                        {
                          'is-valid':
                            formik.touched.product_description &&
                            !formik.errors.product_description,
                        }
                      )}
                      name='product_description'
                    ></textarea>
                    <label htmlFor='proddesc'>Description</label>
                  </div>
                  {formik.touched.product_description && formik.errors.product_description && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.product_description}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='col-md-3 mb-10 '>
                  <div className='form-floating mb-3'>
                    <select
                      style={{
                        height: '50px',
                      }}
                      id='prodcat'
                      aria-label='Category Status'
                      {...formik.getFieldProps('category')}
                      className={clsx(
                        'form-select',
                        {'is-invalid': formik.touched.category && formik.errors.category},
                        {
                          'is-valid': formik.touched.category && !formik.errors.category,
                        }
                      )}
                      name='category'
                    >
                      <option selected>Choose Category</option>
                      {cat.length > 0 ? (
                        cat.map((item) => <option value={item.id}>{item.name}</option>)
                      ) : (
                        <option>
                          No category found - <br />
                          <small>Please add one from the category section.</small>
                        </option>
                      )}
                    </select>
                    <label htmlFor='prodcat'>Product Category</label>
                  </div>
                  {formik.touched.category && formik.errors.category && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.category}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='col-md-3 mb-10 '>
                  <div className='form-floating mb-3'>
                    <select
                      id='productraffle'
                      aria-label='Raffle Draw'
                      {...formik.getFieldProps('raffle')}
                      className={clsx(
                        'form-select',
                        {'is-invalid': formik.touched.raffle && formik.errors.raffle},
                        {
                          'is-valid': formik.touched.raffle && !formik.errors.raffle,
                        }
                      )}
                      name='raffle'
                    >
                      <option selected>Choose Raffle</option>
                      {raffle && raffle.length > 0 ? (
                        raffle.map((item) => <option value={item.id}>{item.ticket_name}</option>)
                      ) : (
                        <option>
                          No raffle found - <small>Please add one from the raffle section.</small>
                        </option>
                      )}
                    </select>
                    <label htmlFor='productraffle'>Product Raffle Draw</label>
                  </div>
                  {formik.touched.raffle && formik.errors.raffle && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.raffle}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='col-md-4 mb-10 '>
                  <div className='form-floating mb-3'>
                    <select
                      id='prodavailability'
                      aria-label='product availability'
                      {...formik.getFieldProps('availability')}
                      className={clsx(
                        'form-select',
                        {'is-invalid': formik.touched.availability && formik.errors.availability},
                        {
                          'is-valid': formik.touched.availability && !formik.errors.availability,
                        }
                      )}
                      name='availability'
                    >
                      <option selected>Choose Availability</option>
                      {ProductAvailability.length > 0 &&
                        ProductAvailability.map((item) => (
                          <option value={item.statusCode}>{item.name}</option>
                        ))}
                    </select>
                    <label htmlFor='prodavailability'>Product Availability</label>
                  </div>
                  {formik.touched.availability && formik.errors.availability && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.availability}</span>
                      </div>
                    </div>
                  )}
                </div>
                <h5 className='mb-5 border-bottom'>Price, Shipping & Stock</h5>
                <div className='col-md-5 mb-10 mt-5'>
                  <div className='form-check form-switch'>
                    <input
                      {...formik.getFieldProps('featured')}
                      className={clsx(
                        'form-check-input',
                        {'is-invalid': formik.touched.featured && formik.errors.featured},
                        {
                          'is-valid': formik.touched.featured && !formik.errors.featured,
                        }
                      )}
                      type='checkbox'
                      role='switch'
                      id='setfeatured'
                    />
                    <label className='form-check-label' htmlFor='setfeatured'>
                      Set as featured
                    </label>
                  </div>
                  {formik.touched.featured && formik.errors.featured && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.featured}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='col-md-5 mb-10 mt-5'>
                  <div className='form-check form-switch'>
                    <input
                      {...formik.getFieldProps('low_stock_alert')}
                      className={clsx(
                        'form-check-input',
                        {
                          'is-invalid':
                            formik.touched.low_stock_alert && formik.errors.low_stock_alert,
                        },
                        {
                          'is-valid':
                            formik.touched.low_stock_alert && !formik.errors.low_stock_alert,
                        }
                      )}
                      type='checkbox'
                      role='switch'
                      id='lowstockalert'
                    />
                    <label className='form-check-label' htmlFor='lowstockalert'>
                      Low stock alert
                    </label>
                  </div>
                  {formik.touched.low_stock_alert && formik.errors.low_stock_alert && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.low_stock_alert}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='col-md-3 mb-10 '>
                  <div className='form-floating mb-3'>
                    <input
                      type='tel'
                      id='prodstock'
                      placeholder='Enter stock'
                      {...formik.getFieldProps('stock')}
                      className={clsx(
                        'form-control',
                        {'is-invalid': formik.touched.stock && formik.errors.stock},
                        {
                          'is-valid': formik.touched.stock && !formik.errors.stock,
                        }
                      )}
                      style={{
                        height: '50px',
                      }}
                      name='stock'
                    />
                    <label htmlFor='prodstock'>Product Stock</label>
                  </div>
                  {formik.touched.stock && formik.errors.stock && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.stock}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='col-md-4 mb-10 '>
                  <div className='form-floating mb-3'>
                    <input
                      type='tel'
                      id='prodprice'
                      placeholder='Enter Price'
                      {...formik.getFieldProps('product_price')}
                      className={clsx(
                        'form-control',
                        {'is-invalid': formik.touched.product_price && formik.errors.product_price},
                        {
                          'is-valid': formik.touched.product_price && !formik.errors.product_price,
                        }
                      )}
                      style={{
                        height: '50px',
                      }}
                      name='product_price'
                    />
                    <label htmlFor='prodprice'>Product Price</label>
                  </div>
                  {formik.touched.product_price && formik.errors.product_price && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.product_price}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='col-md-3 mb-10 '>
                  <div className='form-floating mb-3'>
                    <input
                      type='text'
                      id='prodshippingprice'
                      placeholder='Enter Shipping Price'
                      {...formik.getFieldProps('shipping_fee')}
                      className={clsx(
                        'form-control',
                        {'is-invalid': formik.touched.shipping_fee && formik.errors.shipping_fee},
                        {
                          'is-valid': formik.touched.shipping_fee && !formik.errors.shipping_fee,
                        }
                      )}
                      style={{
                        height: '50px',
                      }}
                      name='shipping_fee'
                    />
                    <label htmlFor='prodshippingprice'>Product Shipping Fee</label>
                  </div>
                </div>
                <h5>Variant</h5>
                <small className='mb-5 border-bottom'>
                  Add variant for this product, offer your customer different image options
                </small>

                <div className='col-md-10 mb-10 '>
                  <div className='row'>
                    {picture.length > 0 &&
                      picture.map((item: any) => (
                        <div className='col-md-3 d-flex flex-column'>
                          <button
                            className=' border-0 afri-btn-bg-primary  fs-2 '
                            onClick={() => handleDeleteClick(item.id)}
                          >
                            x
                          </button>
                          <img
                            className='w-100 mb-5'
                            style={{
                              minHeight: '100px',
                              minWidth: '100px',
                            }}
                            src={item.thumbnailUrl}
                            alt={item.title}
                          />

                          <div className='form-check text-center'>
                            <input
                              className='form-check-input'
                              type='radio'
                              value='1'
                              id={item.id}
                              checked={item.id === checked}
                              onChange={() => handleFrontChange(item.id)}
                            />
                            <label className='form-check-label' htmlFor={item.id}>
                              Set as front
                            </label>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                <div className='col-md-4 mb-10 '>
                  <button
                    onClick={() => setShowMedia(true)}
                    type='button'
                    className='btn btn-outline w-100'
                  >
                    {' '}
                    <i className='bi bi-plus fs-2'></i> Add Media
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export {ProductCreate}
