import {HeadButton} from '../../lib/widgets/HeadButton'
import {useLocation} from 'react-router'

const RaffleHead = () => {
  const location = useLocation()
  return (
    <>
      {location.pathname === `/raffle/list` && (
        <div className='row d-flex justify-content-start'>
          <HeadButton colsize='2' name='New Raffle' to='/raffle/create' icon='bi-plus' />
          <HeadButton colsize='2' name='Category List' to='/raffle/category/list' />
          <HeadButton colsize='2' name='Participants' to='/raffle/participants' />
          <HeadButton colsize='2' name='Result' to='/raffle/result' />
        </div>
      )}
    </>
  )
}

export {RaffleHead}
