import {Link, useParams} from 'react-router-dom'
import {useEffect, useState} from 'react'
import {
  axiosGet,
  CurrencyColumnFormater,
  DateFormatter,
  PaymentStatusFormatter,
  ProductInfoCell,
  TransactionStatusFormatter,
  UserInfoCell,
} from '../../../lib/helpers'
import {Loader, LoaderTimout} from '../../../lib/widgets/Loader'
import {EditOrderModal} from '../../../lib/modals/EditActionModal'

const OrderDetails = () => {
  const {id} = useParams()
  const [data, setData] = useState<any>({})
  const [loading, setLoading] = useState(true)
  const [showModal, setShowModal] = useState(false)
  useEffect(() => {
    ;(async () => {
      const result = await axiosGet({path: `orders/list/${id}`})
      if (result.isSuccess) {
        setTimeout(() => {
          setLoading(false)
        }, LoaderTimout)
        setData(result.order)
      }
    })()
  }, [id])

  console.log(data)

  const count = data ? 1 : 0
  return (
    <>
      {showModal && (
        <EditOrderModal res={data} show={showModal} handleClose={() => setShowModal(false)} />
      )}
      <div className='row mb-4'>
        <div className='col-md-4'>
          <Link to='/orders/list' className='text-secondary'>
            <i className='bi bi-arrow-left text-gray'></i> Back to Orders
          </Link>
        </div>
      </div>

      {!loading && data ? (
        <div className='row'>
          {/*Order info*/}
          <div className='col-md-12 mb-lg-5 mb-4'>
            <div className='card card-bordered  border-1 shadow-none'>
              <div className='card-header d-flex flex-row justify-content-between align-items-center border-0'>
                <div className='card-title fw-bold fs-1 d-flex flex-column'>
                  Order {data?.tracking?.tracking_number}
                  <small className='text-secondary'>
                    <DateFormatter date={data?.created} />
                  </small>
                </div>
                <div className='card-header-action d-flex flex-row justify-content-between align-items-center'>
                  <PaymentStatusFormatter transaction={data?.transaction} className='me-2' />
                  <button className='btn btn-outline' onClick={() => setShowModal(true)}>
                    Edit
                  </button>
                </div>
              </div>
              <div className='card-body'>
                <div className='row'>
                  <div className='col-md-10'>
                    <div className='row'>
                      <div className='col-md-3 d-flex flex-column border-end'>
                        <span>Email</span>
                        <small className='text-secondary'>{data?.user?.email}</small>
                      </div>
                      <div className='col-md-3 d-flex flex-column border-end'>
                        <span>Phone</span>
                        <small className='text-secondary'>{data?.user?.phone}</small>
                      </div>
                      <div className='col-md-3 d-flex flex-column border-end'>
                        <span>Payment</span>
                        <small className='text-secondary'>Paystack</small>
                      </div>
                      <div className='col-md-3 d-flex flex-column border-end'>
                        <span>Sales Channel</span>
                        <small className='text-secondary'>App/Website</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*Summary*/}
          <div className='col-md-12 mb-lg-5 mb-4'>
            <div className='card  border-1 shadow-none'>
              <div className='card-header d-flex flex-row justify-content-between align-items-center border-0'>
                <div className='card-title fw-bold fs-1 d-flex flex-column'>Summary</div>
                <div className='card-header-action'>
                  <button className='btn btn-outline' onClick={() => setShowModal(true)}>
                    Edit
                  </button>
                </div>
              </div>
              <div className='card-body'>
                <div className='row d-flex flex-row align-items-center'>
                  {/*First part*/}
                  <div className='col-md-6 mb-4'>
                    <ProductInfoCell product={data?.product} />
                  </div>
                  <div className='col-md-4 text-secondary'>
                    <div className='price d-flex flex-row align-items-center justify-content-center '>
                      <span className='me-2'>
                        <CurrencyColumnFormater amount={data?.product.product_price} />{' '}
                      </span>
                      x{data?.quantity}
                    </div>
                  </div>
                  <div className='col-md-2 '>
                    <div className='price d-flex flex-row justify-content-end '>
                      <CurrencyColumnFormater amount={data?.product.product_price} />
                    </div>
                  </div>

                  {/*Second part*/}
                  <div className='col-md-6 mb-4'>Subtotal</div>
                  <div className='col-md-6 '>
                    <div className='price d-flex flex-row justify-content-end '>
                      <CurrencyColumnFormater amount={data?.product.product_price} />
                    </div>
                  </div>

                  {/* Third part*/}
                  <div className='col-md-6'>Shipping fee</div>
                  <div className='col-md-6 '>
                    <div className='price d-flex flex-row justify-content-end '>
                      <CurrencyColumnFormater amount={data?.product.shipping_fee} />
                    </div>
                  </div>

                  {/*  final*/}
                  <div className='col-md-6 fs-1 fw-bolder mb-5'>Total</div>

                  <div className='col-md-6 fs-1 fw-bolder '>
                    <div className='price d-flex flex-row justify-content-end '>
                      <CurrencyColumnFormater amount={data?.product.product_price} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*Payment*/}
          <div className='col-md-12 mb-lg-5 mb-4'>
            <div className='card card-bordered  border-1 shadow-none'>
              <div className='card-header d-flex flex-row justify-content-between align-items-center border-0'>
                <div className='card-title fw-bold fs-1 d-flex flex-column'>Payments</div>
                <div className='card-header-action d-flex flex-row justify-content-between align-items-center'>
                  <PaymentStatusFormatter transaction={data?.transaction} className='me-2' />
                  <button className='btn btn-sm btn-outline bg-white border-1'>
                    Capture Payment
                  </button>
                </div>
              </div>
              <div className='card-body'>
                <div className='row'>
                  {data?.transaction?.length > 0 &&
                    data.transaction.map((item: any) => (
                      <div className='col-md-6 d-flex flex-column'>
                        <span className='text-black mb-2'>{item.reference}</span>
                        <small className='text-secondary mb-2'>
                          <DateFormatter date={item.created} />
                        </small>
                        <span className='text-black mb-2'>
                          {' '}
                          <i className='bi bi-arrow-return-right text-info-emphasis mb-2'></i>{' '}
                          <TransactionStatusFormatter status={item.status} />
                        </span>
                      </div>
                    ))}
                  <div className='col-md-6 d-flex flex-row justify-content-end align-items-center'>
                    <span className='text-black me-2'>Paystack</span>
                    <small>
                      <CurrencyColumnFormater amount={data?.transaction[0].amount} />
                    </small>
                  </div>

                  <div className='col-md-6 '>
                    <span className='fw-bold'>Total paid </span>
                  </div>
                  <div className='col-md-6 d-flex flex-row justify-content-end align-items-center'>
                    <small className='fw-bold'>
                      <CurrencyColumnFormater amount={data?.transaction[0].amount} />
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-12 mb-lg-5 mb-4'>
            <div className='card card-bordered  border-1 shadow-none'>
              <div className='card-header d-flex flex-row justify-content-between align-items-center border-0'>
                <div className='card-title fw-bold fs-1 d-flex flex-column'>Customer</div>
                <div className='card-header-action d-flex flex-row justify-content-between align-items-center'>
                  <i className='bi bi-three-dots'></i>
                </div>
              </div>
              <div className='card-body'>
                <div className='row'>
                  <div className='col-md-10 mb-4'>
                    <UserInfoCell user={data?.user} />
                  </div>
                  <div className='col-md-10'>
                    <div className='row'>
                      <div className='col-md-3 d-flex flex-column border-end'>
                        <span>Contact</span>
                        <small className='text-black'>{data?.user?.email}</small>
                        <small className='text-black'>{data?.user?.phone}</small>
                      </div>
                      <div className='col-md-3 d-flex flex-column border-end'>
                        <span>Shipping</span>
                        {data?.user?.shipping.length > 0 &&
                          data.user.shipping.map(
                            (item: any) =>
                              item.default === true && (
                                <small className='text-black'>
                                  {item.shipping_address}, <br /> {item.shipping_city}{' '}
                                  {item.shipping_state}, {item.shipping_zip_code}
                                </small>
                              )
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='d-flex justify-content-center'>
          <Loader isError={count} errorMessage='error occurred' />
        </div>
      )}
    </>
  )
}

export {OrderDetails}
