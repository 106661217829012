import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {CustomersList, CustomerDetails} from './'
import {CustomerEdit} from './components/CustomerEdit'

const CustomersPage = () => (
  <Routes>
    <Route element={<Outlet />}>
      <Route
        path='list'
        element={
          <>
            {/*<PageTitle breadcrumbs={[]}></PageTitle>*/}
            <CustomersList />
          </>
        }
      />{' '}
      <Route
        path=':id'
        element={
          <>
            {/*<PageTitle breadcrumbs={[]}></PageTitle>*/}
            <CustomerDetails />
          </>
        }
      />
      <Route
        path='list/:id'
        element={
          <>
            {/*<PageTitle breadcrumbs={[]}></PageTitle>*/}
            <CustomerEdit />
          </>
        }
      />
      <Route index element={<Navigate to='/customers/list' />} />
    </Route>
  </Routes>
)
export {CustomersPage}
