import clsx from 'clsx'
import {HeaderNotificationsMenu, HeaderUserMenu} from '../../../partials'
import {useLayout} from '../../core'
import {useAuth} from '../../../../app/pages'
import {useEffect, useState} from 'react'
import {axiosGet} from '../../../../app/lib/helpers'
import {accessPermissions} from '../../../../app/pages/auth/core/Permissions'
import {KTIcon} from '../../../helpers'
import {toAbsoluteUrl} from '../../../helpers'

const itemClass = 'ms-1 ms-lg-3'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'
const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'fs-1'

const Navbar = () => {
  const {config} = useLayout()
  const {currentUser} = useAuth()

  const [count, setCount] = useState<any[]>([])
  const {notifications} = accessPermissions()
  useEffect(() => {
    ;(async () => {
      const result = await axiosGet({path: `event/system/list`})
      if (result.isSuccess) {
        const events = []
        for (const x in result.events) {
          if (result.events[x].status === 0) {
            events.push(result.events[x])
          }
        }
        setCount(events)
      }
    })()
  }, [])

  return (
    <div className='app-navbar flex-shrink-0'>
      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          className={btnClass}
        >
          <span
            className='badge bg-danger text-white rounded-circle'
            style={{
              top: '15px',
              position: 'absolute',
            }}
          >
            {count.length}
          </span>
          <i className='bi bi-bell fs-2'></i>
        </div>
        {notifications && <HeaderNotificationsMenu />}
      </div>

      {/*<div className={clsx('app-navbar-item', itemClass)}>*/}
      {/*  <div className={clsx('position-relative', btnClass)} id='kt_drawer_chat_toggle'>*/}
      {/*    <KTIcon iconName='message-text-2' className={btnIconClass} />*/}
      {/*    <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute' />*/}
      {/*  </div>*/}
      {/*</div>*/}

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol rounded-circle', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <img
            src={
              currentUser.user.picture.length > 0
                ? currentUser.user.picture[0].location
                : toAbsoluteUrl('/media/avatars/blank.png')
            }
            alt={currentUser?.user?.firstname}
            className='rounded-circle'
          />
        </div>
        <HeaderUserMenu />
      </div>

      {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTIcon iconName='text-align-left' className={btnIconClass} />
          </div>
        </div>
      )}
    </div>
  )
}

export {Navbar}
