import {Link} from 'react-router-dom'
import {FC, useEffect, useState} from 'react'
import {
  EditOrderModal,
  EditProdCategoryModal,
  EditRaffleCategoryModal,
  EditRoleModal,
  EditTrackingModal,
  SetWinnerModal,
  EditCountryModal,
} from '../../modals/EditActionModal'
import {axiosDelete, axiosGet, axiosPost, axiosSpecial, notifyFailure} from '../../helpers'
type productProps = {
  id: string
}
const ProductActionCell: FC<productProps> = ({id}) => {
  const handleDelete = async () => {
    await axiosDelete({path: `products/list/${id}`})
  }
  return (
    <>
      <div className='dropdown'>
        <button
          className='btn bg-transparent btn-small '
          type='button'
          data-bs-toggle='dropdown'
          aria-expanded='false'
        >
          <i className='bi bi-three-dots-vertical'></i>
        </button>
        <ul className='dropdown-menu'>
          <li>
            <Link className='dropdown-item' to='#' onClick={handleDelete}>
              Delete
            </Link>
          </li>
        </ul>
      </div>
    </>
  )
}
const WithdrawReqActionCell: FC<productProps> = ({id}) => {
  const handleApproval = async () => {
    await axiosSpecial({
      path: `transaction/approvetransfer`,
      data: {
        transactionId: id,
      },
      type: `approve`,
    })
  }
  const handleReject = async () => {
    await axiosSpecial({
      path: `transaction/rejecttransfer`,
      data: {
        transactionId: id,
        type: `reject`,
      },
    })
  }
  return (
    <>
      <div className='dropdown'>
        <button
          className='btn bg-transparent btn-small '
          type='button'
          data-bs-toggle='dropdown'
          aria-expanded='false'
        >
          <i className='bi bi-three-dots-vertical'></i>
        </button>
        <ul className='dropdown-menu'>
          <li>
            <Link className='dropdown-item' to='#' onClick={handleApproval}>
              Approve
            </Link>
          </li>
          <li>
            <Link className='dropdown-item' to='#' onClick={handleReject}>
              Reject
            </Link>
          </li>
        </ul>
      </div>
    </>
  )
}

const ProductCategoryActionCell: FC<productProps> = ({id}) => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [data, setData] = useState<any>({})
  const [isDelete, setIsDelete] = useState(false)
  const handleEdit = async () => {
    const result = await axiosGet({path: `products/category/list/${id}`})
    if (result.isSuccess) {
      setData(result.category)
      setShowModal(true)
    }
  }
  const handleDelete = async () => {
    await axiosDelete({path: `products/category/list/${id}`})
    setIsDelete(true)
  }
  useEffect(() => {
    if (isDelete) {
      window.location.reload()
    }
  }, [isDelete])

  return (
    <>
      {showModal && (
        <EditProdCategoryModal
          res={data}
          show={showModal}
          handleClose={() => setShowModal(false)}
        />
      )}
      <div className='dropdown'>
        <button
          className='btn bg-transparent btn-small '
          type='button'
          data-bs-toggle='dropdown'
          aria-expanded='false'
        >
          <i className='bi bi-three-dots-vertical fs-3'></i>
        </button>
        <ul className='dropdown-menu'>
          <li>
            <Link className='dropdown-item' to='#' onClick={handleEdit}>
              Edit
            </Link>
          </li>
          <li>
            <Link className='dropdown-item' to='#' onClick={handleDelete}>
              Delete
            </Link>
          </li>
        </ul>
      </div>
    </>
  )
}
const EventActionCell: FC<productProps> = ({id}) => {
  const handleDelete = async () => {
    await axiosDelete({path: `event/list/${id}`})
  }

  return (
    <>
      <div className='dropdown'>
        <button
          className='btn bg-transparent btn-small '
          type='button'
          data-bs-toggle='dropdown'
          aria-expanded='false'
        >
          <i className='bi bi-three-dots-vertical fs-3'></i>
        </button>
        <ul className='dropdown-menu'>
          <li>
            <Link className='dropdown-item' to='#' onClick={handleDelete}>
              Delete
            </Link>
          </li>
        </ul>
      </div>
    </>
  )
}
const RolesActionCell: FC<productProps> = ({id}) => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [data, setData] = useState<any[]>([])
  const handleDelete = async () => {
    await axiosDelete({path: `user/role/${id}`})
  }
  const handleEdit = async () => {
    const result = await axiosGet({path: `user/role/${id}`})
    if (result.isSuccess) {
      setShowModal(true)
      setData(result.role)
    }
  }

  return (
    <>
      {showModal && (
        <EditRoleModal res={data} show={showModal} handleClose={() => setShowModal(false)} />
      )}
      <div className='dropdown'>
        <button
          className='btn bg-transparent btn-small '
          type='button'
          data-bs-toggle='dropdown'
          aria-expanded='false'
        >
          <i className='bi bi-three-dots-vertical fs-3'></i>
        </button>
        <ul className='dropdown-menu'>
          {/*<li><Link className="dropdown-item" to="#" onClick={handleEdit}>Edit</Link></li>*/}
          <li>
            <Link className='dropdown-item' to='#' onClick={handleDelete}>
              Delete
            </Link>
          </li>
        </ul>
      </div>
    </>
  )
}
const CountryActionCell: FC<productProps> = ({id}) => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [data, setData] = useState<any[]>([])
  const handleDelete = async () => {
    await axiosDelete({path: `resources/country/${id}`})
  }
  const handleEdit = async () => {
    const result = await axiosGet({path: `resources/country/${id}`})
    if (result.isSuccess) {
      setShowModal(true)
      setData(result.country)
    }
  }

  return (
    <>
      {showModal && (
        <EditCountryModal res={data} show={showModal} handleClose={() => setShowModal(false)} />
      )}
      <div className='dropdown'>
        <button
          className='btn bg-transparent btn-small '
          type='button'
          data-bs-toggle='dropdown'
          aria-expanded='false'
        >
          <i className='bi bi-three-dots-vertical fs-3'></i>
        </button>
        <ul className='dropdown-menu'>
          {/*<li><Link className="dropdown-item" to="#" onClick={handleEdit}>Edit</Link></li>*/}
          <li>
            <Link className='dropdown-item' to='#' onClick={handleDelete}>
              Delete
            </Link>
          </li>
          <li>
            <Link className='dropdown-item' to='#' onClick={handleEdit}>
              Edit Fee
            </Link>
          </li>
        </ul>
      </div>
    </>
  )
}
const UserActionCell: FC<productProps> = ({id}) => {
  const handleDelete = async () => {
    await axiosDelete({path: `user/superuser/${id}`})
  }

  return (
    <>
      <div className='dropdown'>
        <button
          className='btn bg-transparent btn-small '
          type='button'
          data-bs-toggle='dropdown'
          aria-expanded='false'
        >
          <i className='bi bi-three-dots-vertical fs-3'></i>
        </button>
        <ul className='dropdown-menu'>
          <li>
            <Link className='dropdown-item' to='#' onClick={handleDelete}>
              Delete
            </Link>
          </li>
        </ul>
      </div>
    </>
  )
}
const OrderActionCell: FC<productProps> = ({id}) => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [data, setData] = useState<any>({})
  const handleEdit = async () => {
    const result = await axiosGet({path: `orders/list/${id}`})
    if (result.isSuccess) {
      setData(result.order)
      setShowModal(true)
    }
  }
  const handleDelete = async () => {
    const result = await axiosDelete({path: `orders/list/${id}`})

    if (result.isSuccess) {
      setTimeout(() => {
        window.location.reload()
      }, 1000)
    }
  }

  return (
    <>
      {showModal && (
        <EditOrderModal res={data} show={showModal} handleClose={() => setShowModal(false)} />
      )}
      <div className='dropdown'>
        <button
          className='btn bg-transparent btn-small '
          type='button'
          data-bs-toggle='dropdown'
          aria-expanded='false'
        >
          <i className='bi bi-three-dots-vertical fs-3'></i>
        </button>
        <ul className='dropdown-menu'>
          <li>
            <Link className='dropdown-item' to='#' onClick={handleEdit}>
              Edit
            </Link>
          </li>
          <li>
            <Link className='dropdown-item' to='#' onClick={handleDelete}>
              Delete
            </Link>
          </li>
        </ul>
      </div>
    </>
  )
}
const RaffleCategoryActionCell: FC<productProps> = ({id}) => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [data, setData] = useState<any>({})
  const handleEdit = async () => {
    const result = await axiosGet({path: `raffle/category/list/${id}`})
    if (result.isSuccess) {
      setData(result.cat)
      setShowModal(true)
    }
  }
  const handleDelete = async () => {
    await axiosDelete({path: `raffle/category/list/${id}`})
  }

  return (
    <>
      {showModal && (
        <EditRaffleCategoryModal
          res={data}
          show={showModal}
          handleClose={() => setShowModal(false)}
        />
      )}
      <div className='dropdown'>
        <button
          className='btn bg-transparent btn-small '
          type='button'
          data-bs-toggle='dropdown'
          aria-expanded='false'
        >
          <i className='bi bi-three-dots-vertical fs-3'></i>
        </button>
        <ul className='dropdown-menu'>
          <li>
            <Link className='dropdown-item' to='#' onClick={handleEdit}>
              Edit
            </Link>
          </li>
          <li>
            <Link className='dropdown-item' to='#' onClick={handleDelete}>
              Delete
            </Link>
          </li>
        </ul>
      </div>
    </>
  )
}
const RaffleActionCell: FC<productProps> = ({id}) => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const handleDelete = async () => {
    const result = await axiosDelete({path: `raffle/list/${id}`})

    if (result.isSuccess) {
      window.location.reload()
    }
  }
  const [data, setData] = useState<any>({})
  const handleEdit = async () => {
    const result = await axiosGet({path: `raffle/list/${id}`})
    if (result.isSuccess && result.raffle.participants.length > 0) {
      setData(result.raffle)
      setShowModal(true)
    } else {
      const toastrProps = {
        text: `No participant found`,
        type: `error`,
      }
      notifyFailure({type: `toastr`, toastrProps})
    }
  }

  return (
    <>
      {showModal && (
        <SetWinnerModal res={data} show={showModal} handleClose={() => setShowModal(false)} />
      )}
      <div className='dropdown'>
        <button
          className='btn bg-transparent btn-small '
          type='button'
          data-bs-toggle='dropdown'
          aria-expanded='false'
        >
          <i className='bi bi-three-dots-vertical fs-3'></i>
        </button>
        <ul className='dropdown-menu'>
          <li>
            <Link className='dropdown-item' to='#' onClick={handleEdit}>
              Set winner
            </Link>
          </li>
          <li>
            <Link className='dropdown-item' to='#' onClick={handleDelete}>
              Delete
            </Link>
          </li>
        </ul>
      </div>
    </>
  )
}

const TrackingActionCell: FC<productProps> = ({id}) => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [data, setData] = useState<any>({})
  const handleEdit = async () => {
    const result = await axiosGet({path: `orders/tracking/list/${id}`})
    if (result.isSuccess) {
      setData(result.tracking)
      setShowModal(true)
    }
  }
  const handleDelete = async () => {
    await axiosDelete({path: `orders/tracking/list/${id}`})
  }

  return (
    <>
      {showModal && (
        <EditTrackingModal res={data} show={showModal} handleClose={() => setShowModal(false)} />
      )}
      <div className='dropdown'>
        <button
          className='btn bg-transparent btn-small '
          type='button'
          data-bs-toggle='dropdown'
          aria-expanded='false'
        >
          <i className='bi bi-three-dots-vertical fs-3'></i>
        </button>
        <ul className='dropdown-menu'>
          <li>
            <Link className='dropdown-item' to='#' onClick={handleEdit}>
              Update
            </Link>
          </li>
          <li>
            <Link className='dropdown-item' to='#' onClick={handleDelete}>
              Delete
            </Link>
          </li>
        </ul>
      </div>
    </>
  )
}
export {
  UserActionCell,
  RolesActionCell,
  WithdrawReqActionCell,
  EventActionCell,
  TrackingActionCell,
  ProductActionCell,
  ProductCategoryActionCell,
  RaffleCategoryActionCell,
  RaffleActionCell,
  OrderActionCell,
  CountryActionCell,
}
