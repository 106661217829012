import {Table} from '../../../lib/table/Table'
import {useEffect, useState} from 'react'
import {axiosGet} from '../../../lib/helpers'
import {ParticipantsColumn} from '../../../lib/table/components/Columns'

const RaffleParticipant = () => {
  const [data, setData] = useState<any[]>([])

  useEffect(() => {
    ;(async () => {
      const result = await axiosGet({path: `raffle/participants/list`})
      console.log(result)
      if (result.isSuccess) {
        setData(result.participants)
      }
    })()
  })
  return (
    <>
      <Table data={data} columns={ParticipantsColumn} />
    </>
  )
}
export {RaffleParticipant}
