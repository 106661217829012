import {HeadButton} from '../../../lib/widgets/HeadButton'
import {Table} from '../../../lib/table/Table'
import {ProductsColumn} from '../../../lib/table/components/Columns'
import {useEffect, useState} from 'react'
import {axiosGet} from '../../../lib/helpers'

const ProductList = () => {
  const [data, setData] = useState<any[]>([])

  useEffect(() => {
    ;(async () => {
      const result = await axiosGet({path: `products/list`})
      console.log(result)
      if (result.isSuccess) {
        setData(result.products)
      }
    })()
  }, [])
  return (
    <>
      <div className='row d-flex justify-content-start'>
        <HeadButton colsize='2' name='New Product' to='/products/create' icon='bi-plus' />
        <HeadButton colsize='2' name='Category' to='/products/category/list' />
      </div>

      <Table data={data} columns={ProductsColumn} />
    </>
  )
}
export {ProductList}
