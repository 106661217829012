import {Table} from '../../../lib/table/Table'
import {RaffleColumn} from '../../../lib/table/components/Columns'
import {useEffect, useState} from 'react'
import {axiosGet} from '../../../lib/helpers'
import {HeadButton} from '../../../lib/widgets/HeadButton'

const RaffleList = () => {
  const [data, setData] = useState<any[]>([])

  useEffect(() => {
    ;(async () => {
      const result = await axiosGet({path: `raffle/list/all`})
      console.log(result)
      if (result.isSuccess) {
        setData(result.raffle)
      }
    })()
  }, [])
  return (
    <>
      <Table data={data} columns={RaffleColumn} />
    </>
  )
}
export {RaffleList}
