import React from 'react'
import {KTIcon} from '../../../_metronic/helpers/components/KTIcon'
import {toAbsoluteUrl} from '../../../_metronic/helpers/AssetHelpers'
import {Link} from 'react-router-dom'

type Props = {
  className: string
  color: string
  svgIcon: string
  iconColor: string
  title: any
  titleColor?: string
  description: string
  descriptionColor?: string
  to?: any
}

const Card: React.FC<Props> = ({
  className,
  color,
  svgIcon,
  iconColor,
  title,
  titleColor,
  description,
  descriptionColor,
  to,
}) => {
  return (
    <Link to={to} className={`card bg-${color} hoverable ${className}`}>
      <div className='card-body d-flex align-items-center'>
        <img src={toAbsoluteUrl(svgIcon)} className={`h-85px fs-3x ms-n1 me-2`} />
        <div className='card-details d-flex flex-column'>
          <div
            className={`fw-semibold`}
            style={{
              color: '#A5B3CD',
              fontWeight: '400',
              fontFamily: 'Poppins',
              fontSize: '18.42px',
            }}
          >
            {description}
          </div>
          <div
            className={`text-${titleColor} fw-bold fs-2 my-2`}
            style={{
              color: '#141736',
              fontWeight: '600',
              fontFamily: 'Poppins',
              fontSize: '27.01px',
            }}
          >
            {title}
          </div>
        </div>
      </div>
    </Link>
  )
}

export {Card}
