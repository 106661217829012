import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {ProductCategoryList, ProductCreate, ProductList, ProductDetails, ProductEdit} from './index'

const ProductPage = () => (
  <Routes>
    <Route element={<Outlet />}>
      <Route
        path=':id'
        element={
          <>
            {/*<PageTitle breadcrumbs={[]}></PageTitle>*/}
            <ProductDetails />
          </>
        }
      />{' '}
      <Route
        path='list/:id'
        element={
          <>
            {/*<PageTitle breadcrumbs={[]}></PageTitle>*/}
            <ProductEdit />
          </>
        }
      />
      <Route
        path='category/list'
        element={
          <>
            {/*<PageTitle breadcrumbs={[]}></PageTitle>*/}
            <ProductCategoryList />
          </>
        }
      />
      <Route
        path='list'
        element={
          <>
            {/*<PageTitle breadcrumbs={[]}></PageTitle>*/}
            <ProductList />
          </>
        }
      />
      <Route
        path='create'
        element={
          <>
            {/*<PageTitle breadcrumbs={[]}></PageTitle>*/}
            <ProductCreate />
          </>
        }
      />
      <Route index element={<Navigate to='/products/list' />} />
    </Route>
  </Routes>
)
export {ProductPage}
