/* eslint-disable react/jsx-no-target-blank */
import {SidebarMenuItem} from './sidebar-menu/SidebarMenuItem'
import React from 'react'
import {useAuth} from '../../../../app/pages'

const SidebarFooter = () => {
  const {logout} = useAuth()
  return (
    <div className='app-sidebar-footer flex-column-auto pt-2 pb-6 px-6' id='kt_app_sidebar_footer'>
      <SidebarMenuItem
        action={logout}
        to='#'
        icon='/media/menu/logout-icon.svg'
        title='Logout'
        access={true}
      />
    </div>
  )
}

export {SidebarFooter}
