import {Table} from '../../../lib/table/Table'
import {TransactionsColumn} from '../../../lib/table/components/Columns'
import {useEffect, useState} from 'react'
import {axiosGet} from '../../../lib/helpers'
import {HeadButton} from '../../../lib/widgets/HeadButton'

const PaymentList = () => {
  const [data, setData] = useState<any[]>([])

  useEffect(() => {
    ;(async () => {
      const result = await axiosGet({path: `transaction/list`})
      if (result.isSuccess) {
        setData(result.transactions)
      }
    })()
  }, [])
  return (
    <>
      <div className='row d-flex justify-content-start'>
        <HeadButton
          colsize='3'
          name='Withdrawal Requests'
          to='/payments/withdrawals/list'
          icon=''
        />
      </div>
      <Table data={data} columns={TransactionsColumn} noclick={false} />
    </>
  )
}
export {PaymentList}
