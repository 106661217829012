import {useAuth} from './Auth'

import {UserPermissions} from '../../../lib/models/permissions'
type Props = {
  accessName: any
}
const Permission = ({accessName}: Props) => {
  const {currentUser} = useAuth()
  const userRole = currentUser?.user?.role

  for (const perm of userRole?.role_permission) {
    if (accessName.includes(perm.permission.name)) {
      return true
    }
  }
  return false
}
const accessPermissions = () => {
  return {
    orders: Permission({
      accessName: [
        UserPermissions.orders_all,
        UserPermissions.orders_edit,
        UserPermissions.orders_list,
        UserPermissions.orders_delete,
      ],
    }),
    products: Permission({
      accessName: [
        UserPermissions.products_all,
        UserPermissions.products_delete,
        UserPermissions.products_create,
        UserPermissions.products_edit,
        UserPermissions.products_list,
        UserPermissions.products_category_create,
        UserPermissions.products_category_all,
        UserPermissions.products_category_list,
        UserPermissions.products_category_delete,
        UserPermissions.products_category_edit,
      ],
    }),
    payments: Permission({
      accessName: [
        UserPermissions.payment_all,
        UserPermissions.payment_list,
        UserPermissions.payment_approve,
        UserPermissions.payment_reject,
      ],
    }),
    users: Permission({
      accessName: [
        UserPermissions.users_all,
        UserPermissions.users_edit,
        UserPermissions.users_list,
        UserPermissions.users_delete,
      ],
    }),
    tracking: Permission({
      accessName: [
        UserPermissions.tracking_all,
        UserPermissions.tracking_create,
        UserPermissions.tracking_delete,
        UserPermissions.tracking_edit,
        UserPermissions.tracking_list,
      ],
    }),
    raffle: Permission({
      accessName: [
        UserPermissions.raffle_all,
        UserPermissions.raffle_list,
        UserPermissions.raffle_edit,
        UserPermissions.raffle_delete,
        UserPermissions.raffle_category_all,
        UserPermissions.raffle_categorylist,
        UserPermissions.raffle_delete,
        UserPermissions.raffle_category_edit,
        UserPermissions.raffle_category_create,
      ],
    }),
    notifications: Permission({accessName: [UserPermissions.notifications_all]}),
    media: Permission({accessName: [UserPermissions.media_all]}),
    settings: Permission({accessName: [UserPermissions.setall]}),
  }
}

type permProps = {
  orders: boolean
  products: boolean
  payments: boolean
  users: boolean
  tracking: boolean
  raffle: boolean
  notifications: boolean
  media: boolean
  settings: boolean
}
const checkPermissionHome = ({
  orders,
  products,
  payments,
  users,
  tracking,
  raffle,
  notifications,
  media,
  settings,
}: permProps) => {
  return orders
    ? '/orders/list'
    : products
    ? '/products/list'
    : payments
    ? '/payments/list'
      ? users
      : '/customers/list'
      ? tracking
      : '/tracking/list'
      ? raffle
      : '/raffle/list'
      ? notifications
      : '/notifications/list'
      ? media
      : '/home'
      ? settings
      : '/settings/superusers/list'
    : '/home'
}
export {Permission, accessPermissions, checkPermissionHome}
