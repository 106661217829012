import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PaymentList} from './components/PaymentList'
import {PaymentRequests} from './components/PaymentRequests'
import {PageTitle} from '../../../_metronic/layout/core'

const PaymentPage = () => (
  <Routes>
    <Route element={<Outlet />}>
      <Route
        path='list'
        element={
          <>
            <PageTitle breadcrumbs={[]}>All Transactions</PageTitle>

            <PaymentList />
          </>
        }
      />
      <Route
        path='withdrawals/list'
        element={
          <>
            <PageTitle breadcrumbs={[]}>Withdraw Request</PageTitle>
            <PaymentRequests />
          </>
        }
      />
      <Route index element={<Navigate to='/payments/list' />} />
    </Route>
  </Routes>
)
export {PaymentPage}
