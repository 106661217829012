import {Table} from '../../../lib/table/Table'
import {TrackingColumn} from '../../../lib/table/components/Columns'
import {useEffect, useState} from 'react'
import {axiosGet} from '../../../lib/helpers'

const TrackList = () => {
  const [data, setData] = useState<any[]>([])

  useEffect(() => {
    ;(async () => {
      const result = await axiosGet({path: `orders/tracking/list`})
      console.log(result)
      if (result.isSuccess) {
        setData(result.trackings)
      }
    })()
  }, [])
  return (
    <>
      <Table data={data} columns={TrackingColumn} />
    </>
  )
}
export {TrackList}
