import clsx from 'clsx'
import {useEffect, useState} from 'react'
import {axiosGet, axiosPost, axiosPut} from '../../../lib/helpers'
import {ProductAvailability, ProductStatus} from '../../../lib/models/Product'
import {Media} from '../../media/Media'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useParams} from 'react-router-dom'
import {Loader} from '../../../lib/widgets/Loader'

const RaffleEdit = () => {
  const {id} = useParams()
  const [data, setData] = useState<any>([])
  const [cat, setCat] = useState<any[]>([])
  const [showMedia, setShowMedia] = useState(false)
  const [picture, setPicture] = useState(() => {
    const savedPics = localStorage.getItem('pictures')
    if (savedPics) {
      return JSON.parse(savedPics)
    } else {
      return []
    }
  })
  const [loading, setLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [checked, setChecked] = useState(false)
  const handleChange = (e: any) => {
    setData({...data, [e.target.name]: e.target.value})
  }

  useEffect(() => {
    ;(async () => {
      const category = await axiosGet({path: 'raffle/category/list'})
      if (category.isSuccess) {
        const cart = []

        for (const x in category.rafflecategories) {
          // if (category.rafflecategories[x].status === 1){
          cart.push(category.rafflecategories[x])
          // }
        }
        setCat(cart)
      }

      const product = await axiosGet({path: `raffle/list/${id}`})
      if (product.isSuccess) {
        const thisPic = []
        setData(product.raffle)
        for (const x in product.raffle.pictures) {
          thisPic.push({
            id: product.raffle.pictures[x].id,
            thumbnailUrl: product.raffle.pictures[x].location,
            front: product.raffle.pictures[x].front,
          })
          if (product.raffle.pictures[x].front) {
            setChecked(product.raffle.pictures[x].id)
          }
        }
        setPicture(thisPic)
      }
    })()

    localStorage.setItem('pictures', JSON.stringify(picture))
  }, [])

  const handleDeleteClick = (_id: any) => {
    const removeItem = picture.filter((picture: any) => {
      return picture.id !== _id
    })
    setPicture(removeItem)
  }

  const initialValues = {
    ticket_name: data.ticket_name,
    ticket_description: data.ticket_description,
    product_price: data.product_price,
    category: data.category,
    featured: data.featured,
    start_date: data.start_date,
    end_date: data.end_date,
    status: data.status,
  }
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        picture.map((item: any) => {
          return delete item.thumbnailUrl
        })

        const result = await axiosPut({
          path: `raffle/list/${id}`,
          data: {
            ticket_name: values.ticket_name,
            ticket_description: values.ticket_description,
            product_price: values.product_price,
            category: values.category,
            featured: values.featured,
            start_date: values.start_date,
            end_date: values.end_date,
            status: values.status,
          },
        })
        if (result.isSuccess) {
          localStorage.removeItem(`pictures`)
        }
        setLoading(false)
        setSubmitting(false)
      } catch (error) {
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  const handleFrontChange = (_id: any) => {
    setChecked(_id)
    picture.map((item: any) => {
      if (item.id === _id) {
        const front = Object.assign(item, {front: true})
        return localStorage.setItem(`pictures`, JSON.stringify([...picture, {...front}]))
      } else {
        const front = Object.assign(item, {front: false})
        return localStorage.setItem(`pictures`, JSON.stringify([...picture, {...front}]))
      }
    })
  }
  const selectCallback = (item: any) => {
    setPicture([...picture, {id: item._id, thumbnailUrl: item.thumbnailUrl}])
    setShowMedia(false)
  }
  const count = data ? 1 : 0
  return (
    <>
      {showMedia && (
        <Media
          show={showMedia}
          hide={() => setShowMedia(false)}
          selectCallback={(e: any) => selectCallback(e)}
        />
      )}

      <form onSubmit={formik.handleSubmit} noValidate id='raffle_edit_form' autoComplete='off'>
        {!isLoading && data ? (
          <div className='row'>
            <div className='d-flex justify-content-end'>
              <div className='col-md-3 me-2'>
                <div className='form-floating'>
                  <select
                    id='productraffle'
                    aria-label='status'
                    {...formik.getFieldProps('status')}
                    className={clsx('form-select')}
                    name='status'
                    onChange={handleChange}
                    value={data.status}
                  >
                    <option selected>Choose Status</option>
                    {ProductStatus.length > 0 ? (
                      ProductStatus.map((item) => (
                        <option value={item.statusCode}>{item.name}</option>
                      ))
                    ) : (
                      <option>
                        No raffle found - <small>Please add one from the raffle section.</small>
                      </option>
                    )}
                  </select>
                  <label htmlFor='productraffle'>Product Status</label>
                </div>
              </div>
              <button
                type='submit'
                disabled={formik.isSubmitting}
                className='btn me-2 afri-btn-bg-primary'
                data-status={1}
              >
                {!loading && <span className='indicator-label'>Save Changes</span>}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>

            <div className='card shadow-none'>
              <div className='card-header d-flex flex-column'>
                <div className='card-title fw-bold fs-1 mb-3'>
                  Edit Raffle - {data?.ticket_name}
                </div>
                <div className='card-head-extra d-flex flex-row justify-content-between align-items-center'>
                  <h4 className='fs-4 fw-normal'>General information</h4>
                  <i className='bi bi-plus fs-2'></i>
                </div>
              </div>
              <div className='card-body'>
                <h4>Organize</h4>
                <small>To start selling, all you need is a title and a price.</small>

                <div className='row mt-5'>
                  <h5 className='mb-5 border-bottom'>General</h5>
                  <div className='col-md-10 mb-10 '>
                    <div className='form-floating mb-3'>
                      <input
                        type='text'
                        id='prodname'
                        placeholder='Enter name'
                        {...formik.getFieldProps('ticket_name')}
                        className={clsx('form-control')}
                        style={{
                          height: '50px',
                        }}
                        name='ticket_name'
                        onChange={handleChange}
                        value={data.ticket_name}
                      />
                      <label htmlFor='prodname'>Ticket Name</label>
                    </div>
                  </div>
                  <div className='col-md-10 col-lg-10 mb-10'>
                    <div className='form-floating'>
                      <textarea
                        placeholder='Enter description'
                        id='proddesc'
                        style={{height: '100px'}}
                        {...formik.getFieldProps('ticket_description')}
                        className={clsx('form-control')}
                        name='product_description'
                        onChange={handleChange}
                        value={data.ticket_description}
                      ></textarea>
                      <label htmlFor='proddesc'>Description</label>
                    </div>
                  </div>
                  <div className='col-md-10 mb-10 mt-5'>
                    <div className='form-check form-switch'>
                      <input
                        {...formik.getFieldProps('featured')}
                        className={clsx('form-check-input')}
                        type='checkbox'
                        role='switch'
                        id='setfeatured'
                        onChange={handleChange}
                        checked={data.low_stock_alert}
                      />
                      <label className='form-check-label' htmlFor='setfeatured'>
                        Set as featured
                      </label>
                    </div>
                  </div>
                  <div className='col-md-4 mb-10 '>
                    <div className='form-floating mb-3'>
                      <select
                        style={{
                          height: '50px',
                        }}
                        id='editprodcat'
                        aria-label='Category Status'
                        {...formik.getFieldProps('category')}
                        className={clsx('form-select')}
                        name='category'
                      >
                        <option selected>Choose Category</option>
                        {data?.category && cat.length > 0 ? (
                          cat.map((item) => (
                            <option selected={item.id === data?.category?.id} value={item.id}>
                              {item.name}
                            </option>
                          ))
                        ) : (
                          <option>
                            No category found - <br />
                            <small>Please add one from the category section.</small>
                          </option>
                        )}
                      </select>
                      <label htmlFor='editprodcat'>Ticket Category</label>
                    </div>
                  </div>
                  <div className='col-md-3 mb-10 '>
                    <div className='form-floating mb-3'>
                      <input
                        type='date'
                        {...formik.getFieldProps('start_date')}
                        className={clsx('form-control')}
                        name='start_date'
                        value={data?.start_date}
                        onChange={handleChange}
                      />
                      <label htmlFor='editproductraffle'>Start date</label>
                    </div>
                  </div>
                  <div className='col-md-3 mb-10 '>
                    <div className='form-floating mb-3'>
                      <input
                        type='date'
                        {...formik.getFieldProps('end_date')}
                        className={clsx('form-control')}
                        name='end_date'
                        defaultValue={data?.end_start}
                        value={data?.end_start}
                        onChange={handleChange}
                      />
                      <label htmlFor='editproductraffle'>End date</label>
                    </div>
                  </div>

                  <h5>Variant</h5>
                  <small className='mb-5 border-bottom'>
                    Add variant for this product, offer your customer different image options
                  </small>

                  <div className='col-md-10 mb-10 '>
                    <div className='row'>
                      {picture.length > 0 &&
                        picture.map((item: any) => (
                          <div className='col-md-3 d-flex flex-column'>
                            <button
                              className=' border-0 afri-btn-bg-primary  fs-2 '
                              onClick={() => handleDeleteClick(item.id)}
                            >
                              x
                            </button>
                            <img
                              className='w-100 mb-5'
                              style={{
                                minHeight: '100px',
                                minWidth: '100px',
                              }}
                              src={item.thumbnailUrl}
                              alt={item.title}
                            />

                            <div className='form-check text-center'>
                              <input
                                className='form-check-input'
                                type='radio'
                                value='1'
                                id={item.id}
                                checked={item.id === checked}
                                onChange={() => handleFrontChange(item.id)}
                              />
                              <label className='form-check-label' htmlFor={item.id}>
                                Set as front
                              </label>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className='col-md-4 mb-10 '>
                    <button
                      onClick={() => setShowMedia(true)}
                      type='button'
                      className='btn btn-outline w-100'
                    >
                      <i className='bi bi-plus fs-2'></i> Add Media
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className='d-flex justify-content-center'>
            <Loader isError={count} errorMessage='error occurred' />
          </div>
        )}
      </form>
    </>
  )
}

export {RaffleEdit}
