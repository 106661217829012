import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import {
  Home,
  OrdersPage,
  ProductPage,
  CustomersPage,
  PaymentPage,
  RafflePage,
  SettingsPage,
  TrackPage,
  NotificationsPage,
} from '../pages'
import {accessPermissions, checkPermissionHome} from '../pages/auth/core/Permissions'
import {MediaPage} from '../pages/media/MediaPage'

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='auth/*' element={<Navigate to='/home' />} />
        {/* Pages */}
        <Route path='home' element={<Home />} />
        {/* Lazy Modules */}
        <Route path='notifications/*' element={<NotificationsPage />} />
        <Route path='orders/*' element={<OrdersPage />} />{' '}
        <Route path='products/*' element={<ProductPage />} />{' '}
        <Route path='customers/*' element={<CustomersPage />} />
        <Route path='payments/*' element={<PaymentPage />} />
        <Route path='tracking/*' element={<TrackPage />} />
        <Route path='raffle/*' element={<RafflePage />} />
        <Route path='media/*' element={<MediaPage />} />
        <Route path='settings/*' element={<SettingsPage />} />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

export {PrivateRoutes}
