export const ProductAvailability = [
  {statusCode: 1, name: `Website`},
  {statusCode: 2, name: `App`},
  {statusCode: 3, name: `All`},
]
export const ProductStatus = [
  {statusCode: 2, name: `Inactive`},
  {statusCode: 1, name: `Published`},
  {statusCode: 3, name: `Draft`},
]
