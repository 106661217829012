import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {SettingsRoles, SettingsUsers, SettingsCountry} from './components/SettingsGeneral'
import {PageTitle} from '../../../_metronic/layout/core'

const SettingsPage = () => (
  <Routes>
    <Route element={<Outlet />}>
      <Route
        path='superusers/list'
        element={
          <>
            <PageTitle breadcrumbs={[]}>Super Users</PageTitle>
            <SettingsUsers />
          </>
        }
      />
      <Route
        path='roles/list'
        element={
          <>
            <PageTitle breadcrumbs={[]}></PageTitle>
            <SettingsRoles />
          </>
        }
      />
      <Route
        path='country'
        element={
          <>
            <PageTitle breadcrumbs={[]}></PageTitle>
            <SettingsCountry />
          </>
        }
      />
      <Route index element={<Navigate to='/settings/superusers/list' />} />
    </Route>
  </Routes>
)
export {SettingsPage}
