/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState, ReactPortal} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import {KTIcon} from '../../../_metronic/helpers/components/KTIcon'
import {toAbsoluteUrl} from '../../../_metronic/helpers/AssetHelpers'
import {FormStatus} from '../models/Form'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {axiosGet, axiosPost, axiosPut} from '../helpers'
import clsx from 'clsx'
import {OrderStatus, TrackingStatus} from '../models/Orders'

type Props = {
  show: boolean
  handleClose: () => void
  res?: any
}

const modalsRoot = document.getElementById('root-modals') || document.body

const EditProdCategoryModal = ({res, show, handleClose}: Props) => {
  const [data, setData] = useState<any>(res)
  const [loading, setLoading] = useState(false)

  const handleChange = (e: any) => {
    setData({...data, [e.target.name]: e.target.value})
  }
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(`Category name is required`),
    description: Yup.string().required(`Category description is required`),
    status: Yup.number().required(`Category status is required`),
  })
  const initialValues = {
    name: data.name,
    description: data.description,
    status: data.status,
  }

  const path = `products/category/list/${data.id}`
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setLoading(true)
      const result = await axiosPut({
        path: path,
        data: {
          name: values.name,
          description: values.description,
          status: parseInt(values.status),
        },
      })
      if (result.isSuccess) {
        setSubmitting(false)
        setLoading(false)
      } else {
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-700px'
      show={show}
      onHide={handleClose}
      backdrop={true}
    >
      <div className='modal-header border-0'>
        <h2>Edit Product Category - {data.name}</h2>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTIcon className='fs-1' iconName='cross' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>
        <form onSubmit={formik.handleSubmit}>
          <div className='fv-row mb-10 row'>
            <div className='col-md-6'>
              <div className='form-floating mb-3'>
                <input
                  type='text'
                  id='editprodcategoryname'
                  placeholder='Enter name'
                  {...formik.getFieldProps('name')}
                  className={clsx(
                    'form-control border-1',
                    {'is-invalid': formik.touched.name && formik.errors.name},
                    {
                      'is-valid': formik.touched.name && !formik.errors.name,
                    }
                  )}
                  name='name'
                  value={data.name}
                  onChange={handleChange}
                />
                <label htmlFor='editprodcategoryname'>Category Name</label>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-floating'>
                <select
                  id='editprodcatstatus'
                  aria-label='Category Status'
                  {...formik.getFieldProps('status')}
                  className={clsx(
                    'form-select border-1',
                    {'is-invalid': formik.touched.status && formik.errors.status},
                    {
                      'is-valid': formik.touched.status && !formik.errors.status,
                    }
                  )}
                  name='status'
                >
                  <option selected>Choose Category Status</option>
                  {FormStatus.length > 0 &&
                    FormStatus.map((item) => <option value={item.statusCode}>{item.name}</option>)}
                </select>
                <label htmlFor='editprodcatstatus'>Category Status</label>
              </div>
            </div>
          </div>
          <div className='fv-row mb-10 row'>
            <div className='col-md-12 col-lg-12'>
              <div className='form-floating'>
                <textarea
                  placeholder='Enter description'
                  id='editprodcategorydesc'
                  style={{height: '100px'}}
                  {...formik.getFieldProps('description')}
                  className={clsx(
                    'form-control',
                    {'is-invalid': formik.touched.description && formik.errors.description},
                    {
                      'is-valid': formik.touched.description && !formik.errors.description,
                    }
                  )}
                  value={data.description}
                  onChange={handleChange}
                  name='description'
                ></textarea>
                <label htmlFor='editprodcategorydesc'>Description</label>
              </div>
            </div>
          </div>
          <div className='fv-row mb-10 row justify-content-start'>
            <button
              type='submit'
              id='editprodcatbtn'
              className='btn afri-btn-bg-primary w-50'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && <span className='indicator-label'>Save changes</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </Modal>,
    modalsRoot
  ) as ReactPortal
}
const EditRoleModal = ({res, show, handleClose}: Props) => {
  const [data, setData] = useState<any>(res)
  const [loading, setLoading] = useState(false)
  const [permissions, setPermissions] = useState<any[]>([])

  const handleChange = (e: any) => {
    setData({...data, [e.target.name]: e.target.value})
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(`Role name is required`),
    permissions: Yup.array().required(`Role permissions is required`),
  })
  const initialValues = {
    name: data.name,
    permissions: data.role_permissions,
  }

  useEffect(() => {
    ;(async () => {
      const result = await axiosGet({path: `user/permissions/list`})
      if (result.isSuccess) {
        setPermissions(result.permissions)
      }
    })()
  }, [])

  const path = `user/role/${data.id}`
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      setLoading(true)

      const result = await axiosPut({
        path: path,
        data: {
          name: values.name,
          permissions: values.permissions,
        },
      })
      if (result.isSuccess) {
        setSubmitting(false)
        setLoading(false)
        resetForm()
      } else {
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  const handleCheck = (e: any) => {
    console.log(e)
    e.target.checked = true
  }

  console.log(data.role_permission)

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-700px'
      show={show}
      onHide={handleClose}
      backdrop={true}
    >
      <div className='modal-header border-0'>
        <h2>Edit Role - {data.name}</h2>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTIcon className='fs-1' iconName='cross' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>
        <form onSubmit={formik.handleSubmit}>
          <div className='fv-row mb-10 row'>
            <div className='col-md-12'>
              <div className='form-floating mb-3'>
                <input
                  type='text'
                  {...formik.getFieldProps('name')}
                  className={clsx(
                    'form-control border-1',
                    {'is-invalid': formik.touched.name && formik.errors.name},
                    {
                      'is-valid': formik.touched.name && !formik.errors.name,
                    }
                  )}
                  id='name'
                  placeholder='Enter name'
                  name='name'
                  value={data.name}
                  onChange={handleChange}
                />
                <label htmlFor='name'>Role Name</label>
              </div>
            </div>
            <h4>Role Permissions</h4>
            {permissions.length > 0 &&
              permissions.map((value, index) =>
                data.role_permission.map((item: any, number: number) => (
                  <div className='col-md-4 mb-3 text-wrap' key={index}>
                    <div className='form-check form-switch'>
                      <input
                        {...formik.getFieldProps('permissions')}
                        className={clsx(
                          'form-check-input',
                          {'is-invalid': formik.touched.permissions && formik.errors.permissions},
                          {
                            'is-valid': formik.touched.permissions && !formik.errors.permissions,
                          }
                        )}
                        type='checkbox'
                        role='switch'
                        onChange={handleCheck}
                        id={`permissions_${value.id}`}
                        name='permissions'
                        checked={value.id === item?.permission?.id}
                        value={value.id}
                      />
                      <label
                        className='form-check-label text-capitalize'
                        htmlFor={`permissions_${value.id}`}
                      >
                        {value.name.replace('-', ' ').replace('-', ' ')}
                      </label>
                      <br />
                      <small>{value.description}</small>
                    </div>
                  </div>
                ))
              )}
          </div>
          <div className='fv-row mb-10 mt-5 row justify-content-start'>
            <button
              type='submit'
              id='editprodcatbtn'
              className='btn afri-btn-bg-primary w-50'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && <span className='indicator-label'>Save Changes</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </Modal>,
    modalsRoot
  ) as ReactPortal
}
const EditCountryModal = ({res, show, handleClose}: Props) => {
  const [data, setData] = useState<any>(res)
  const [loading, setLoading] = useState(false)

  const handleChange = (e: any) => {
    setData((prevData: any) => ({...prevData, [e.target.name]: e.target.value}))
  }

  const validationSchema = Yup.object().shape({
    shipping_fee: Yup.string().required(`Fee is required`),
  })
  const initialValues = {
    shipping_fee: data.shipping_fee,
  }

  // useEffect(() => {
  //   ;(async () => {
  //     const result = await axiosGet({path: `user/permissions/list`})
  //     if (result.isSuccess) {
  //       setPermissions(result.permissions)
  //     }
  //   })()
  // }, [])

  const path = `resources/country/${data.id}`
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      setLoading(true)
      console.log(values)
      const result = await axiosPut({
        path: path,
        data: {
          shipping_fee: parseInt(values.shipping_fee),
        },
      })
      if (result.isSuccess) {
        setSubmitting(false)
        setLoading(false)
        handleClose()
        resetForm()
      } else {
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  // const handleCheck = (e: any) => {
  //   console.log(e)
  //   e.target.checked = true
  // }

  // console.log(data.role_permission)

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-700px'
      show={show}
      onHide={handleClose}
      backdrop={true}
    >
      <div className='modal-header border-0'>
        <h2>Edit Shipping Fee - {data.name}</h2>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTIcon className='fs-1' iconName='cross' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>
        <form onSubmit={formik.handleSubmit}>
          <div className='fv-row mb-10 row'>
            <div className='col-md-12'>
              <div className='form-floating mb-3'>
                <input
                  type='text'
                  {...formik.getFieldProps('shipping_fee')}
                  className={clsx(
                    'form-control border-1',
                    {'is-invalid': formik.touched.shipping_fee && formik.errors.shipping_fee},
                    {
                      'is-valid': formik.touched.shipping_fee && !formik.errors.shipping_fee,
                    }
                  )}
                  id='shipping_fee'
                  placeholder='Enter Shipping fee'
                  name='shipping_fee'
                  value={data.shipping_fee}
                  onChange={handleChange}
                />
                <label htmlFor='shipping_fee'>Shipping Fee</label>
              </div>
            </div>
          </div>
          <div className='fv-row mb-10 mt-5 row justify-content-start'>
            <button
              type='submit'
              id='editprodcatbtn'
              className='btn afri-btn-bg-primary w-50'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && <span className='indicator-label'>Save Changes</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </Modal>,
    modalsRoot
  ) as ReactPortal
}
const SetWinnerModal = ({res, show, handleClose}: Props) => {
  const [data, setData] = useState<any>(res)
  const [loading, setLoading] = useState(false)

  const initialValues = {
    participants: ``,
  }

  console.log(data)
  const path = `raffle/draw/setwinner`
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (values, {setSubmitting}) => {
      setLoading(true)
      console.log(values.participants)
      const result = await axiosPost({
        path: path,
        data: {
          participantId: values.participants,
          raffleId: data.id,
        },
      })
      if (result.isSuccess) {
        setSubmitting(false)
        setLoading(false)
      } else {
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-700px'
      show={show}
      onHide={handleClose}
      backdrop={true}
    >
      <div className='modal-header border-0'>
        <h2>Set Raffle {data.ticket_name} - Winner</h2>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTIcon className='fs-1' iconName='cross' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>
        <form onSubmit={formik.handleSubmit}>
          <div className='fv-row mb-10 row'>
            <div className='col-md-12'>
              <div className='form-floating'>
                <select
                  id='participants'
                  aria-label='Choose participants'
                  {...formik.getFieldProps('participants')}
                  className={clsx(
                    'form-select border-1',
                    {'is-invalid': formik.touched.participants && formik.errors.participants},
                    {
                      'is-valid': formik.touched.participants && !formik.errors.participants,
                    }
                  )}
                  name='participants'
                >
                  <option selected>Choose Participants</option>
                  {data.participants.length > 0 &&
                    data.participants.map((item: any) => (
                      <option value={item.id}>
                        {item?.user?.firstname} {item?.user?.lastname} - {item?.user?.email} -
                        Entry(s) {item.entry}
                      </option>
                    ))}
                </select>
                <label htmlFor='participants'>Choose Winner</label>
              </div>
            </div>
          </div>
          <div className='fv-row mb-10 row justify-content-start'>
            <button
              type='submit'
              id='editprodcatbtn'
              className='btn afri-btn-bg-primary w-50'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && <span className='indicator-label'>Save changes</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </Modal>,
    modalsRoot
  ) as ReactPortal
}
const EditRaffleCategoryModal = ({res, show, handleClose}: Props) => {
  const [data, setData] = useState<any>(res)
  const [loading, setLoading] = useState(false)

  const handleChange = (e: any) => {
    setData({...data, [e.target.name]: e.target.value})
  }
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(`Category name is required`),
    description: Yup.string().required(`Category description is required`),
    status: Yup.string().required(`Category status is required`),
  })
  const initialValues = {
    name: data.name,
    description: data.description,
    status: data.status,
  }

  const path = `raffle/category/list/${data.id}`
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setLoading(true)
      const result = await axiosPut({
        path: path,
        data: {
          name: values.name,
          description: values.description,
          status: values.status,
        },
      })
      if (result.isSuccess) {
        setSubmitting(false)
        setLoading(false)
      } else {
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-700px'
      show={show}
      onHide={handleClose}
      backdrop={true}
    >
      <div className='modal-header border-0'>
        <h2>Edit Raffle Category - {data.name}</h2>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTIcon className='fs-1' iconName='cross' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>
        <form onSubmit={formik.handleSubmit}>
          <div className='fv-row mb-10 row'>
            <div className='col-md-6'>
              <div className='form-floating mb-3'>
                <input
                  type='text'
                  id='editprodcategoryname'
                  placeholder='Enter name'
                  {...formik.getFieldProps('name')}
                  className={clsx(
                    'form-control border-1',
                    {'is-invalid': formik.touched.name && formik.errors.name},
                    {
                      'is-valid': formik.touched.name && !formik.errors.name,
                    }
                  )}
                  name='name'
                  value={data.name}
                  onChange={handleChange}
                />
                <label htmlFor='editprodcategoryname'>Category Name</label>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-floating'>
                <select
                  id='editprodcatstatus'
                  aria-label='Category Status'
                  {...formik.getFieldProps('status')}
                  className={clsx(
                    'form-select border-1',
                    {'is-invalid': formik.touched.status && formik.errors.status},
                    {
                      'is-valid': formik.touched.status && !formik.errors.status,
                    }
                  )}
                  name='status'
                >
                  <option selected>Choose Category Status</option>
                  {FormStatus.length > 0 &&
                    FormStatus.map((item) => <option value={item.statusCode}>{item.name}</option>)}
                </select>
                <label htmlFor='editprodcatstatus'>Category Status</label>
              </div>
            </div>
          </div>
          <div className='fv-row mb-10 row'>
            <div className='col-md-12 col-lg-12'>
              <div className='form-floating'>
                <textarea
                  placeholder='Enter description'
                  id='editprodcategorydesc'
                  style={{height: '100px'}}
                  {...formik.getFieldProps('description')}
                  className={clsx(
                    'form-control',
                    {'is-invalid': formik.touched.description && formik.errors.description},
                    {
                      'is-valid': formik.touched.description && !formik.errors.description,
                    }
                  )}
                  value={data.description}
                  onChange={handleChange}
                  name='description'
                ></textarea>
                <label htmlFor='editprodcategorydesc'>Description</label>
              </div>
            </div>
          </div>
          <div className='fv-row mb-10 row justify-content-start'>
            <button
              type='submit'
              id='editprodcatbtn'
              className='btn afri-btn-bg-primary w-50'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && <span className='indicator-label'>Save changes</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </Modal>,
    modalsRoot
  ) as ReactPortal
}
const EditTrackingModal = ({res, show, handleClose}: Props) => {
  const [data, setData] = useState<any>(res)
  const [loading, setLoading] = useState(false)

  const handleChange = (e: any) => {
    setData({...data, [e.target.name]: e.target.value})
  }

  const initialValues = {
    status: data.status,
    location: data.location,
    comment: data.comment,
  }

  const path = `orders/track/${data.tracking_number}`
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: async (values, {setSubmitting}) => {
      setLoading(true)
      const result = await axiosPut({
        path: path,
        data: {
          status: parseInt(values.status),
          comment: values.comment,
          location: values.location,
        },
      })
      if (result.isSuccess) {
        setSubmitting(false)
        setLoading(false)
      } else {
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-700px'
      show={show}
      onHide={handleClose}
      backdrop={true}
    >
      <div className='modal-header border-0'>
        <h2>Update Tacking - {data.tracking_number}</h2>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTIcon className='fs-1' iconName='cross' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>
        <form onSubmit={formik.handleSubmit}>
          <div className='fv-row mb-10 row'>
            <div className='col-md-6'>
              <div className='form-floating mb-3'>
                <input
                  type='text'
                  id='edittracklocation'
                  placeholder='Enter Location'
                  {...formik.getFieldProps('location')}
                  className={clsx('form-control border-1')}
                  name='location'
                  value={data.location}
                  onChange={handleChange}
                />
                <label htmlFor='edittracklocation'>Location</label>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-floating'>
                <select
                  id='editprodcatstatus'
                  aria-label='Tracking Status'
                  {...formik.getFieldProps('status')}
                  className={clsx('form-select border-1')}
                  name='status'
                  onChange={handleChange}
                  value={data.status}
                >
                  <option selected>Choose Category Status</option>
                  {TrackingStatus.length > 0 &&
                    TrackingStatus.map((item) => (
                      <option value={item.statusCode}>{item.name}</option>
                    ))}
                </select>
                <label htmlFor='editprodcatstatus'>Category Status</label>
              </div>
            </div>
          </div>
          <div className='fv-row mb-10 row'>
            <div className='col-md-12 col-lg-12'>
              <div className='form-floating'>
                <textarea
                  placeholder='Enter comment'
                  id='edittrackcomment'
                  style={{height: '100px'}}
                  {...formik.getFieldProps('comment')}
                  className={clsx('form-control')}
                  value={data.comment}
                  onChange={handleChange}
                  name='comment'
                ></textarea>
                <label htmlFor='edittrackcomment'>Comment</label>
              </div>
            </div>
          </div>
          <div className='fv-row mb-10 row justify-content-start'>
            <button
              type='submit'
              id='editprodcatbtn'
              className='btn afri-btn-bg-primary w-50'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && <span className='indicator-label'>Save changes</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </Modal>,
    modalsRoot
  ) as ReactPortal
}
const EditOrderModal = ({res, show, handleClose}: Props) => {
  const [data, setData] = useState<any>(res)
  const [loading, setLoading] = useState(false)

  const handleChange = (e: any) => {
    setData({...data, [e.target.name]: e.target.value})
  }
  const validationSchema = Yup.object().shape({
    status: Yup.string().required(`Category status is required`),
    quantity: Yup.number().required(`Quantity status is required`),
  })
  const initialValues = {
    status: data.status,
    quantity: data.quantity,
  }

  const path = `orders/list/${data.id}`
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setLoading(true)
      const result = await axiosPut({
        path: path,
        data: {
          status: values.status,
          quantity: values.quantity,
        },
      })
      console.log(result)
      if (result.isSuccess) {
        setSubmitting(false)
        setLoading(false)
      } else {
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-700px'
      show={show}
      onHide={handleClose}
      backdrop={true}
    >
      <div className='modal-header border-0'>
        <h2>Edit Order - {data.tracking.tracking_number}</h2>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTIcon className='fs-1' iconName='cross' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>
        <form onSubmit={formik.handleSubmit}>
          <div className='fv-row mb-10 row'>
            <div className='col-md-6'>
              <div className='form-floating mb-3'>
                <input
                  type='text'
                  id='editprodcategoryname'
                  placeholder='Enter name'
                  {...formik.getFieldProps('quantity')}
                  className={clsx(
                    'form-control border-1',
                    {'is-invalid': formik.touched.quantity && formik.errors.quantity},
                    {
                      'is-valid': formik.touched.quantity && !formik.errors.quantity,
                    }
                  )}
                  name='quantity'
                  value={data.quantity}
                  onChange={handleChange}
                />
                <label htmlFor='editprodcategoryname'>Quantity</label>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-floating'>
                <select
                  id='editorderstatus'
                  aria-label='Category Status'
                  {...formik.getFieldProps('status')}
                  className={clsx(
                    'form-select border-1 text-capitalize',
                    {'is-invalid': formik.touched.status && formik.errors.status},
                    {
                      'is-valid': formik.touched.status && !formik.errors.status,
                    }
                  )}
                  name='status'
                  value={data.status}
                  onChange={handleChange}
                >
                  <option selected>Change Order Status</option>
                  {OrderStatus.length > 0 &&
                    OrderStatus.map((item) => <option value={item.statusCode}>{item.name}</option>)}
                </select>
                <label htmlFor='editorderstatus'>Order Status</label>
              </div>
            </div>
          </div>
          <div className='fv-row mb-10 row justify-content-start'>
            <button
              type='submit'
              id='editprodcatbtn'
              className='btn afri-btn-bg-primary w-50'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && <span className='indicator-label'>Save changes</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </Modal>,
    modalsRoot
  ) as ReactPortal
}

export {
  EditRoleModal,
  SetWinnerModal,
  EditProdCategoryModal,
  EditRaffleCategoryModal,
  EditOrderModal,
  EditTrackingModal,
  EditCountryModal,
}
