/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {useAuth} from '../core/Auth'
import {axiosPost} from '../../../lib/helpers'
import {toAbsoluteUrl} from '../../../../_metronic/helpers/AssetHelpers'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

export function Login() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const [passwordType, setPasswordType] = useState('password')

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const auth = await axiosPost({
          data: {
            email: values.email,
            password: values.password,
            role: 1,
          },
          isLoading: loading,
          path: `auth/login`,
        })
        if (auth?.statusCode === 200 || auth?.status === 200) {
          saveAuth(auth)
          setCurrentUser(auth)
        }
        setLoading(false)
        setSubmitting(false)
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  const togglePassword = () => {
    if (passwordType === 'password') {
      setPasswordType('text')
      return
    }
    setPasswordType('password')
  }
  return (
    <>
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_login_signin_form'
        autoComplete='off'
      >
        {formik.status && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )}

        {/* begin::Form group */}
        <div className='fv-row mb-8'>
          <div className='input-group mb-3'>
            <div className='input-group-prepend'>
              <span className='input-group-text' id='login-email-addon'>
                <img
                  src={toAbsoluteUrl(`/media/auth/login-email-icon.svg`)}
                  className='h-20px'
                  alt='login password icon'
                />
              </span>
            </div>
            <input
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control afri-form-control-auth',
                {'is-invalid': formik.touched.email && formik.errors.email},
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
              type='email'
              name='email'
              autoComplete='off'
              placeholder='Email'
              aria-label='Email'
              aria-describedby='login-email-addon'
            />
          </div>
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}
        {/* begin::Form group */}
        <div className='fv-row mb-8'>
          <div className='input-group mb-3'>
            <div className='input-group-prepend'>
              <span className='input-group-text' id='login-password-addon'>
                <img
                  src={toAbsoluteUrl(`/media/auth/login-password-key.svg`)}
                  className='h-20px'
                  alt='login password icon'
                />
              </span>
            </div>
            <input
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control afri-form-control-auth',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
              type={passwordType}
              autoComplete='off'
              placeholder='Password'
              aria-label='Password'
              aria-describedby='login-password-addon'
            />
            <div
              className='input-group-append'
              onClick={togglePassword}
              style={{cursor: 'pointer'}}
            >
              <span className='input-group-text'>
                {passwordType === 'password' ? (
                  <i className='bi bi-eye-fill fs-2'></i>
                ) : (
                  <img
                    src={toAbsoluteUrl(`/media/auth/hide-password-icon.svg`)}
                    className='h-20px'
                    alt='login password icon'
                  />
                )}
              </span>
            </div>
          </div>
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Wrapper */}
        <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
          <div />

          {/* begin::Link */}
          <Link to='/auth/forgot-password' className='afri-text-primary-color'>
            Forgot Password ?
          </Link>
          {/* end::Link */}
        </div>
        {/* end::Wrapper */}

        {/* begin::Action */}
        <div className='d-grid mb-10'>
          <button
            type='submit'
            id='kt_sign_in_submit'
            className='btn afri-btn-bg-primary'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className='indicator-label'>Login</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Action */}
      </form>
    </>
  )
}
