/* eslint-disable react/jsx-no-target-blank */
import React, {Fragment, useState} from 'react'
import {SidebarMenuItem} from './SidebarMenuItem'
import {Link} from 'react-router-dom'
import {Media} from '../../../../../app/pages'
import {accessPermissions} from '../../../../../app/pages/auth/core/Permissions'

const SidebarMenuMain = () => {
  const {orders, products, payments, users, tracking, raffle, settings, notifications, media} =
    accessPermissions()
  return (
    <Fragment>
      <div className='menu-item'>
        <Link to='#' className='text-uppercase fw-light menu-link without-sub menu-text-manage'>
          Manage
        </Link>
      </div>
      <SidebarMenuItem to='/home' icon='/media/menu/home-icon.svg' title='Home' />
      <SidebarMenuItem
        to='/orders/list'
        icon='/media/menu/cart-icon.svg'
        title='Orders'
        access={orders}
      />
      <SidebarMenuItem
        to='/products/list'
        icon='/media/menu/tag-icon.svg'
        title='Products'
        access={products}
      />
      <SidebarMenuItem
        to='/customers/list'
        icon='/media/menu/customers-icon.svg'
        title='Customers'
        access={users}
      />
      <SidebarMenuItem
        to='/payments/list'
        icon='/media/menu/payments-icon.svg'
        title='Payments'
        access={payments}
      />

      <SidebarMenuItem
        to='/tracking/list'
        icon='/media/menu/tracking-icon.svg'
        title='Tracking'
        access={tracking}
      />
      <SidebarMenuItem
        to='/raffle/list'
        icon='/media/menu/raffle-icon.svg'
        title='Raffle Draw'
        access={raffle}
      />
      <SidebarMenuItem
        to='/notifications/list'
        icon='/media/menu/notification.svg'
        title='Notifications'
        access={notifications}
      />
      <SidebarMenuItem
        to='/media/popup/list'
        icon='/media/menu/media.svg'
        title='Media'
        access={media}
      />

      <SidebarMenuItem
        to='/settings/superusers/list'
        icon='/media/menu/settings-icon.svg'
        title='Settings'
        access={settings}
      />
    </Fragment>
  )
}

export {SidebarMenuMain}
