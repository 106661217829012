import React, {useEffect, useState} from 'react'
import {Card} from '../../lib/widgets/Card'
import {Chart} from '../../lib/widgets/Chart'
import {Loader, LoaderTimout} from '../../lib/widgets/Loader'
import {axiosGet} from '../../lib/helpers'
import {NumericFormat} from 'react-number-format'
import {accessPermissions} from '../auth/core/Permissions'

const Home = () => {
  const [orderslist, setOrders] = useState<any[]>([])
  const [loading, setLoading] = useState(true)
  const [userslist, setUsers] = useState<any[]>([])
  const [rafflelist, setRaffle] = useState<any[]>([])
  const {orders, products, payments, users, tracking, raffle, settings, notifications, media} =
    accessPermissions()
  useEffect(() => {
    ;(async () => {
      const orderList = await axiosGet({path: `orders/list`})
      if (orderList.isSuccess) {
        setOrders(orderList.orders)
        setTimeout(() => {
          setLoading(false)
        }, LoaderTimout)
      }
      const userList = await axiosGet({path: `user/list`})
      if (userList.isSuccess) {
        setUsers(userList.users)
        setTimeout(() => {
          setLoading(false)
        }, LoaderTimout)
      }
      const raffleList = await axiosGet({path: `raffle/list/all`})
      if (raffleList.isSuccess) {
        setRaffle(raffleList.raffle)
        setTimeout(() => {
          setLoading(false)
        }, LoaderTimout)
      }
    })()
  }, [])

  return (
    <>
      {/* begin::Row */}
      <div className='row g-5 g-xl-8'>
        <div className='col-md-4'>
          {orders && !loading && orderslist && orderslist.length > 0 ? (
            <Card
              className='card-xl-stretch my-xl-8 shadow'
              svgIcon='/media/card/orders.svg'
              color='white'
              iconColor='dark'
              title={
                <NumericFormat
                  value={orderslist.length}
                  allowLeadingZeros
                  thousandSeparator=','
                  displayType='text'
                />
              }
              titleColor='dark'
              description='Orders'
              descriptionColor='dark'
              to='/orders/list'
            />
          ) : (
            <div className='d-flex justify-content-center'>
              <Loader isError={orderslist.length} errorMessage='Error occured' />
            </div>
          )}
        </div>

        <div className='col-md-4'>
          {users && !loading && userslist && userslist.length > 0 ? (
            <Card
              className='card-xl-stretch my-xl-8 shadow'
              svgIcon='/media/card/customers.svg'
              color='white'
              iconColor='dark'
              title={
                <NumericFormat
                  value={userslist.length}
                  allowLeadingZeros
                  thousandSeparator=','
                  displayType='text'
                />
              }
              titleColor='dark'
              description='Customers'
              descriptionColor='dark'
              to='/customers/list'
            />
          ) : (
            <div className='d-flex justify-content-center'>
              <Loader isError={orderslist.length} errorMessage='Error occured' />
            </div>
          )}
        </div>
        <div className='col-md-4'>
          {raffle && !loading && rafflelist && rafflelist.length > 0 ? (
            <Card
              className='card-xl-stretch my-xl-8 shadow'
              svgIcon='/media/card/raffledraw.svg'
              color='white'
              iconColor='dark'
              title={
                <NumericFormat
                  value={rafflelist.length}
                  allowLeadingZeros
                  thousandSeparator=','
                  displayType='text'
                />
              }
              titleColor='dark'
              description='Raffle draw'
              descriptionColor='dark'
              to='/raffle/list'
            />
          ) : (
            <div className='d-flex justify-content-center'>
              <Loader isError={orderslist.length} errorMessage='Error occured' />
            </div>
          )}
        </div>

        <div className='col-xl-12'>{<Chart className='card-xl-stretch mb-xl-8 shadow' />}</div>
      </div>
      {/* end::Row */}
    </>
  )
}
export {Home}
