import {Table} from '../../../lib/table/Table'
import {OrdersColumn} from '../../../lib/table/components/Columns'
import {FC, useEffect, useState} from 'react'
import {axiosGet} from '../../../lib/helpers'

const OrdersList: FC<any> = ({state}) => {
  const [data, setData] = useState<any[]>([])

  useEffect(() => {
    ;(async () => {
      const result = await axiosGet({path: `orders/list`})
      if (result.isSuccess) {
        setData(result.orders)
      }
    })()
  }, [])

  return (
    <>
      <Table data={data} columns={OrdersColumn} />
    </>
  )
}
export {OrdersList}
