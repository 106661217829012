import {useState} from 'react'
export const LoaderTimout = 3000
type Props = {
  errorMessage?: string
  isError?: number
  className?: string
}
const Loader = ({errorMessage, isError, className}: Props) => {
  const [error, setError] = useState(false)

  if (isError === 0) {
    setTimeout(() => {
      setError(true)
    }, 10000)
  }

  return (
    <>
      {!error ? (
        <div className='lds-facebook'>
          <div></div>
          <div></div>
          <div></div>
        </div>
      ) : (
        <div className='row d-flex justify-content-center'>
          <div className={className}>
            <div className='alert alert-info fs-4 fw-light text-capitalize' role='alert'>
              {errorMessage}
            </div>
          </div>
        </div>
      )}
    </>
  )
}
export {Loader}
