export const OrderStatus = [
  {
    statusCode: 1,
    name: `pending`,
  },
  {
    statusCode: 0,
    name: `rejected`,
  },
  {
    statusCode: 3,
    name: `approved`,
  },
  {
    statusCode: 4,
    name: `shipped`,
  },
  {
    statusCode: 5,
    name: `delivered`,
  },
  {
    statusCode: 6,
    name: `received`,
  },
  {
    statusCode: 7,
    name: `intransit`,
  },
]
export const TrackingStatus = [
  {
    statusCode: 1,
    name: `pending`,
  },
  {
    statusCode: 4,
    name: `shipped`,
  },
  {
    statusCode: 5,
    name: `delivered`,
  },
  {
    statusCode: 6,
    name: `received`,
  },
  {
    statusCode: 7,
    name: `intransit`,
  },
]
export type SingleOrderModel = {
  data?: []
  tracking: object
}
