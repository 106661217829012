import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import {KTIcon} from '../../../_metronic/helpers/components/KTIcon'
import {toAbsoluteUrl} from '../../../_metronic/helpers/AssetHelpers'
import {FormStatus} from '../models/Form'
import {useEffect, useState, ReactPortal} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {axiosGet, axiosPost, notifyFailure} from '../helpers'
import clsx from 'clsx'

type Props = {
  show: boolean
  handleClose: () => void
}
const modalsRoot = document.getElementById('root-modals') || document.body
const CreateProductCategory = ({show, handleClose}: Props) => {
  const [loading, setLoading] = useState(false)
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(`Category name is required`),
    description: Yup.string().required(`Category description is required`),
    status: Yup.number().required(`Category status is required`),
  })
  const initialValues = {
    name: ``,
    description: ``,
    status: ``,
  }

  const path = `products/category/new`
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setLoading(true)
      const result = await axiosPost({
        path: path,
        data: {
          name: values.name,
          description: values.description,
          status: parseInt(values.status),
        },
      })
      if (result.isSuccess) {
        setSubmitting(false)
        setLoading(false)
      } else {
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-700px'
      show={show}
      onHide={handleClose}
      backdrop={true}
    >
      <div className='modal-header border-0'>
        <h2>Create Product Category</h2>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTIcon className='fs-1' iconName='cross' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>
        <form onSubmit={formik.handleSubmit}>
          <div className='fv-row mb-10 row'>
            <div className='col-md-6'>
              <div className='form-floating mb-3'>
                <input
                  type='text'
                  {...formik.getFieldProps('name')}
                  className={clsx(
                    'form-control border-1',
                    {'is-invalid': formik.touched.name && formik.errors.name},
                    {
                      'is-valid': formik.touched.name && !formik.errors.name,
                    }
                  )}
                  id='prodcategoryname'
                  placeholder='Enter name'
                  name='name'
                />
                <label htmlFor='prodcategoryname'>Category Name</label>
              </div>
              {formik.touched.name && formik.errors.name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.name}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-md-6'>
              <div className='form-floating'>
                <select
                  id='prodcatstatus'
                  aria-label='Category Status'
                  {...formik.getFieldProps('status')}
                  className={clsx(
                    'form-select border-1',
                    {'is-invalid': formik.touched.status && formik.errors.status},
                    {
                      'is-valid': formik.touched.status && !formik.errors.status,
                    }
                  )}
                  name='status'
                >
                  <option selected>Choose Category Status</option>
                  {FormStatus.length > 0 &&
                    FormStatus.map((item) => <option value={item.statusCode}>{item.name}</option>)}
                </select>
                <label htmlFor='prodcatstatus'>Category Status</label>
              </div>
              {formik.touched.status && formik.errors.status && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.status}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='fv-row mb-10 row'>
            <div className='col-md-12 col-lg-12'>
              <div className='form-floating'>
                <textarea
                  placeholder='Enter description'
                  id='prodcategorydesc'
                  style={{height: '100px'}}
                  {...formik.getFieldProps('description')}
                  className={clsx(
                    'form-control',
                    {'is-invalid': formik.touched.description && formik.errors.description},
                    {
                      'is-valid': formik.touched.description && !formik.errors.description,
                    }
                  )}
                  name='description'
                ></textarea>
                <label htmlFor='prodcategorydesc'>Description</label>
              </div>
              {formik.touched.description && formik.errors.description && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.description}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='fv-row mb-10 row justify-content-start'>
            <button
              type='submit'
              id='editprodcatbtn'
              className='btn afri-btn-bg-primary w-50'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && <span className='indicator-label'>Continue</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </Modal>,
    modalsRoot
  ) as ReactPortal
}
const CreateRaffleCategory = ({show, handleClose}: Props) => {
  const [loading, setLoading] = useState(false)
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(`Category name is required`),
    description: Yup.string().required(`Category description is required`),
    status: Yup.number().required(`Category status is required`),
  })
  const initialValues = {
    name: ``,
    description: ``,
    status: ``,
  }

  const path = `raffle/category/create`
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setLoading(true)
      const result = await axiosPost({
        path: path,
        data: {
          name: values.name,
          description: values.description,
          status: parseInt(values.status),
        },
      })
      if (result.isSuccess) {
        setSubmitting(false)
        setLoading(false)
      } else {
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-700px'
      show={show}
      onHide={handleClose}
      backdrop={true}
    >
      <div className='modal-header border-0'>
        <h2>Create Raffle Category</h2>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTIcon className='fs-1' iconName='cross' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>
        <form onSubmit={formik.handleSubmit}>
          <div className='fv-row mb-10 row'>
            <div className='col-md-6'>
              <div className='form-floating mb-3'>
                <input
                  type='text'
                  {...formik.getFieldProps('name')}
                  className={clsx(
                    'form-control border-1',
                    {'is-invalid': formik.touched.name && formik.errors.name},
                    {
                      'is-valid': formik.touched.name && !formik.errors.name,
                    }
                  )}
                  id='prodcategoryname'
                  placeholder='Enter name'
                  name='name'
                />
                <label htmlFor='prodcategoryname'>Category Name</label>
              </div>
              {formik.touched.name && formik.errors.name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.name}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-md-6'>
              <div className='form-floating'>
                <select
                  id='prodcatstatus'
                  aria-label='Category Status'
                  {...formik.getFieldProps('status')}
                  className={clsx(
                    'form-select border-1',
                    {'is-invalid': formik.touched.status && formik.errors.status},
                    {
                      'is-valid': formik.touched.status && !formik.errors.status,
                    }
                  )}
                  name='status'
                >
                  <option selected>Choose Category Status</option>
                  {FormStatus.length > 0 &&
                    FormStatus.map((item) => <option value={item.statusCode}>{item.name}</option>)}
                </select>
                <label htmlFor='prodcatstatus'>Category Status</label>
              </div>
              {formik.touched.status && formik.errors.status && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.status}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='fv-row mb-10 row'>
            <div className='col-md-12 col-lg-12'>
              <div className='form-floating'>
                <textarea
                  placeholder='Enter description'
                  id='prodcategorydesc'
                  style={{height: '100px'}}
                  {...formik.getFieldProps('description')}
                  className={clsx(
                    'form-control',
                    {'is-invalid': formik.touched.description && formik.errors.description},
                    {
                      'is-valid': formik.touched.description && !formik.errors.description,
                    }
                  )}
                  name='description'
                ></textarea>
                <label htmlFor='prodcategorydesc'>Description</label>
              </div>
              {formik.touched.description && formik.errors.description && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.description}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='fv-row mb-10 row justify-content-start'>
            <button
              type='submit'
              id='editprodcatbtn'
              className='btn afri-btn-bg-primary w-50'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && <span className='indicator-label'>Continue</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </Modal>,
    modalsRoot
  ) as ReactPortal
}
const CreateUserModal = ({show, handleClose}: Props) => {
  const [loading, setLoading] = useState(false)
  const validationSchema = Yup.object().shape({
    firstname: Yup.string().required(`Firstname is required`),
    lastname: Yup.string().required(`Lastname is required`),
    phone: Yup.number().required(`Phone number is required`),
    role: Yup.string().required(`Role is required`),
    password: Yup.string().required(`Password is required`),
    confirmpassword: Yup.string().required(`Confirm Password is required`),
  })
  const initialValues = {
    firstname: ``,
    lastname: ``,
    phone: ``,
    email: ``,
    role: ``,
    password: ``,
    confirmpassword: ``,
  }

  const [roles, setRoles] = useState<any[]>([])
  useEffect(() => {
    ;(async () => {
      const result = await axiosGet({path: `user/role/list`})
      console.log(result)
      if (result.isSuccess) {
        setRoles(result.roles)
      }
    })()
  }, [])

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setLoading(true)
      if (values.confirmpassword !== values.password) {
        notifyFailure({
          type: `toastr`,
          toastrProps: {
            text: `Password mismatch`,
            type: `error`,
          },
        })
      }
      const result = await axiosPost({
        path: `user/superuser/create`,
        data: {
          firstname: values.firstname,
          lastname: values.lastname,
          phone: `+234${values.phone}`,
          email: values.email,
          role: values.role,
          password: values.password,
          country: `Nigeria`,
        },
      })
      if (result.isSuccess) {
        setSubmitting(false)
        setLoading(false)
      } else {
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-700px'
      show={show}
      onHide={handleClose}
      backdrop={true}
    >
      <div className='modal-header border-0'>
        <h2>Create User</h2>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTIcon className='fs-1' iconName='cross' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>
        <form onSubmit={formik.handleSubmit}>
          <div className='fv-row mb-10 row'>
            <div className='col-md-6'>
              <div className='form-floating mb-3'>
                <input
                  type='text'
                  {...formik.getFieldProps('firstname')}
                  className={clsx(
                    'form-control border-1',
                    {'is-invalid': formik.touched.firstname && formik.errors.firstname},
                    {
                      'is-valid': formik.touched.firstname && !formik.errors.firstname,
                    }
                  )}
                  id='firstname'
                  placeholder='Enter name'
                  name='firstname'
                />
                <label htmlFor='firstname'>Firstname</label>
              </div>
              {formik.touched.firstname && formik.errors.firstname && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.firstname}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-md-6'>
              <div className='form-floating mb-3'>
                <input
                  type='text'
                  {...formik.getFieldProps('lastname')}
                  className={clsx(
                    'form-control border-1',
                    {'is-invalid': formik.touched.lastname && formik.errors.lastname},
                    {
                      'is-valid': formik.touched.lastname && !formik.errors.lastname,
                    }
                  )}
                  id='firstname'
                  placeholder='Enter name'
                  name='lastname'
                />
                <label htmlFor='firstname'>Lastname</label>
              </div>
              {formik.touched.lastname && formik.errors.lastname && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.lastname}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='fv-row mb-10 row'>
            <div className='col-md-12'>
              <div className='form-floating'>
                <select
                  id='role'
                  aria-label='Category Status'
                  {...formik.getFieldProps('role')}
                  className={clsx(
                    'form-select border-1',
                    {'is-invalid': formik.touched.role && formik.errors.role},
                    {
                      'is-valid': formik.touched.role && !formik.errors.role,
                    }
                  )}
                  name='role'
                >
                  <option selected>Choose Role</option>
                  {roles.length > 0 &&
                    roles.map((item) => <option value={item.id}>{item.name}</option>)}
                </select>
                <label htmlFor='role'>User Role</label>
              </div>
              {formik.touched.role && formik.errors.role && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.role}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='fv-row mb-10 row'>
            <div className='col-md-6'>
              <div className='form-floating mb-3'>
                <input
                  type='email'
                  {...formik.getFieldProps('email')}
                  className={clsx(
                    'form-control border-1',
                    {'is-invalid': formik.touched.email && formik.errors.email},
                    {
                      'is-valid': formik.touched.email && !formik.errors.email,
                    }
                  )}
                  id='email'
                  placeholder='Enter Email'
                  name='email'
                />
                <label htmlFor='email'>Email</label>
              </div>
              {formik.touched.email && formik.errors.email && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.email}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-md-6'>
              <div className='input-group'>
                <div className='input-group-prepend'>
                  <span className='input-group-text' id='login-password-addon'>
                    +234
                  </span>
                </div>
                <div className='form-floating mb-3'>
                  <input
                    type='tel'
                    {...formik.getFieldProps('phone')}
                    className={clsx(
                      'form-control border-1',
                      {'is-invalid': formik.touched.phone && formik.errors.phone},
                      {
                        'is-valid': formik.touched.phone && !formik.errors.phone,
                      }
                    )}
                    id='phone'
                    placeholder='Example: +2349034787969'
                    name='phone'
                  />
                  <label htmlFor='phone'>Phone</label>
                </div>
              </div>
              {formik.touched.phone && formik.errors.phone && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.phone}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='fv-row mb-10 row'>
            <div className='col-md-6'>
              <div className='form-floating mb-3'>
                <input
                  type='password'
                  {...formik.getFieldProps('password')}
                  className={clsx(
                    'form-control border-1',
                    {'is-invalid': formik.touched.password && formik.errors.password},
                    {
                      'is-valid': formik.touched.password && !formik.errors.password,
                    }
                  )}
                  id='password'
                  placeholder='Enter Email'
                  name='password'
                />
                <label htmlFor='password'>Password</label>
              </div>
              {formik.touched.password && formik.errors.password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.password}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-md-6'>
              <div className='form-floating mb-3'>
                <input
                  type='password'
                  {...formik.getFieldProps('confirmpassword')}
                  className={clsx(
                    'form-control border-1',
                    {'is-invalid': formik.touched.confirmpassword && formik.errors.confirmpassword},
                    {
                      'is-valid': formik.touched.confirmpassword && !formik.errors.confirmpassword,
                    }
                  )}
                  id='confirmpassword'
                  placeholder='Example:'
                  name='confirmpassword'
                />
                <label htmlFor='confirmpassword'>Confirm Password</label>
              </div>
              {formik.touched.confirmpassword && formik.errors.confirmpassword && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.confirmpassword}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='fv-row mb-10 row justify-content-start'>
            <button
              type='submit'
              id='editprodcatbtn'
              className='btn afri-btn-bg-primary w-50'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && <span className='indicator-label'>Continue</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </Modal>,
    modalsRoot
  ) as ReactPortal
}
const CreateRoleModal = ({show, handleClose}: Props) => {
  const [loading, setLoading] = useState(false)
  const [permissions, setPermissions] = useState<any[]>([])
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(`Role name is required`),
    permissions: Yup.array().required(`Role permissions is required`),
  })
  const initialValues = {
    name: ``,
    permissions: ``,
  }

  useEffect(() => {
    ;(async () => {
      const result = await axiosGet({path: `user/permissions/list`})
      if (result.isSuccess) {
        setPermissions(result.permissions)
      }
    })()
  }, [])

  const path = `user/role/create`
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      setLoading(true)

      const result = await axiosPost({
        path: path,
        data: {
          name: values.name,
          permissions: values.permissions,
        },
      })
      if (result.isSuccess) {
        setSubmitting(false)
        setLoading(false)
        resetForm()
      } else {
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-900px'
      show={show}
      onHide={handleClose}
      backdrop={true}
    >
      <div className='modal-header border-0'>
        <h2>Create Role</h2>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTIcon className='fs-1' iconName='cross' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>
        <form onSubmit={formik.handleSubmit}>
          <div className='fv-row mb-10 row'>
            <div className='col-md-12'>
              <div className='form-floating mb-3'>
                <input
                  type='text'
                  {...formik.getFieldProps('name')}
                  className={clsx(
                    'form-control border-1',
                    {'is-invalid': formik.touched.name && formik.errors.name},
                    {
                      'is-valid': formik.touched.name && !formik.errors.name,
                    }
                  )}
                  id='prodcategoryname'
                  placeholder='Enter name'
                  name='name'
                />
                <label htmlFor='prodcategoryname'>Role Name</label>
              </div>
              {formik.touched.name && formik.errors.name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.name}</span>
                  </div>
                </div>
              )}
            </div>
            <h4>Role Permissions</h4>
            {permissions.length > 0 &&
              permissions.map((item) => (
                <div className='col-md-4 mb-3 text-wrap'>
                  <div className='form-check form-switch'>
                    <input
                      {...formik.getFieldProps('permissions')}
                      className={clsx(
                        'form-check-input',
                        {'is-invalid': formik.touched.permissions && formik.errors.permissions},
                        {
                          'is-valid': formik.touched.permissions && !formik.errors.permissions,
                        }
                      )}
                      type='checkbox'
                      role='switch'
                      id='permissions'
                      name='permissions'
                      value={item.id}
                    />
                    <label className='form-check-label text-capitalize' htmlFor='permissions'>
                      {item.name.replace('-', ' ').replace('-', ' ')}
                    </label>
                    <br />
                    <small>{item.description}</small>
                  </div>
                </div>
              ))}
          </div>
          <div className='fv-row mb-10 mt-5 row justify-content-start'>
            <button
              type='submit'
              id='editprodcatbtn'
              className='btn afri-btn-bg-primary w-50'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && <span className='indicator-label'>Continue</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </Modal>,
    modalsRoot
  ) as ReactPortal
}
export {CreateRoleModal, CreateUserModal, CreateProductCategory, CreateRaffleCategory}
