import clsx from 'clsx'
import {useEffect, useState} from 'react'
import {axiosDelete, axiosGet, axiosPut} from '../../../lib/helpers'
import {ProductAvailability, ProductStatus} from '../../../lib/models/Product'
import {useFormik} from 'formik'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {Loader} from '../../../lib/widgets/Loader'
import {FormStatus} from '../../../lib/models/Form'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

const CustomerEdit = () => {
  const {id} = useParams()
  const [data, setData] = useState<any>([])
  const [isDelete, setIsDelete] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const handleChange = (e: any) => {
    setData({...data, [e.target.name]: e.target.value})
  }
  const [roles, setRoles] = useState<any[]>([])

  useEffect(() => {
    ;(async () => {
      const user = await axiosGet({path: `user/list/${id}`})
      if (user.isSuccess) {
        setData(user.profile)
        setTimeout(() => {
          setIsLoading(false)
        }, 500)
      }
      const result = await axiosGet({path: `user/role/list`})
      console.log(result)
      if (result.isSuccess) {
        setRoles(result.roles)
      }
    })()
    if (isDelete) {
      location(`/customers/list`)
    }
  }, [])
  console.log(data)

  const initialValues = {
    firstname: data.firstname,
    lastname: data.lastname,
    phone: data.phone,
    country: data.country,
    picture: data.picture,
    status: data.status,
    role: data.role,
  }
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        await axiosPut({
          path: `user/list/${id}`,
          data: {
            firstname: values.firstname,
            lastname: values.lastname,
            phone: values.phone,
            country: values.country,
            status: values.status,
            role: values.role,
          },
        })

        setLoading(false)
        setSubmitting(false)
      } catch (error) {
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  const location = useNavigate()
  const count = data ? 1 : 0
  const handleDelete = async () => {
    await axiosDelete({path: `user/list/${id}`})
  }
  return (
    <>
      <form onSubmit={formik.handleSubmit} noValidate id='product_create_form' autoComplete='off'>
        {!isLoading && data ? (
          <div className='row'>
            <div className='d-flex justify-content-end'>
              <div className='col-md-3 me-2'>
                <div className='form-floating'>
                  <select
                    id='productraffle'
                    aria-label='status'
                    {...formik.getFieldProps('status')}
                    className={clsx('form-select')}
                    name='status'
                    onChange={handleChange}
                    value={data.status}
                  >
                    <option selected>Choose Status</option>
                    {FormStatus.length > 0 &&
                      FormStatus.map((item) => (
                        <option value={item.statusCode}>{item.name}</option>
                      ))}
                  </select>
                  <label htmlFor='productraffle'>User Status</label>
                </div>
              </div>
              <button
                type='submit'
                disabled={formik.isSubmitting}
                className='btn me-2 afri-btn-bg-primary'
                data-status={1}
              >
                {!loading && <span className='indicator-label'>Save Changes</span>}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>

            <div className='card shadow-none'>
              <div className='card-header d-flex flex-column'>
                <div className='card-title fw-bold fs-1 mb-3'>
                  Edit Customer{' '}
                  <div className='symbol symbol-circle symbol-50px overflow-hidden me-3 ms-3'>
                    <div className='symbol-label'>
                      <img
                        src={
                          data?.picture?.length > 0
                            ? data?.picture[0]?.location
                            : toAbsoluteUrl(`/media/avatars/blank.png`)
                        }
                        className='w-100'
                      />
                    </div>
                  </div>{' '}
                  {data.firstname} {data.lastname}
                </div>
                <div className='card-head-extra d-flex flex-row justify-content-between align-items-center'>
                  <h4 className='fs-4 fw-normal'>General information</h4>
                  <i className='bi bi-plus fs-2'></i>
                </div>
              </div>
              <div className='card-body'>
                <div className='row mt-5'>
                  <h5 className='mb-5 border-bottom'>General</h5>
                  <div className='col-md-10 mb-10 '>
                    <div className='form-floating mb-3'>
                      <input
                        type='text'
                        id='firstname'
                        placeholder='Enter name'
                        {...formik.getFieldProps('firstname')}
                        className={clsx('form-control')}
                        style={{
                          height: '50px',
                        }}
                        name='firstname'
                        onChange={handleChange}
                        value={data.firstname}
                      />
                      <label htmlFor='firstname'>First name</label>
                    </div>
                  </div>
                  <div className='col-md-10 mb-10 '>
                    <div className='form-floating mb-3'>
                      <input
                        type='text'
                        id='lastname'
                        placeholder='Enter last name'
                        {...formik.getFieldProps('lastname')}
                        className={clsx('form-control')}
                        style={{
                          height: '50px',
                        }}
                        name='lastname'
                        onChange={handleChange}
                        value={data.lastname}
                      />
                      <label htmlFor='firstname'>Last name</label>
                    </div>
                  </div>

                  {data?.role?.type === 1 && (
                    <div className='fv-row mb-10 row'>
                      <div className='col-md-10'>
                        <div className='form-floating'>
                          <select
                            id='role'
                            aria-label='Category Status'
                            {...formik.getFieldProps('role')}
                            className={clsx('form-select border-1')}
                            name='role'
                          >
                            <option selected>Choose Role</option>
                            {roles.length > 0 &&
                              roles.map((item) => (
                                <option selected={data?.role?.id === item.id} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                          </select>
                          <label htmlFor='role'>User Role</label>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className='col-md-5 mb-10 '>
                    <div className='form-floating mb-3'>
                      <input
                        type='tel'
                        id='phone'
                        placeholder='Enter '
                        {...formik.getFieldProps('phone')}
                        className={clsx('form-control')}
                        style={{
                          height: '50px',
                        }}
                        name='stock'
                        onChange={handleChange}
                        value={data.phone}
                      />
                      <label htmlFor='phone'>Phone</label>
                    </div>
                  </div>
                  <div className='col-md-5 mb-10 '>
                    <div className='form-floating mb-3'>
                      <input
                        type='tel'
                        id='prodprice'
                        placeholder='Enter Country'
                        {...formik.getFieldProps('country')}
                        className={clsx('form-control')}
                        style={{
                          height: '50px',
                        }}
                        name='country'
                        onChange={handleChange}
                        value={data.country}
                      />
                      <label htmlFor='country'>Country</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className='d-flex justify-content-center'>
            <Loader isError={count} errorMessage='error occurred' />
          </div>
        )}
      </form>
      <div className='row'>
        <div className='col-md-12 mb-lg-5 mb-4'>
          <div className='card card-bordered  border-1 shadow-none'>
            <div className='card-header d-flex flex-row justify-content-between align-items-center border-0'>
              <div className='card-title fw-bold fs-1 d-flex flex-column'>Delete Customer</div>
            </div>
            <div className='card-body'>
              <div className='row'>
                <p>Deleting customer {data.email} will erase them from the system. Are you sure?</p>
                <div className='col-md-6'>
                  <button onClick={handleDelete} className='btn btn-outline btn-outline-danger'>
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {CustomerEdit}
