import {NumericFormat} from 'react-number-format'
import {FC, Fragment} from 'react'
import {Link} from 'react-router-dom'
import {OrderStatus, TrackingStatus} from '../models/Orders'
import {ProductStatus} from '../models/Product'
import {FormStatus} from '../models/Form'
import {toAbsoluteUrl} from '../../../_metronic/helpers/AssetHelpers'
import {TransactionType} from '../models/Payments'
type dateProps = {
  date: any
}
const DateFormatter = ({date}: dateProps) => {
  const options: any = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}
  const DateLocal = new Date(date).toLocaleDateString('en-US', options)
  return <>{DateLocal}</>
}
const PermissionsFormatter = ({permissions}: any) => {
  return (
    <>
      <div className='h-100px overflow-y-scroll'>
        {permissions.length > 0 &&
          permissions.map((item: any) => (
            <p className='text-wrap text-success text-capitalize me-2 text-lg-start'>
              {' '}
              {item?.permission?.name.replace('-', ' ').replace('-', ' ')}{' '}
            </p>
          ))}
      </div>
    </>
  )
}

type phoneProps = {
  phone: any
}

type amountProps = {
  amount: number
}
const CurrencyColumnFormater: FC<amountProps> = ({amount}) => {
  return (
    <>
      <NumericFormat
        prefix={'₦'}
        value={amount}
        allowLeadingZeros
        thousandSeparator=','
        displayType='text'
        decimalScale={3}
      />
    </>
  )
}

type userProps = {
  user: any
}

const UserInfoCell: FC<userProps> = ({user}) => (
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
    <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
      <a href={`/customers/${user?.id}`}>
        <div className='symbol-label'>
          <img
            src={
              user?.picture?.length > 0
                ? user?.picture[0]?.location
                : toAbsoluteUrl(`/media/avatars/blank.png`)
            }
            alt={user?.firstname}
            className='w-100'
          />
        </div>
      </a>
    </div>
    <div className='d-flex flex-column'>
      <Link to={`/customers/${user?.id}`} className='text-gray-800 text-hover-primary mb-1'>
        {user?.firstname} {user?.lastname}
      </Link>
    </div>
  </div>
)

type productProps = {
  product: any
}
const ProductInfoCell: FC<productProps> = ({product}) => (
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
    <div className='symbol  symbol-50px overflow-hidden me-3'>
      <Link to={`/products/${product.id}`}>
        <div className='symbol-label'>
          {product.pictures.length > 0 ? (
            product.pictures.map(
              (item: any) =>
                item.front === true && (
                  <img src={item.location} alt={product.product_name} className='w-100' />
                )
            )
          ) : (
            <img
              src={toAbsoluteUrl(`/media/product/notavailable.png`)}
              alt={product.product_name}
              className='w-100'
            />
          )}
        </div>
      </Link>
    </div>

    <div className='d-flex flex-column'>
      <Link to={`/products/${product.id}`}>{product.product_name}</Link>
    </div>
  </div>
)

type orderStatusProps = {
  status: number
}

const OrderStatusFormatter: FC<orderStatusProps> = ({status}) => {
  return (
    <>
      {OrderStatus?.length > 0 &&
        OrderStatus.map((item) => item.statusCode === status && item.name)}
    </>
  )
}
const TrackingStatusFormatter: FC<orderStatusProps> = ({status}) => {
  return (
    <>
      {TrackingStatus?.length > 0 &&
        TrackingStatus.map((item) => item.statusCode === status && item.name)}
    </>
  )
}
const TrackingTimelineFormatter: FC<orderStatusProps> = ({status}) => {
  return (
    <>
      {status === 1 ? (
        <div className='min-w-125px pe-2'>
          <span className='badge badge-light-warning'>Pending</span>
        </div>
      ) : status === 2 ? (
        <div className='min-w-125px pe-2'>
          <span className='badge badge-light-warning'>Processing</span>
        </div>
      ) : status === 3 ? (
        <div className='min-w-125px pe-2'>
          <span className='badge badge-light-primary'>Approved</span>
        </div>
      ) : status === 4 ? (
        <div className='min-w-125px pe-2'>
          <span className='badge badge-light-primary'>Shipped</span>
        </div>
      ) : status === 5 ? (
        <div className='min-w-125px pe-2'>
          <span className='badge badge-light-primary'>Delivered</span>
        </div>
      ) : status === 6 ? (
        <div className='min-w-125px pe-2'>
          <span className='badge badge-light-primary'>Received</span>
        </div>
      ) : (
        status === 7 && (
          <div className='min-w-125px pe-2'>
            <span className='badge badge-light-warning'>In transit</span>
          </div>
        )
      )}
    </>
  )
}
const ProductStatusFormatter: FC<orderStatusProps> = ({status}) => {
  return (
    <>
      {ProductStatus.length > 0 &&
        ProductStatus.map((item) => item.statusCode === status && item.name)}
    </>
  )
}

type paymentProps = {
  transaction: any
  className?: string
}
const PaymentStatusFormatter: FC<paymentProps> = ({transaction, className}) => {
  return (
    <>
      {transaction?.length > 0 ? (
        transaction.map((item: any) =>
          item.status === 1 ? (
            <div className={`d-flex align-items-center ${className}`}>
              {' '}
              <i
                className='bi bi-dot text-success me-0'
                style={{
                  fontSize: '32px',
                }}
              ></i>
              Paid
            </div>
          ) : item.status === 2 ? (
            <div className={`d-flex align-items-center ${className}`}>
              {' '}
              <i
                className='bi bi-dot text-warning'
                style={{
                  fontSize: '36px',
                }}
              ></i>
              Pending{' '}
            </div>
          ) : (
            item.status === 0 && (
              <div
                className={`d-flex align-items-center ${className}`}
                style={{
                  fontSize: '36px',
                }}
              >
                {' '}
                <i className='bi bi-dot text-danger'></i>Failed{' '}
              </div>
            )
          )
        )
      ) : (
        <div
          className={`d-flex align-items-center me-2`}
          style={{
            fontSize: '12px',
          }}
        >
          {' '}
          <i
            className='bi bi-dot text-danger fa-2x'
            style={{
              fontSize: '12px',
            }}
          >
            {' '}
          </i>
          Pending{' '}
        </div>
      )}
    </>
  )
}
type transactionProps = {
  status: number
}
const TransactionStatusFormatter: FC<transactionProps> = ({status}) => {
  return (
    <>
      {status === 4 ? (
        <div className={`d-flex align-items-center`}>
          {' '}
          <i
            className='bi bi-dot text-danger me-0'
            style={{
              fontSize: '32px',
            }}
          ></i>
          Rejected
        </div>
      ) : status === 3 ? (
        <div className={`d-flex align-items-center`}>
          {' '}
          <i
            className='bi bi-dot text-success me-0'
            style={{
              fontSize: '32px',
            }}
          ></i>
          Approved
        </div>
      ) : status === 1 ? (
        <div className={`d-flex align-items-center`}>
          {' '}
          <i
            className='bi bi-dot text-success me-0'
            style={{
              fontSize: '32px',
            }}
          ></i>
          Paid
        </div>
      ) : status === 2 ? (
        <div className={`d-flex align-items-center`}>
          {' '}
          <i
            className='bi bi-dot text-warning'
            style={{
              fontSize: '36px',
            }}
          ></i>
          Pending{' '}
        </div>
      ) : (
        status === 0 && (
          <div
            className={`d-flex align-items-center`}
            style={{
              fontSize: '36px',
            }}
          >
            {' '}
            <i className='bi bi-dot text-danger'></i>Failed{' '}
          </div>
        )
      )}
    </>
  )
}
const WithdrawStatusFormatter: FC<transactionProps> = ({status}) => {
  console.log(status)

  return (
    <>
      {status === 3 ? (
        <div className={`d-flex align-items-center`}>
          {' '}
          <i
            className='bi bi-dot text-success me-0'
            style={{
              fontSize: '32px',
            }}
          ></i>
          Approved
        </div>
      ) : status === 1 ? (
        <div className={`d-flex align-items-center`}>
          {' '}
          <i
            className='bi bi-dot text-success me-0'
            style={{
              fontSize: '32px',
            }}
          ></i>
          Sent
        </div>
      ) : status === 2 ? (
        <div className={`d-flex align-items-center`}>
          {' '}
          <i
            className='bi bi-dot text-warning'
            style={{
              fontSize: '36px',
            }}
          ></i>
          Pending{' '}
        </div>
      ) : (
        status === 4 && (
          <div className={`d-flex align-items-center`}>
            {' '}
            <i
              className='bi bi-dot text-danger'
              style={{
                fontSize: '36px',
              }}
            ></i>
            Rejected{' '}
          </div>
        )
      )}
    </>
  )
}

type transactionTypeProps = {
  type: number
}
const TransactionTypeFormatter: FC<transactionTypeProps> = ({type}) => {
  return <>{TransactionType.map((item: any) => item.statusCode === type && item.name)}</>
}
type paymentStatusProps = {
  transaction: any[]
}
const OrderAmount: FC<paymentStatusProps> = ({transaction}) => {
  return (
    <>
      {transaction?.length &&
        transaction.map((item) => <CurrencyColumnFormater amount={item.amount} />)}
    </>
  )
}

const RatingFormater = ({rating}: any) => {
  return (
    <>
      <div className='d-flex flex-row'>
        <span className='fa fa-star checked'></span>
        <span className='fa fa-star checked'></span>
        <span className='fa fa-star checked'></span>
        <span className='fa fa-star'></span>
        <span className='fa fa-star'></span>
      </div>
    </>
  )
}

const RaffleTicketFormatter = ({raffle}: any) =>
  raffle.length > 0 && raffle.map((item: any) => item.ticket_name)

const FormStatusFormatter = ({status}: any) => {
  return (
    <>
      {FormStatus.length > 0 &&
        FormStatus.map((item: any) => item.statusCode === status && item.name)}
    </>
  )
}

export {
  TrackingStatusFormatter,
  TrackingTimelineFormatter,
  PermissionsFormatter,
  WithdrawStatusFormatter,
  FormStatusFormatter,
  DateFormatter,
  ProductInfoCell,
  CurrencyColumnFormater,
  UserInfoCell,
  OrderStatusFormatter,
  OrderAmount,
  PaymentStatusFormatter,
  TransactionStatusFormatter,
  TransactionTypeFormatter,
  ProductStatusFormatter,
  RaffleTicketFormatter,
  RatingFormater,
}
