import axios from 'axios'
import {LoaderTimout} from '../widgets/Loader'
import {useFormik} from 'formik'
import {MySwal, notifyFailure, notifySuccess} from './NotificationHelpers'

type axiosGetProps = {
  path: string
  title?: string
  text?: string
}
export const axiosGet = async ({path}: axiosGetProps) => {
  try {
    const {data: result} = await axios.get(`${path}`)

    if (!(result || !(result.status !== 200 && !result?.isSuccess))) {
      notifyFailure({
        type: `toastr`,
        toastrProps: {
          text: result.message,
          type: `error`,
        },
      })
    }
    // else {
    //   notifySuccess({
    //     type: `toastr`, toastrProps: {
    //       text: result.message,
    //       type: `success`
    //     }
    //   })
    // }
    return result
  } catch (error) {
    notifyFailure({
      type: `toastr`,
      toastrProps: {
        text: error,
        type: `error`,
      },
    })
  }
}
export const axiosDelete = async ({
  path,
  title = 'Are you sure?',
  text = "You won't be able to revert this!",
}: axiosGetProps) => {
  try {
    const request = await MySwal.fire({
      title: title,
      text: text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: `#010038`,
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete',
    })

    if (request.isConfirmed) {
      const {data: delReq} = await axios.delete(`${path}`)

      if (!delReq.isSuccess && delReq.status !== 200) {
        MySwal.fire('Error Occured!', delReq.message, 'error')
      } else {
        MySwal.fire('Deleted!', delReq.message, 'success')
      }
      return delReq
    }
    return false
  } catch (error) {
    notifyFailure({
      type: `toastr`,
      toastrProps: {
        text: error,
        type: `error`,
      },
    })
  }
}
export const axiosSpecial = async ({
  path,
  title = 'Are you sure?',
  text = "You won't be able to revert this!",
  data,
  type,
}: any) => {
  try {
    const request = await MySwal.fire({
      title: title,
      text: text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: `#010038`,
      cancelButtonColor: '#d33',
      confirmButtonText: `Yes, ${type}`,
    })

    if (request.isConfirmed) {
      const {data: delReq} = await axios.post(`${path}`, data)

      if (!delReq.isSuccess && delReq.status !== 200) {
        MySwal.fire('Error Occured!', delReq.message, 'error')
      } else {
        MySwal.fire(`${type}d`, delReq.message, 'success')
      }
      return delReq
    }
    return false
  } catch (error) {
    notifyFailure({
      type: `toastr`,
      toastrProps: {
        text: error,
        type: `error`,
      },
    })
  }
}

type axiosPostProps = {
  data: any
  isLoading?: any
  path: string
}
export const axiosPost = async ({path, data}: axiosPostProps) => {
  try {
    const {data: result} = await axios.post(`${path}`, data)

    if (!result.isSuccess && result.statusCode !== 200) {
      notifyFailure({
        type: `toastr`,
        toastrProps: {
          text: result.message,
          type: `error`,
        },
      })
    } else {
      notifySuccess({
        type: `toastr`,
        toastrProps: {
          text: result.message,
          type: `success`,
        },
      })
    }
    return result
  } catch (error) {
    console.log(error)
    notifyFailure({
      type: `toastr`,
      toastrProps: {
        text: error,
        type: `error`,
      },
    })
  }
}
export const axiosPut = async ({path, data}: axiosPostProps) => {
  try {
    const {data: result} = await axios.put(`${path}`, data)

    if (!result.isSuccess && result.status !== 200) {
      notifyFailure({
        type: `toastr`,
        toastrProps: {
          text: result.message,
          type: `error`,
        },
      })
    } else {
      notifySuccess({
        type: `toastr`,
        toastrProps: {
          text: result.message,
          type: `success`,
        },
      })
    }
    return result
  } catch (error) {
    console.log(error)
  }
}

export function getUserByToken(token: string) {
  return axios.get(`user/profile`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}
