import DataTable, {createTheme} from 'react-data-table-component'
import {Loader} from '../widgets/Loader'
import {KTCardBody} from '../../../_metronic/helpers'
import React, {useEffect, useState} from 'react'
import {Navigate, useLocation, useNavigate} from 'react-router-dom'

type tableProps = {
  data: any
  columns: any
  noclick?: boolean
}
const Table = ({data, columns, noclick = true}: tableProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [pending, setPending] = useState(true)
  const [column, setColumn] = useState<any[]>([])
  const [isData, setIsData] = useState(data)
  useEffect(() => {
    setIsData(data)
    const timeout = setTimeout(() => {
      setColumn(columns)
      setPending(false)
    }, 2000)
    return () => clearTimeout(timeout)
  }, [data])

  const customStyles = {
    rows: {
      style: {
        minHeight: '72px', // override the row height
        overflow: 'visible',
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '8px',
        fontSize: '1.2em',
      },
    },
    cells: {
      style: {
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '8px',
        textTransform: '',
      },
    },
  }

  createTheme(
    'solarized',
    {
      text: {
        primary: '#111827',
        secondary: '#6B7280',
        fontFamily: 'Poppins, sans-serif',
        fontSize: '12px',
        fontWeight: '400',
      },
      background: {
        default: 'transparent',
      },
      context: {
        background: '#BD9241',
        text: '#FFFFFF',
      },
      divider: {
        default: '#E5E7EB',
      },
      action: {
        button: 'rgba(0,0,0,.54)',
        hover: 'rgba(0,0,0,.08)',
        disabled: 'rgba(0,0,0,.12)',
      },
    },
    'light'
  )
  const handleClick = (e: any) => {
    console.log(e)
    const currentLocation = location.pathname.split(`/`)
    navigate(`/${currentLocation[1]}/${e.id}`)
  }
  return (
    <>
      <div className='table-responsive btn-outline btn w-100'>
        <DataTable
          columns={column}
          data={isData}
          // selectableRows
          customStyles={customStyles}
          theme='solarized'
          pagination
          onRowClicked={(e) => {
            if (noclick) handleClick(e)
          }}
          // onSelectedRowsChange={handleClick}
          progressPending={pending}
          highlightOnHover
          pointerOnHover
          progressComponent={<Loader />}
        />
      </div>
    </>
  )
}

export {Table}
