import clsx from 'clsx'
import {useEffect, useState} from 'react'
import {axiosGet, axiosPost, sweetAlert} from '../../../lib/helpers'
import {ProductAvailability} from '../../../lib/models/Product'
import {Media} from '../../media/Media'
import {useFormik} from 'formik'
import * as Yup from 'yup'

const RaffleCreate = () => {
  const [cat, setCat] = useState<any[]>([])
  const [showMedia, setShowMedia] = useState(false)
  const [picture, setPicture] = useState(() => {
    const savedPics = localStorage.getItem('pictures')
    if (savedPics) {
      return JSON.parse(savedPics)
    } else {
      return []
    }
  })
  const [loading, setLoading] = useState(false)
  const [checked, setChecked] = useState(false)
  const [descCount, setDescCount] = useState(0)
  const fetch = async () => {
    const category = await axiosGet({path: 'raffle/category/list'})
    console.log(category)
    if (category.isSuccess) {
      const cart = []

      for (const x in category.rafflecategories) {
        // if (category.rafflecategories[x].status === 1){
        cart.push(category.rafflecategories[x])
        // }
      }
      setCat(cart)
    }
  }

  useEffect(() => {
    fetch()
    localStorage.setItem('pictures', JSON.stringify(picture))
  }, [picture])

  const handleDeleteClick = (_id: any) => {
    const removeItem = picture.filter((picture: any) => {
      return picture.id !== _id
    })
    setPicture(removeItem)
  }
  const validationSchema = Yup.object().shape({
    ticket_name: Yup.string().required('Ticket name is required'),
    ticket_description: Yup.string().required('Ticket Description is required'),
    category: Yup.string().required('Category is required'),
    start_date: Yup.date().required(`Start Date is required`),
    end_date: Yup.date().required(`End Date is required`),
    featured: Yup.boolean().required(`Featured is required`),
  })

  const initialValues = {
    ticket_name: '',
    ticket_description: '',
    start_date: '',
    end_date: '',
    category: ``,
    featured: ``,
  }
  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        picture.map((item: any) => {
          delete item.thumbnailUrl
        })

        if (picture.length > 0) {
          const result = await axiosPost({
            path: `raffle/create`,
            data: {
              ticket_name: values.ticket_name,
              ticket_description: values.ticket_description,
              start_date: values.start_date,
              end_date: values.end_date,
              category: values.category,
              featured: values.featured,
              pictures: picture,
            },
          })
          if (result.isSuccess) {
            localStorage.removeItem(`pictures`)
          }
        } else {
          sweetAlert({title: `Error Occured`, text: `Please add picture`, icon: `error`})
        }

        setLoading(false)
        setSubmitting(false)
      } catch (error) {
        setStatus()
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  const handleFrontChange = (_id: any) => {
    setChecked(_id)
    picture.map((item: any) => {
      if (item.id === _id) {
        const front = Object.assign(item, {front: true})
        localStorage.setItem(`pictures`, JSON.stringify([...picture, {...front}]))
      } else {
        const front = Object.assign(item, {front: false})
        localStorage.setItem(`pictures`, JSON.stringify([...picture, {...front}]))
      }
    })
  }
  const selectCallback = (item: any) => {
    setPicture([...picture, {id: item[0].id, thumbnailUrl: item[0].location}])
    setShowMedia(false)
  }

  return (
    <>
      {showMedia && (
        <Media
          show={showMedia}
          hide={() => setShowMedia(false)}
          selectCallback={(e: any) => selectCallback(e)}
        />
      )}
      {/*<button className='btn me-2 btn-outline' disabled={formik.isSubmitting || !formik.isValid} data-status={3}>*/}
      {/*  {!loading && <span className='indicator-label'>Save as draft</span>}*/}
      {/*  {loading && (*/}
      {/*    <span className='indicator-progress' style={{display: 'block'}}>*/}
      {/*        Please wait...*/}
      {/*        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>*/}
      {/*      </span>*/}
      {/*  )}</button>*/}
      <form onSubmit={formik.handleSubmit} noValidate id='product_create_form' autoComplete='off'>
        <div className='row'>
          <div className='d-flex justify-content-end'>
            <button
              disabled={formik.isSubmitting || !formik.isValid}
              className='btn me-2 afri-btn-bg-primary'
              data-status={1}
            >
              {!loading && <span className='indicator-label'>Publish</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>

          <div className='card shadow-none'>
            <div className='card-header d-flex flex-column'>
              <div className='card-title fw-bold fs-1 mb-3'>Create Raffle draw</div>
              <div className='card-head-extra d-flex flex-row justify-content-between align-items-center'>
                <h4 className='fs-4 fw-normal'>General information</h4>
                <i className='bi bi-plus fs-2'></i>
              </div>
            </div>
            <div className='card-body'>
              <div className='row mt-5'>
                <h5 className='mb-5 border-bottom'>General</h5>
                <div className='col-md-10 mb-10 '>
                  <div className='form-floating mb-3'>
                    <input
                      type='text'
                      id='tickname'
                      placeholder='Enter name'
                      {...formik.getFieldProps('ticket_name')}
                      className={clsx(
                        'form-control',
                        {'is-invalid': formik.touched.ticket_name && formik.errors.ticket_name},
                        {
                          'is-valid': formik.touched.ticket_name && !formik.errors.ticket_name,
                        }
                      )}
                      style={{
                        height: '50px',
                      }}
                      name='ticket_name'
                    />
                    <label htmlFor='tickname'>Ticket Name</label>
                  </div>
                  {formik.touched.ticket_name && formik.errors.ticket_name && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.ticket_name}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='col-md-10 col-lg-10 mb-10'>
                  <div className='form-floating'>
                    <textarea
                      placeholder='Enter description'
                      id='tickdesc'
                      maxLength={300}
                      style={{height: '100px'}}
                      {...formik.getFieldProps('ticket_description')}
                      className={clsx(
                        'form-control',
                        {
                          'is-invalid':
                            formik.touched.ticket_description && formik.errors.ticket_description,
                        },
                        {
                          'is-valid':
                            formik.touched.ticket_description && !formik.errors.ticket_description,
                        }
                      )}
                      name='ticket_description'
                      onKeyUp={(e) => setDescCount(formik.values.ticket_description.length)}
                    ></textarea>
                    <label htmlFor='tickdesc'>Description</label>
                  </div>
                  {formik.touched.ticket_description && formik.errors.ticket_description && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.ticket_description}</span>
                      </div>
                    </div>
                  )}
                  <span>{descCount}/300</span>
                </div>
                <div className='col-md-10 mb-10 mt-5'>
                  <div className='form-check form-switch'>
                    <input
                      {...formik.getFieldProps('featured')}
                      className={clsx(
                        'form-check-input',
                        {'is-invalid': formik.touched.featured && formik.errors.featured},
                        {
                          'is-valid': formik.touched.featured && !formik.errors.featured,
                        }
                      )}
                      type='checkbox'
                      role='switch'
                      id='setfeatured'
                    />
                    <label className='form-check-label' htmlFor='setfeatured'>
                      Set as featured
                    </label>
                  </div>
                  {formik.touched.featured && formik.errors.featured && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.featured}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='col-md-4 mb-10 '>
                  <div className='form-floating mb-3'>
                    <select
                      style={{
                        height: '50px',
                      }}
                      id='tickcat'
                      aria-label='Category Status'
                      {...formik.getFieldProps('category')}
                      className={clsx(
                        'form-select',
                        {'is-invalid': formik.touched.category && formik.errors.category},
                        {
                          'is-valid': formik.touched.category && !formik.errors.category,
                        }
                      )}
                      name='category'
                    >
                      <option selected>Choose Category</option>
                      {cat.length > 0 ? (
                        cat.map((item) => <option value={item.id}>{item.name}</option>)
                      ) : (
                        <option>
                          No category found -{' '}
                          <small>Please add one from the category section.</small>
                        </option>
                      )}
                    </select>
                    <label htmlFor='tickcat'>Product Category</label>
                  </div>
                  {formik.touched.category && formik.errors.category && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.category}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='col-md-3 mb-10 '>
                  <div className='form-floating mb-3'>
                    <input
                      type='date'
                      id='tickstart'
                      placeholder='Enter Date'
                      {...formik.getFieldProps('start_date')}
                      className={clsx(
                        'form-control',
                        {'is-invalid': formik.touched.start_date && formik.errors.start_date},
                        {
                          'is-valid': formik.touched.start_date && !formik.errors.start_date,
                        }
                      )}
                      style={{
                        height: '50px',
                      }}
                      name='start_date'
                    />
                    <label htmlFor='tickstart'>Start Date</label>
                  </div>
                  {formik.touched.start_date && formik.errors.start_date && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.start_date}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='col-md-3 mb-10 '>
                  <div className='form-floating mb-3'>
                    <input
                      type='date'
                      id='tickend'
                      placeholder='Enter end date'
                      {...formik.getFieldProps('end_date')}
                      className={clsx(
                        'form-control',
                        {'is-invalid': formik.touched.end_date && formik.errors.end_date},
                        {
                          'is-valid': formik.touched.end_date && !formik.errors.end_date,
                        }
                      )}
                      style={{
                        height: '50px',
                      }}
                      name='end_date'
                    />
                    <label htmlFor='tickend'>End Date</label>
                  </div>
                  {formik.touched.end_date && formik.errors.end_date && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.end_date}</span>
                      </div>
                    </div>
                  )}
                </div>
                <h5>Variant</h5>
                <small className='mb-5 border-bottom'>
                  Add variant for this product, offer your customer different image options
                </small>

                <div className='col-md-10 mb-10 '>
                  <div className='row'>
                    {picture.length > 0 &&
                      picture.map((item: any) => (
                        <div className='col-md-3 d-flex flex-column'>
                          <button
                            className=' border-0 afri-btn-bg-primary  fs-2 '
                            onClick={() => handleDeleteClick(item.id)}
                          >
                            x
                          </button>
                          <img
                            className='w-100 mb-5'
                            style={{
                              minHeight: '100px',
                              minWidth: '100px',
                            }}
                            src={item.thumbnailUrl}
                            alt={item.title}
                          />

                          <div className='form-check text-center'>
                            <input
                              className='form-check-input'
                              type='radio'
                              value='1'
                              id={item.id}
                              checked={item.id === checked}
                              onChange={() => handleFrontChange(item.id)}
                            />
                            <label className='form-check-label' htmlFor={item.id}>
                              Set as front
                            </label>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                <div className='col-md-4 mb-10 '>
                  <button
                    onClick={() => setShowMedia(true)}
                    type='button'
                    className='btn btn-outline w-100'
                  >
                    {' '}
                    <i className='bi bi-plus fs-2'></i> Add Media
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export {RaffleCreate}
