import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {RaffleCreate, RaffleCategoryList, RaffleList, RaffleHead} from './'
import {RaffleResultList} from './components/result/RaffleResultList'
import {RaffleDetails} from './components/RaffleDetails'
import {RaffleEdit} from './components/RaffleEdit'
import {RaffleParticipant} from './components/RaffleParticipant'

const RafflePage = () => (
  <Routes>
    <Route
      element={
        <>
          <RaffleHead />
          <Outlet />
        </>
      }
    >
      <Route
        path=':id'
        element={
          <>
            {/*<PageTitle breadcrumbs={[]}></PageTitle>*/}
            <RaffleDetails />
          </>
        }
      />{' '}
      <Route
        path='list/:id'
        element={
          <>
            {/*<PageTitle breadcrumbs={[]}></PageTitle>*/}
            <RaffleEdit />
          </>
        }
      />
      <Route
        path='list'
        element={
          <>
            {/*<PageTitle breadcrumbs={[]}></PageTitle>*/}
            <RaffleList />
          </>
        }
      />
      <Route
        path='category/list'
        element={
          <>
            {/*<PageTitle breadcrumbs={[]}></PageTitle>*/}
            <RaffleCategoryList />
          </>
        }
      />{' '}
      <Route
        path='create'
        element={
          <>
            {/*<PageTitle breadcrumbs={[]}></PageTitle>*/}
            <RaffleCreate />
          </>
        }
      />
      <Route
        path='result'
        element={
          <>
            {/*<PageTitle breadcrumbs={[]}></PageTitle>*/}
            <RaffleResultList />
          </>
        }
      />
      <Route
        path='participants'
        element={
          <>
            {/*<PageTitle breadcrumbs={[]}></PageTitle>*/}
            <RaffleParticipant />
          </>
        }
      />
      <Route index element={<Navigate to='/raffle/list' />} />
    </Route>
  </Routes>
)
export {RafflePage}
