import {PageTitle} from '../../../../_metronic/layout/core'
import {Link, useParams} from 'react-router-dom'
import {useEffect, useState} from 'react'
import {
  axiosGet,
  axiosPut,
  CurrencyColumnFormater,
  DateFormatter,
  PaymentStatusFormatter,
  ProductInfoCell,
  TransactionStatusFormatter,
  UserInfoCell,
} from '../../../lib/helpers'
import {Loader, LoaderTimout} from '../../../lib/widgets/Loader'
import {EditOrderModal} from '../../../lib/modals/EditActionModal'

const NotificationsDetails = () => {
  const {id} = useParams()
  const [data, setData] = useState<any>({})
  const [loading, setLoading] = useState(true)
  const [read, setRead] = useState(true)

  useEffect(() => {
    ;(async () => {
      const result = await axiosGet({path: `event/system/list/${id}`})
      if (result.isSuccess) {
        setTimeout(() => {
          setLoading(false)
        }, LoaderTimout)
        setData(result.event)
      }
    })()
  }, [id])

  console.log(data)

  const count = data ? 1 : 0
  const handleRead = async () => {
    setRead(false)
    const result = await axiosPut({path: `event/list/${id}`, data: {}})

    if (result.isSuccess) {
    }
    setRead(true)
  }
  return (
    <>
      <div className='row mb-4'>
        <div className='col-md-4'>
          <Link to='/notifications/list' className='text-secondary'>
            <i className='bi bi-arrow-left text-gray'></i> Back to Notifcations
          </Link>
        </div>
      </div>

      {!loading && data ? (
        <div className='row'>
          {/*Order info*/}
          <div className='col-md-12 mb-lg-5 mb-4'>
            <div className='card card-bordered  border-1 shadow-none'>
              <div className='card-header d-flex flex-row justify-content-between align-items-center border-0'>
                <div className='card-title fw-bold fs-1 d-flex flex-column'>
                  Order {data?.event_name}
                  <small className='text-secondary'>
                    <DateFormatter date={data?.created} />
                  </small>
                </div>
                <div className='card-header-action d-flex flex-row justify-content-between align-items-center'>
                  <button className='btn btn-outline' onClick={handleRead} disabled={!read}>
                    Mark as Read
                  </button>
                </div>
              </div>
              <div className='card-body'>
                <div className='row'>
                  <div className='col-md-10'>
                    <p>{data?.event_description}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='d-flex justify-content-center'>
          <Loader isError={count} errorMessage='error occurred' />
        </div>
      )}
    </>
  )
}

export {NotificationsDetails}
