import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {TrackList} from './components/TrackList'
import {TrackingDetails} from './components/TrackingDetails'

const TrackPage = () => (
  <Routes>
    <Route element={<Outlet />}>
      <Route
        path='list'
        element={
          <>
            {/*<PageTitle breadcrumbs={[]}></PageTitle>*/}
            <TrackList />
          </>
        }
      />{' '}
      <Route
        path=':id'
        element={
          <>
            {/*<PageTitle breadcrumbs={[]}></PageTitle>*/}
            <TrackingDetails />
          </>
        }
      />
      <Route index element={<Navigate to='/tracking/list' />} />
    </Route>
  </Routes>
)
export {TrackPage}
