import {PageTitle} from '../../../../_metronic/layout/core'
import {Link, useParams} from 'react-router-dom'
import {useEffect, useState} from 'react'
import {
  axiosGet,
  CurrencyColumnFormater,
  DateFormatter,
  OrderStatusFormatter,
  PaymentStatusFormatter,
  ProductInfoCell,
  ProductStatusFormatter,
  RatingFormater,
  TransactionStatusFormatter,
  UserInfoCell,
} from '../../../lib/helpers'
import {Loader, LoaderTimout} from '../../../lib/widgets/Loader'
import {SingleOrderModel} from '../../../lib/models/Orders'
import {ProductAvailability} from '../../../lib/models/Product'
import {Gallery, Item} from 'react-photoswipe-gallery'
import {Table} from '../../../lib/table/Table'
import {ReviewsColumn} from '../../../lib/table/components/Columns'

const ProductDetails = () => {
  const {id} = useParams()
  const [data, setData] = useState<any>({})
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    ;(async () => {
      const result = await axiosGet({path: `products/list/${id}`})
      if (result.isSuccess) {
        setTimeout(() => {
          setLoading(false)
        }, LoaderTimout)
        setData(result.product)
      }
    })()
  }, [])

  console.log(data)

  type lightBoxProps = {
    ref: any
    open: any
  }
  const count = data ? 1 : 0
  return (
    <>
      <div className='row mb-4'>
        <div className='col-md-4'>
          <Link to='/products/list' className='afri-text-secondary '>
            <i className='bi bi-arrow-left text-gray'></i> Back to Products
          </Link>
        </div>
      </div>

      {!loading && data ? (
        <div className='row'>
          {/*Order info*/}
          <div className='col-md-12 mb-lg-5 mb-4'>
            <div className='card card-bordered  border-1 shadow-none'>
              <div className='card-header d-flex flex-row justify-content-between align-items-center border-0'>
                <div className='card-title fw-bold fs-1 d-flex flex-column text-capitalize'>
                  <div>
                    {' '}
                    {data?.product_name} - {<CurrencyColumnFormater amount={data?.product_price} />}{' '}
                  </div>
                  <small className='afri-text-secondary '>
                    {data?.category?.name} | Created on <DateFormatter date={data?.created} />{' '}
                  </small>
                </div>
                <div className='card-header-action d-flex flex-row justify-content-end align-items-center text-capitalize'>
                  <ProductStatusFormatter status={data?.status} />
                  <Link to={`/products/list/${data?.id}`} className='btn btn-outline ms-2'>
                    Edit
                  </Link>
                </div>
              </div>
              <div className='card-body'>
                <div className='row'>
                  <div className='col-md-10'>
                    <div className='row'>
                      <div className='col-md-3 d-flex flex-column border-end'>
                        <span>Shipping Fee</span>
                        <small className='afri-text-secondary '>
                          {<CurrencyColumnFormater amount={data?.shipping_fee} />}
                        </small>
                      </div>
                      <div className='col-md-3 d-flex flex-column border-end'>
                        <span>Stock</span>
                        <small className='afri-text-secondary '>{data?.stock}</small>
                      </div>
                      <div className='col-md-3 d-flex flex-column border-end'>
                        <span>Featured</span>
                        <small className='afri-text-secondary '>
                          {data?.featured ? `Yes` : `No`}
                        </small>
                      </div>
                      <div className='col-md-3 d-flex flex-column border-end'>
                        <span>Low Stock Alert</span>
                        <small className='afri-text-secondary '>
                          {data?.low_stock_alert ? `Yes` : `No`}
                        </small>
                      </div>
                      <div className='col-md-3 d-flex flex-column mt-4'>
                        <span>Availability</span>
                        {ProductAvailability.length > 0 &&
                          ProductAvailability.map(
                            (item) =>
                              item.statusCode === data?.availability && (
                                <small className='afri-text-secondary '>{item.name}</small>
                              )
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*Summary*/}
          <div className='col-md-12 mb-lg-5 mb-4'>
            <div className='card  border-1 shadow-none'>
              <div className='card-header d-flex flex-row justify-content-between align-items-center border-0'>
                <div className='card-title fw-bold fs-1 d-flex flex-column'>Product Details</div>
                <div className='card-header-action'>
                  <i className='bi bi-three-dots'></i>
                </div>
              </div>
              <div className='card-body'>
                <div className='row d-flex flex-column align-items-start'>
                  {/*First part*/}
                  <div className='col-md-6 mb-4'>
                    <div className='card-title'>Description</div>
                  </div>
                  <div className='col-md-4 afri-text-secondary mb-5'>
                    {data?.product_description}
                  </div>

                  {/*Second part*/}
                  <div className='col-md-6 mb-4'>
                    <div className='card-title'>Variants (Pictures)</div>
                  </div>
                  <div className='col-md-6 mb-5'>
                    <div className='row'>
                      {
                        <Gallery>
                          {data?.pictures?.length > 0 &&
                            data?.pictures.map((item: any) => (
                              <div className='col-md-4 '>
                                <Item
                                  original={item.location}
                                  thumbnail={item.location}
                                  width='640'
                                  height='740'
                                >
                                  {({ref, open}: lightBoxProps) => (
                                    <img
                                      ref={ref}
                                      onClick={open}
                                      src={item.location}
                                      className='h-100px'
                                    />
                                  )}
                                </Item>
                              </div>
                            ))}
                        </Gallery>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-12 mb-lg-5 mb-4'>
            {data?.raffle &&
              data?.raffle.map((item: any) => (
                <div className='card card-bordered  border-1 shadow-none'>
                  <div className='card-header d-flex flex-row justify-content-between align-items-center border-0'>
                    <div className='card-title fw-bold fs-1 d-flex flex-column'>Raffle</div>
                    <div className='card-header-action d-flex flex-row justify-content-between align-items-center'>
                      {item.status === 1 ? `Active` : `Inactive`}
                      <Link
                        to={`/raffle/list/${item.id}`}
                        className='btn btn-sm btn-outline bg-white border-1 ms-2'
                      >
                        Edit Raffle
                      </Link>
                    </div>
                  </div>
                  <div className='card-body'>
                    <div className='row'>
                      <div className='col-md-10'>
                        <div className='row'>
                          <div className='col-md-3 d-flex flex-column '>
                            <span>Name</span>
                            <small className='afri-text-secondary'>{item?.ticket_name}</small>
                          </div>
                          <div className='col-md-3 d-flex flex-column '>
                            <span>Tracking</span>
                            <small className='afri-text-secondary '>{item?.ticket_tracking}</small>
                          </div>
                          <div className='col-md-3 d-flex flex-column '>
                            <span>Status</span>
                            <small className='afri-text-secondary '>
                              {item.status === 1 ? `Active` : `Inactive`}
                            </small>
                          </div>
                          <div className='col-md-3 d-flex flex-column '>
                            <span>Start & End Date</span>
                            <small className='afri-text-secondary '>
                              <DateFormatter date={item?.start_date} /> to{' '}
                              <DateFormatter date={item?.end_date} />
                            </small>
                          </div>
                        </div>
                        <div className='col-md-10 mt-4 mb-10'>
                          <span>Pictures</span>
                          <div className='row'>
                            {
                              <Gallery>
                                {item?.pictures?.length > 0 &&
                                  item?.pictures.map((item: any) => (
                                    <div className='col-md-4 '>
                                      <Item
                                        original={item.location}
                                        thumbnail={item.location}
                                        width='640'
                                        height='740'
                                      >
                                        {({ref, open}: lightBoxProps) => (
                                          <img
                                            ref={ref}
                                            onClick={open}
                                            src={item.location}
                                            className='h-100px'
                                            alt={item.token}
                                          />
                                        )}
                                      </Item>
                                    </div>
                                  ))}
                              </Gallery>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <div className='col-md-12 mb-lg-5 mb-4'>
            <div className='card card-bordered  border-1 shadow-none'>
              <div className='card-header d-flex flex-row justify-content-between align-items-center border-0'>
                <div className='card-title fw-bold fs-1 d-flex flex-column'>Customer Reviews</div>
                <div className='card-header-action d-flex flex-row justify-content-between align-items-center'>
                  <Link
                    to='/reviews/list'
                    className='btn btn-sm btn-outline bg-white border-1 ms-2'
                  >
                    View all
                  </Link>
                </div>
              </div>
              <div className='card-body'>
                <div className='row'>
                  <Table data={data?.reviews} columns={ReviewsColumn} />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='d-flex justify-content-center'>
          <Loader isError={count} errorMessage='error occurred' />
        </div>
      )}
    </>
  )
}

export {ProductDetails}
