import {Table} from '../../../../lib/table/Table'
import {useEffect, useState} from 'react'
import {axiosGet} from '../../../../lib/helpers'
import {RaffleResultColumn} from '../../../../lib/table/components/Columns'

const RaffleResultList = () => {
  const [data, setData] = useState<any[]>([])
  useEffect(() => {
    ;(async () => {
      const result = await axiosGet({path: `raffle/result/list`})

      console.log(result)
      if (result.isSuccess) {
        setData(result.results)
      }
    })()
  }, [])

  return (
    <>
      <Table data={data} columns={RaffleResultColumn} />
    </>
  )
}
export {RaffleResultList}
