import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import {toast} from 'react-toastify'
import {FC} from 'react'
import {axiosDelete} from './RequestHelpers'

export const MySwal = withReactContent(Swal)
type sweetAlertProp = {
  title: any
  icon: any
  text: any
}
export const sweetAlert = ({title, icon, text}: sweetAlertProp) => {
  return MySwal.fire({
    title: `<strong>${title}</strong>`,
    text: text,
    icon: icon,
    iconColor: `#BD9241`,
    confirmButtonText: `Continue`,
    confirmButtonColor: `#010038`,
  })
}

type toastrProps = {
  text: any
  type: any
}
export const toastr = ({text, type}: toastrProps) => {
  return toast(text, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light',
    type: type,
  })
}

type notify = {
  type: string
  sweetAlertProps?: sweetAlertProp
  toastrProps?: toastrProps
}
export const notifySuccess = ({type, sweetAlertProps, toastrProps}: notify) => {
  try {
    switch (type) {
      case `sweetalert`:
        sweetAlert({
          title: sweetAlertProps?.title,
          text: sweetAlertProps?.text,
          icon: sweetAlertProps?.icon,
        })
        break

      case `toastr`:
        toastr({text: toastrProps?.text, type: `success`})
        break

      default:
        toastr({text: toastrProps?.text, type: `success`})
        break
    }
  } catch (error) {}
}
export const notifyFailure = ({type, sweetAlertProps, toastrProps}: notify) => {
  try {
    switch (type) {
      case `sweetalert`:
        sweetAlert({
          title: sweetAlertProps?.title,
          text: sweetAlertProps?.text,
          icon: sweetAlertProps?.icon,
        })
        break

      case `toastr`:
        toastr({text: toastrProps?.text, type: `error`})
        break

      default:
        toastr({text: toastrProps?.text, type: `error`})
        break
    }
  } catch (error) {}
}
export const notificationConfirm = () => {
  try {
  } catch (error) {}
}
export const notificationWarning = () => {
  try {
  } catch (error) {}
}
