import {Link, useParams} from 'react-router-dom'
import {axiosGet, DateFormatter, OrderStatusFormatter} from '../../../lib/helpers'
import {Loader, LoaderTimout} from '../../../lib/widgets/Loader'
import {useEffect, useState} from 'react'
import {EditTrackingModal} from '../../../lib/modals/EditActionModal'
import {Table} from '../../../lib/table/Table'
import {TrackingColumn, TrackingTimelineColumn} from '../../../lib/table/components/Columns'

const TrackingDetails = () => {
  const {id} = useParams()
  const [data, setData] = useState<any>({})
  const [loading, setLoading] = useState(true)
  const [showModal, setShowModal] = useState(false)
  useEffect(() => {
    ;(async () => {
      const result = await axiosGet({path: `orders/timeline/list/${id}`})
      console.log(result)
      if (result.isSuccess) {
        setTimeout(() => {
          setLoading(false)
        }, LoaderTimout)
        setData(result.timeline)
      }
    })()
  }, [id])

  console.log(data)
  const count = data ? 1 : 0
  return (
    <>
      {showModal && (
        <EditTrackingModal res={data} show={showModal} handleClose={() => setShowModal(false)} />
      )}
      <div className='row mb-4'>
        <div className='col-md-4'>
          <Link to='/tracking/list' className='text-secondary'>
            <i className='bi bi-arrow-left text-gray'></i> Back to Tracking
          </Link>
        </div>
      </div>

      {!loading && data ? (
        <div className='row'>
          {/*Order info*/}
          <div className='col-md-12 mb-lg-5 mb-4'>
            <div className='card card-bordered  border-1 shadow-none'>
              <div className='card-header d-flex flex-row justify-content-between align-items-center border-0'>
                <div className='card-title fw-bold fs-1 d-flex flex-column'>
                  Tracking {data?.tracking_number}
                  <small className='text-secondary'>
                    <DateFormatter date={data?.created} />
                  </small>
                </div>
                <div className='card-header-action d-flex flex-row justify-content-between align-items-center'>
                  <button className='btn btn-outline' onClick={() => setShowModal(true)}>
                    Update
                  </button>
                </div>
              </div>
              <div className='card-body'>
                <div className='row'>
                  <div className='col-md-10'>
                    <div className='row'>
                      <div className='col-md-3 d-flex flex-column border-end'>
                        <span>Status</span>
                        <OrderStatusFormatter status={data?.status} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='card-body'>
                <div className='row'>
                  <h4>Timeline</h4>

                  <Table data={data?.timeline} columns={TrackingTimelineColumn} />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='d-flex justify-content-center'>
          <Loader isError={count} errorMessage='error occurred' />
        </div>
      )}
    </>
  )
}

export {TrackingDetails}
