import axios from 'axios'
import {AuthModel} from '../models/_models'

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

export function setupAxios(axios: any) {
  axios.defaults.baseURL = process.env.REACT_APP_AFRI_ENDPOINT
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: any) => {
      const auth = getAuth()
      if (auth && auth.token) {
        const request_token = config.url.includes('refresh_token') ? auth.refresh_token : auth.token
        config.headers.Authorization = `Bearer ${request_token}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )

  axios.interceptors.response.use(
    (res: any) => {
      return res
    },
    async (err: any) => {
      const originalConfig = err.config
      const auth = getAuth()
      let retryCount = 0
      if (originalConfig.url !== '/login' && err.response) {
        if (retryCount >= 3) {
          console.log(retryCount)
          return Promise.reject(err)
        }
        if (auth && auth.token && auth.refresh_token && err.response.status === 401) {
          try {
            const {data} = await axios.post('/auth/refresh_token')
            console.log(data.tokens)
            setAuth({
              ...auth,
              token: data.tokens.access_token,
              refresh_token: data.tokens.refresh_token,
            })
            return new Promise((resolve, reject) => {
              axios(originalConfig)
                .then((response: any) => {
                  retryCount = 0
                  resolve(response)
                })
                .catch((error: any) => {
                  console.log(error)
                  retryCount++
                  reject(error)
                })
            })
          } catch (error) {
            console.log(error)
            retryCount++
            return Promise.reject(error)
          }
        }
      }

      return Promise.reject(err)
    }
  )
}

export {getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY}
