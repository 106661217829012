import {Link, useLocation} from 'react-router-dom'

type Props = {
  name: string
  to: string
  colsize: string
  icon?: string
  action?: any
}

const HeadButton = ({name, to, colsize, icon, action}: Props) => {
  return (
    <div className={`col-md-${colsize} mb-10 align-items-center`}>
      <Link onClick={action} className='btn btn-sm btn-outline' to={to}>
        <i className={`bi ${icon} fs-2 fw-bold text-black`}></i>
        {name}
      </Link>
    </div>
  )
}
export {HeadButton}
