import {Table} from '../../../../lib/table/Table'
import {ProductCategoryColumn, RaffleCategoryColumn} from '../../../../lib/table/components/Columns'
import {useEffect, useState} from 'react'
import {axiosGet} from '../../../../lib/helpers'
import {HeadButton} from '../../../../lib/widgets/HeadButton'
import {CreateProductCategory, CreateRaffleCategory} from '../../../../lib/modals/CreateActionModal'

const RaffleCategoryList = () => {
  const [data, setData] = useState<any[]>([])
  const [showModal, setShowModal] = useState<boolean>(false)

  useEffect(() => {
    ;(async () => {
      const result = await axiosGet({path: `raffle/category/list`})
      if (result.isSuccess) {
        setData(result.rafflecategories)
      }
    })()
  }, [])
  return (
    <>
      {showModal && (
        <CreateRaffleCategory show={showModal} handleClose={() => setShowModal(false)} />
      )}
      <div className='row d-flex justify-content-start'>
        <HeadButton
          colsize='2'
          name='New Category'
          to='#'
          action={() => setShowModal(true)}
          icon='bi-plus'
        />
      </div>
      <Table data={data} columns={RaffleCategoryColumn} noclick={false} />
    </>
  )
}
export {RaffleCategoryList}
