/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../helpers/AssetHelpers'
import {axiosGet, axiosPut, DateFormatter} from '../../../../app/lib/helpers'
import {KTIcon} from '../../../helpers/components/KTIcon'

const HeaderNotificationsMenu: FC = () => {
  const [count, setCount] = useState<any[]>([])

  useEffect(() => {
    ;(async () => {
      const result = await axiosGet({path: `event/system/list`})
      if (result.isSuccess) {
        setCount(result.events)
      }
    })()
  }, [])

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px'
      data-kt-menu='true'
    >
      <div
        className='d-flex flex-column bgi-no-repeat rounded-top'
        style={{backgroundImage: `url('${toAbsoluteUrl('/media/misc/menu-header-bg.jpg')}')`}}
      >
        <h3 className='text-white fw-bold px-9 mt-10 mb-6'>
          Notifications <span className='fs-8 opacity-75 ps-3'>{count.length} reports</span>
        </h3>
      </div>

      <div className='scroll-y mh-325px my-5 px-8'>
        {count.map((alert, index) => (
          <div key={`alert${index}`} className='d-flex flex-stack py-4'>
            <div className='d-flex align-items-center'>
              <div className='symbol symbol-35px me-4'></div>

              <div className='mb-0 me-2'>
                <Link
                  to={`/notifications/${alert.id}`}
                  className='fs-6 text-gray-800 text-hover-primary fw-bolder'
                >
                  {alert.event_name}
                </Link>
                <div className='text-gray-400 fs-7'>{alert.event_description}</div>
              </div>
            </div>

            <span className='badge badge-light fs-8'>{<DateFormatter date={alert.created} />}</span>
          </div>
        ))}
      </div>

      <div className='py-3 text-center border-top'>
        <Link to='/notifications/list' className='btn btn-color-gray-600 btn-active-color-primary'>
          View All <KTIcon iconName='arrow-right' className='fs-5' />
        </Link>
      </div>
    </div>
  )
}

export {HeaderNotificationsMenu}
