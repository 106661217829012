// @ts-nocheck
/* eslint-disable */
import React, {useEffect, useState, ReactPortal} from 'react'
import {createPortal} from 'react-dom'
import {axiosDelete, axiosGet, axiosPost} from '../../lib/helpers'
import {ReactMediaLibrary} from 'react-media-library'
import {Loader, LoaderTimout} from '../../lib/widgets/Loader'

type Props = {
  show: boolean
  hide: () => any
  selectCallback?: any | null
}

const portalRoot = document.getElementById('root-modals') || document.body

const Media = ({show, hide, selectCallback}: Props) => {
  const [fileLibraryList, setFileLibraryList] = useState<any[]>([])
  const [media, setMedia] = useState<any[]>([])
  const [upload, setUpload] = useState(false)

  const fetchMedia = async () => {
    const result = await axiosGet({path: `resources/list`})
    if (result && result.isSuccess) {
      const pictures: any[] = []
      for (const x in result.pictures) {
        const images = result.pictures[x]
        const filename = images.location.substring(images.location.lastIndexOf('/') + 1)
        pictures.push({
          _id: images.id,
          title: filename,
          createdAt: new Date(images.create),
          thumbnailUrl: images.location,
        })
      }
      setMedia(pictures)
    }
  }
  // useEffect(() => {
  //   // TODO Get file list from database
  //   if (upload) {
  //     fetchMedia()
  //   } else {
  //     fetchMedia()
  //   }
  //   setFileLibraryList(media)
  // }, [media])

  const fileUpload = async (fileBase64: any, fileMeta: any) => {
    const formData = new FormData()
    const blob = await (await fetch(fileBase64)).blob()
    formData.append(`pictures`, blob, fileMeta.fileName)
    const result = await axiosPost({path: `resources/image`, data: formData})
    selectCallback(result.images)
    if (result.isSuccess) {
      return {
        success: true,
      }
    } else {
      return {
        success: false,
      }
    }
  }

  const fileDelete = async (item: any) => {
    await axiosDelete({path: `resources/list/${item._id}`})
    return {
      success: true,
    }
  }
  async function uploadCallback(fileBase64: any, fileMeta: any): Promise<boolean> {
    // TODO Upload file to backend APIs
    const result = await fileUpload(fileBase64, fileMeta)
    console.log(result)
    if (result.success) {
      // New file uploaded
      setUpload(true)
      // TODO Reacquire new file list from database
      setFileLibraryList(media)

      // Return true to display upload success in modal
      return true
    } else {
      // Return false to display upload failed in modal
      return false
    }
  }

  async function deleteCallback(item: any) {
    // TODO Delete file from backend service
    const result = await fileDelete(item)
    if (result.success) {
      setUpload(true)
      setFileLibraryList(media)
    }
  }

  const renderPortal = (): React.ReactPortal => {
    return createPortal(
      <React.Fragment>
        <ReactMediaLibrary
          show={show}
          onHide={hide}
          fileUploadCallback={uploadCallback}
          fileLibraryList={fileLibraryList}
          fileSelectCallback={selectCallback}
          fileDeleteCallback={deleteCallback}
        />
      </React.Fragment>,
      portalRoot
    ) as React.ReactPortal
  }

  return renderPortal()
}
export {Media}
