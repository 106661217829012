import {TransactionsColumn, WithdrawReqColumn} from '../../../lib/table/components/Columns'
import {Table} from '../../../lib/table/Table'
import {useEffect, useState} from 'react'
import {axiosGet} from '../../../lib/helpers'

const PaymentRequests = () => {
  const [data, setData] = useState<any[]>([])

  useEffect(() => {
    ;(async () => {
      const result = await axiosGet({path: `transaction/list`})

      if (result.isSuccess) {
        const req = []
        for (const x in result.transactions) {
          if (result.transactions[x].type === 3) {
            req.push(result.transactions[x])
          }
        }
        setData(req)
      }
    })()
  }, [])
  return (
    <>
      <Table data={data} columns={WithdrawReqColumn} noclick={false} />
    </>
  )
}
export {PaymentRequests}
