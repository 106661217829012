import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {NotificationsList, NotificationsPushCreate} from './'
import {NotificationsDetails} from './components/NotificationsDetails'

const NotificationsPage = () => (
  <Routes>
    <Route element={<Outlet />}>
      <Route
        path='list'
        element={
          <>
            {/*<PageTitle breadcrumbs={[]}></PageTitle>*/}
            <NotificationsList />
          </>
        }
      />
      <Route
        path='push/create'
        element={
          <>
            {/*<PageTitle breadcrumbs={[]}></PageTitle>*/}
            <NotificationsPushCreate />
          </>
        }
      />
      <Route
        path=':id'
        element={
          <>
            {/*<PageTitle breadcrumbs={[]}></PageTitle>*/}
            <NotificationsDetails />
          </>
        }
      />
      <Route index element={<Navigate to='/notifications/list' />} />
    </Route>
  </Routes>
)
export {NotificationsPage}
