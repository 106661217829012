import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {OrdersList} from './components/OrdersList'
import {OrderDetails} from './index'

const OrdersPage = () => (
  <Routes>
    <Route element={<Outlet />}>
      <Route
        path='list'
        element={
          <>
            {/*<PageTitle breadcrumbs={[]}></PageTitle>*/}
            <OrdersList />
          </>
        }
      />{' '}
      <Route
        path=':id'
        element={
          <>
            <OrderDetails />
          </>
        }
      />
      <Route index element={<Navigate to='/orders/list' />} />
    </Route>
  </Routes>
)
export {OrdersPage}
