import React, {useEffect, useState} from 'react'
import {Table} from '../../../lib/table/Table'
import {axiosGet} from '../../../lib/helpers'
import {HeadButton} from '../../../lib/widgets/HeadButton'
import {CustomersColumn, RolesColumn, CountryColumn} from '../../../lib/table/components/Columns'
import {CreateRoleModal, CreateUserModal} from '../../../lib/modals/CreateActionModal'

const SettingsRoles = () => {
  const [data, setData] = useState<any[]>([])
  const [showModal, setShowModal] = useState<boolean>(false)
  useEffect(() => {
    ;(async () => {
      try {
        const result = await axiosGet({path: `user/role/list`})
        console.log(result)
        if (result.isSuccess) {
          setData(result.roles)
        }
      } catch (e) {}
    })()
  }, [])

  return (
    <>
      {showModal && <CreateRoleModal show={showModal} handleClose={() => setShowModal(false)} />}
      <div className='row d-flex justify-content-start'>
        <HeadButton
          colsize='2'
          name='New Role'
          to='#'
          action={() => setShowModal(true)}
          icon={`bi bi-plus`}
        />
      </div>
      <Table data={data} columns={RolesColumn} noclick={false} />
    </>
  )
}
const SettingsCountry = () => {
  const [data, setData] = useState<any[]>([])
  const [showModal, setShowModal] = useState<boolean>(false)
  useEffect(() => {
    ;(async () => {
      try {
        const result = await axiosGet({path: `resources/country/list`})
        setData(result.countries)
      } catch (e) {
        console.log(e)
      }
    })()
  }, [])

  return (
    <>
      {showModal && <CreateRoleModal show={showModal} handleClose={() => setShowModal(false)} />}
      <div className='row d-flex justify-content-start'>
        <HeadButton
          colsize='2'
          name='Add Country'
          to='#'
          action={() => setShowModal(true)}
          icon={`bi bi-plus`}
        />
      </div>
      <Table data={data} columns={CountryColumn} noclick={false} />
    </>
  )
}
const SettingsUsers = () => {
  const [data, setData] = useState<any[]>([])
  const [showModal, setShowModal] = useState<boolean>(false)
  useEffect(() => {
    ;(async () => {
      try {
        const result = await axiosGet({path: `user/super/list`})
        console.log(result)
        const users = []
        if (result.isSuccess) {
          for (const x in result.users) {
            if (result.users[x]?.role?.type === 1) {
              users.push(result.users[x])
            }
          }
          setData(users)
        }
      } catch (e) {}
    })()
  }, [])

  return (
    <>
      {showModal && <CreateUserModal show={showModal} handleClose={() => setShowModal(false)} />}
      <div className='row d-flex justify-content-start'>
        <HeadButton
          colsize='2'
          name='New Admin'
          to='#'
          action={() => setShowModal(true)}
          icon={`bi bi-plus`}
        />
        <HeadButton colsize='2' name='Roles' to='/settings/roles/list' />
        <HeadButton colsize='2' name='Country' to='/settings/country' />
      </div>

      <Table data={data} columns={CustomersColumn} noclick={false} />
    </>
  )
}
export {SettingsRoles, SettingsUsers, SettingsCountry}
