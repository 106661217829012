import {PageTitle} from '../../../../_metronic/layout/core'
import {Link, useParams} from 'react-router-dom'
import {useEffect, useState} from 'react'
import {axiosGet, DateFormatter, ProductStatusFormatter} from '../../../lib/helpers'
import {Loader, LoaderTimout} from '../../../lib/widgets/Loader'
import {Gallery, Item} from 'react-photoswipe-gallery'
import {Table} from '../../../lib/table/Table'
import {ParticipantsColumn, ResultColumn} from '../../../lib/table/components/Columns'

const RaffleDetails = () => {
  const {id} = useParams()
  console.log(id)
  const [data, setData] = useState<any>({})
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    ;(async () => {
      const result = await axiosGet({path: `raffle/list/${id}`})
      console.log(result)
      if (result.isSuccess) {
        setTimeout(() => {
          setIsLoading(false)
        }, LoaderTimout)
        setData(result.raffle)
      }
    })()
  }, [id])

  console.log(data)

  type lightBoxProps = {
    ref: any
    open: any
  }
  const count = data ? 1 : 0
  return (
    <>
      <div className='row mb-4'>
        <div className='col-md-4'>
          <Link to='/raffle/list' className='afri-text-secondary '>
            <i className='bi bi-arrow-left text-gray'></i> Back to Raffle{' '}
          </Link>
        </div>
      </div>

      {!isLoading && data ? (
        <div className='row'>
          {/*Order info*/}
          <div className='col-md-12 mb-lg-5 mb-4'>
            <div className='card card-bordered  border-1 shadow-none'>
              <div className='card-header d-flex flex-row justify-content-between align-items-center border-0'>
                <div className='card-title fw-bold fs-1 d-flex flex-column text-capitalize'>
                  <div> {data?.ticket_name} </div>
                </div>
                <div className='card-header-action d-flex flex-row justify-content-end align-items-center text-capitalize'>
                  <ProductStatusFormatter status={data?.status} />
                  <Link to={`/raffle/list/${data?.id}`} className='btn btn-outline ms-2'>
                    Edit
                  </Link>
                </div>
              </div>
              <div className='card-body'>
                <div className='row'>
                  <div className='col-md-10'>
                    <div className='row'>
                      <div className='col-md-2 d-flex flex-column '>
                        <span>Tracking</span>
                        <small className='afri-text-secondary '>{data?.ticket_tracking}</small>
                      </div>
                      <div className='col-md-3 d-flex flex-column '>
                        <span>Participants</span>
                        <small className='afri-text-secondary '>{data?.participants.length}</small>
                      </div>
                      <div className='col-md-5 d-flex flex-column'>
                        <span>Featured</span>
                        <small className='afri-text-secondary '>
                          {data?.featured ? `Yes` : `No`}
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-10 mt-5'>
                    <div className='row'>
                      <div className='col-md-3 d-flex flex-column'>
                        <span>Category</span>
                        <small className='afri-text-secondary '>{data?.category?.name}</small>
                      </div>
                      <div className='col-md-4 d-flex flex-column'>
                        <span>Created on</span>
                        <small className='afri-text-secondary '>
                          {<DateFormatter date={data?.created} />}
                        </small>
                      </div>
                      <div className='col-md-4 d-flex flex-column'>
                        <span>From - To</span>
                        <small className='afri-text-secondary '>
                          {<DateFormatter date={data?.start_date} />} -{' '}
                          {<DateFormatter date={data?.end_date} />}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*Summary*/}
          <div className='col-md-12 mb-lg-5 mb-4'>
            <div className='card  border-1 shadow-none'>
              <div className='card-header d-flex flex-row justify-content-between align-items-center border-0'>
                <div className='card-title fw-bold fs-1 d-flex flex-column'>Pictures</div>
              </div>
              <div className='card-body'>
                <div className='row d-flex flex-column align-items-start'>
                  {/*First part*/}

                  <div className='col-md-6 mb-5'>
                    <div className='row'>
                      {
                        <Gallery>
                          {data?.pictures?.length > 0 &&
                            data?.pictures.map((item: any) => (
                              <div className='col-md-4 '>
                                <Item
                                  original={item.location}
                                  thumbnail={item.location}
                                  width='640'
                                  height='740'
                                >
                                  {({ref, open}: lightBoxProps) => (
                                    <img
                                      ref={ref}
                                      onClick={open}
                                      src={item.location}
                                      className='h-100px'
                                    />
                                  )}
                                </Item>
                              </div>
                            ))}
                        </Gallery>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-12 mb-lg-5 mb-4'>
            <div className='card card-bordered  border-1 shadow-none'>
              <div className='card-header d-flex flex-row justify-content-between align-items-center border-0'>
                <div className='card-title fw-bold fs-1 d-flex flex-column'>Participants</div>
                <div className='card-header-action d-flex flex-row justify-content-between align-items-center'>
                  <Link
                    to='/raffle/participants'
                    className='btn btn-sm btn-outline bg-white border-1 ms-2'
                  >
                    View all
                  </Link>
                </div>
              </div>
              <div className='card-body'>
                <div className='row'>
                  <Table data={data?.participants.user} columns={ParticipantsColumn} />
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-12 mb-lg-5 mb-4'>
            <div className='card card-bordered  border-1 shadow-none'>
              <div className='card-header d-flex flex-row justify-content-between align-items-center border-0'>
                <div className='card-title fw-bold fs-1 d-flex flex-column'>Result</div>
                <div className='card-header-action d-flex flex-row justify-content-between align-items-center'>
                  <Link
                    to='/raffle/result'
                    className='btn btn-sm btn-outline bg-white border-1 ms-2'
                  >
                    View all
                  </Link>
                </div>
              </div>
              <div className='card-body'>
                <div className='row'>
                  <Table data={data?.result || []} columns={ResultColumn} />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='d-flex justify-content-center'>
          <Loader isError={count} errorMessage='error occurred' />
        </div>
      )}
    </>
  )
}

export {RaffleDetails}
