import {HeadButton} from '../../../lib/widgets/HeadButton'
import {EventsColumn} from '../../../lib/table/components/Columns'
import {Table} from '../../../lib/table/Table'
import {useEffect, useState} from 'react'
import {axiosGet} from '../../../lib/helpers'

const NotificationsList = () => {
  const [data, setData] = useState<any[]>([])

  useEffect(() => {
    ;(async () => {
      const result = await axiosGet({path: `event/system/list`})
      if (result.isSuccess) {
        setData(result.events)
      }
    })()
  }, [])
  return (
    <>
      <div className='row d-flex justify-content-start'>
        <HeadButton colsize='2' name='Push Notification' to='/notifications/push/create' />
        <HeadButton colsize='2' name='Notifications' to='/notifications/list' />
      </div>
      <Table data={data} columns={EventsColumn} />
    </>
  )
}
export {NotificationsList}
