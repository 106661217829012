import clsx from 'clsx'
import {FormStatus} from '../../../lib/models/Form'
import {useFormik} from 'formik'
import {axiosPost, axiosPut} from '../../../lib/helpers'
import * as Yup from 'yup'
import {useState} from 'react'

const NotificationsPushCreate = () => {
  const [loading, setLoading] = useState(false)

  const initialValues = {
    message: ``,
    whatsapp: ``,
    sms: ``,
    push: ``,
  }

  const path = `event/push/create`
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (values, {setSubmitting}) => {
      setLoading(true)
      const result = await axiosPost({
        path: path,
        data: {
          whatsapp: values.whatsapp,
          sms: values.sms,
          push: values.push,
          message: values.message,
        },
      })
      console.log(result)
      if (result.isSuccess) {
        setSubmitting(false)
        setLoading(false)
      } else {
        setSubmitting(false)
        setLoading(false)
      }
    },
  })
  return (
    <>
      <div className='card shadow-none'>
        <div className='card-header d-flex flex-column'>
          <div className='card-title fw-bold fs-1 mb-3'>Send Push Notification</div>
          <div className='card-head-extra d-flex flex-row justify-content-between align-items-center'>
            <h4 className='fs-4 fw-normal'>General information</h4>
            <i className='bi bi-plus fs-2'></i>
          </div>
        </div>
        <div className='card-body'>
          <div className='row'>
            <div className='col-md-10'>
              <form onSubmit={formik.handleSubmit}>
                <div className='fv-row mb-10 row'>
                  <div className='col-md-4 mb-10 mt-5'>
                    <div className='form-check form-switch'>
                      <input
                        {...formik.getFieldProps('whatsapp')}
                        className={clsx(
                          'form-check-input',
                          {'is-invalid': formik.touched.whatsapp && formik.errors.whatsapp},
                          {
                            'is-valid': formik.touched.whatsapp && !formik.errors.whatsapp,
                          }
                        )}
                        type='checkbox'
                        role='switch'
                        id='setwhatsapp'
                      />
                      <label className='form-check-label' htmlFor='setfeatured'>
                        Send to whatsapp
                      </label>
                    </div>
                    {formik.touched.whatsapp && formik.errors.whatsapp && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.whatsapp}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='col-md-4 mb-10 mt-5'>
                    <div className='form-check form-switch'>
                      <input
                        {...formik.getFieldProps('sms')}
                        className={clsx(
                          'form-check-input',
                          {'is-invalid': formik.touched.sms && formik.errors.sms},
                          {
                            'is-valid': formik.touched.sms && !formik.errors.sms,
                          }
                        )}
                        type='checkbox'
                        role='switch'
                        id='setsms'
                      />
                      <label className='form-check-label' htmlFor='setfeatured'>
                        Send to SMS
                      </label>
                    </div>
                    {formik.touched.sms && formik.errors.sms && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.sms}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='col-md-4 mb-10 mt-5'>
                    <div className='form-check form-switch'>
                      <input
                        {...formik.getFieldProps('push')}
                        className={clsx(
                          'form-check-input',
                          {'is-invalid': formik.touched.push && formik.errors.push},
                          {
                            'is-valid': formik.touched.push && !formik.errors.push,
                          }
                        )}
                        type='checkbox'
                        role='switch'
                        id='setpush'
                      />
                      <label className='form-check-label' htmlFor='setpush'>
                        Send to Push
                      </label>
                    </div>
                    {formik.touched.push && formik.errors.push && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.push}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='fv-row mb-10 row'>
                  <div className='col-md-10 col-lg-12'>
                    <div className='form-floating'>
                      <textarea
                        placeholder='Enter Message'
                        id='message'
                        style={{height: '100px'}}
                        {...formik.getFieldProps('message')}
                        className={clsx(
                          'form-control',
                          {'is-invalid': formik.touched.message && formik.errors.message},
                          {
                            'is-valid': formik.touched.message && !formik.errors.message,
                          }
                        )}
                        name='message'
                      ></textarea>
                      <label htmlFor='message'>Message</label>
                    </div>
                    {formik.touched.message && formik.errors.message && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.message}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='fv-row mb-10 row justify-content-start'>
                  <button
                    type='submit'
                    id='editprodcatbtn'
                    className='btn afri-btn-bg-primary w-50'
                    disabled={formik.isSubmitting || !formik.isValid}
                  >
                    {!loading && <span className='indicator-label'>Send message</span>}
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {NotificationsPushCreate}
