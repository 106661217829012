import {PageTitle} from '../../../../_metronic/layout/core'
import {Link, useParams} from 'react-router-dom'
import {useEffect, useState} from 'react'
import {
  axiosGet,
  CurrencyColumnFormater,
  DateFormatter,
  FormStatusFormatter,
  UserInfoCell,
} from '../../../lib/helpers'
import {Loader, LoaderTimout} from '../../../lib/widgets/Loader'
import {SingleOrderModel} from '../../../lib/models/Orders'
import {ProductAvailability} from '../../../lib/models/Product'
import {Gallery, Item} from 'react-photoswipe-gallery'
import {FormSelect} from 'react-bootstrap'
import {FormStatus} from '../../../lib/models/Form'
import {Table} from '../../../lib/table/Table'
import {OrdersColumn, TransactionsColumn} from '../../../lib/table/components/Columns'

const CustomerDetails = () => {
  const {id} = useParams()
  const [data, setData] = useState<any>({})
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    ;(async () => {
      const result = await axiosGet({path: `user/list/${id}`})
      if (result.isSuccess) {
        setTimeout(() => {
          setLoading(false)
        }, LoaderTimout)
        setData(result.profile)
      }
    })()
  }, [])

  console.log(data)

  type lightBoxProps = {
    ref: any
    open: any
  }
  console.log(data)
  const count = data ? 1 : 0
  return (
    <>
      <div className='row mb-4'>
        <div className='col-md-4'>
          <Link to='/products/list' className='afri-text-secondary '>
            <i className='bi bi-arrow-left text-gray'></i> Back to Customers
          </Link>
        </div>
      </div>

      {!loading && data ? (
        <div className='row'>
          {/*Order info*/}
          <div className='col-md-12 mb-lg-5 mb-4'>
            <div className='card card-bordered  border-1 shadow-none'>
              <div className='card-header d-flex flex-row justify-content-between align-items-center border-0'>
                <div className='card-title fw-bold fs-1 d-flex flex-column text-capitalize'>
                  <div>
                    {' '}
                    <UserInfoCell user={data} />{' '}
                  </div>
                  <small className='afri-text-secondary '>
                    Created on <DateFormatter date={data?.created} />{' '}
                  </small>
                </div>
                <div className='card-header-action d-flex flex-row justify-content-end align-items-center text-capitalize'>
                  <FormStatusFormatter status={data?.status} />
                  <Link to={`/customers/list/${data?.id}`} className='btn btn-outline ms-2'>
                    Edit
                  </Link>
                </div>
              </div>
              <div className='card-body'>
                <div className='row'>
                  <div className='col-md-10'>
                    <div className='row'>
                      <div className='col-md-3 d-flex flex-column border-end'>
                        <span>Email</span>
                        <small className='afri-text-secondary '>{data?.email}</small>
                      </div>
                      <div className='col-md-3 d-flex flex-column border-end'>
                        <span>Phone</span>
                        <small className='afri-text-secondary '>{data?.phone}</small>
                      </div>
                      <div className='col-md-3 d-flex flex-column border-end'>
                        <span>Verified</span>
                        <small className='afri-text-secondary '>
                          {data?.verified ? `Yes` : `No`}
                        </small>
                      </div>
                      <div className='col-md-3 d-flex flex-column border-end'>
                        <span>Wallet Balance</span>
                        <small className='afri-text-secondary '>
                          {<CurrencyColumnFormater amount={data?.wallet?.balance} />}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*Summary*/}
          <div className='col-md-12 mb-lg-5 mb-4'>
            <div className='card  border-1 shadow-none'>
              <div className='card-header d-flex flex-row justify-content-between align-items-center border-0'>
                <div className='card-title fw-bold fs-1 d-flex flex-column'>Orders</div>
              </div>
              <div className='card-body'>
                <Table data={data?.order} columns={OrdersColumn} />
              </div>
            </div>
          </div>
          <div className='col-md-12 mb-lg-5 mb-4'>
            <div className='card card-bordered  border-1 shadow-none'>
              <div className='card-header d-flex flex-row justify-content-between align-items-center border-0'>
                <div className='card-title fw-bold fs-1 d-flex flex-column'>Transactions</div>
              </div>
              <div className='card-body'>
                <Table data={data?.transaction} columns={TransactionsColumn} />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='d-flex justify-content-center'>
          <Loader isError={count} errorMessage='error occurred' />
        </div>
      )}
    </>
  )
}

export {CustomerDetails}
