export const UserPermissions = {
  orders_all: 'orders-all',
  orders_list: 'orders-list',
  orders_edit: 'orders-edit',
  orders_delete: 'orders-delete',
  products_all: 'product-all',
  products_create: 'product-create',
  products_list: 'product-list',
  products_edit: 'product-edit',
  products_delete: 'product-delete',
  products_category_all: 'product-category-all',
  products_category_create: 'product-category-create',
  products_category_delete: 'product-category-delete',
  products_category_edit: 'product-category-edit',
  products_category_list: 'product-category-list',
  //Users
  users_all: 'users-all',
  users_edit: 'users-edit',
  users_delete: 'users-edit',
  users_list: 'users-edit',
  //Tracking
  tracking_all: 'tracking-all',
  tracking_list: 'tracking-list',
  tracking_delete: 'tracking-delete',
  tracking_edit: 'tracking-edit',
  tracking_create: 'tracking-create',
  //Raffle
  raffle_all: 'raffle-all',
  raffle_list: 'raffle-list',
  raffle_create: 'raffle-create',
  raffle_edit: 'raffle-edit',
  raffle_delete: 'raffle-delete',
  raffle_category_all: 'raffle-catgeory-all',
  raffle_category_delete: 'raffle-catgeory-delete',
  raffle_categorylist: 'raffle-catgeory-list',
  raffle_category_edit: 'raffle-catgeory-edit',
  raffle_category_create: 'raffle-catgeory-create',
  notifications_all: 'notifications-all',
  payment_list: 'payment-list',
  payment_approve: 'payment-approve',
  payment_reject: 'payment-reject',
  payment_all: 'payment-all',
  media_all: 'media-all',
  setall: 'settings-all',
}
