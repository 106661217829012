export const FormStatus = [
  {
    statusCode: 1,
    name: `Enable`,
  },
  {
    statusCode: 0,
    name: `Disable`,
  },
]
