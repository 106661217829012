import React, {Fragment, useEffect, useState} from 'react'
import {HeadButton} from '../../lib/widgets/HeadButton'
import {Media} from './Media'
import {axiosDelete, axiosGet, axiosPost, sweetAlert} from '../../lib/helpers'
import clsx from 'clsx'
import {useFormik} from 'formik'

const PopupList = () => {
  const [showMedia, setShowMedia] = useState(false)
  const [popup, setPopup] = useState<any[]>([])
  const [isDelete, setIsDelete] = useState<boolean>(false)
  const fetchPopup = async () => {
    const req = await axiosGet({path: `resources/list?type=3`})
    if (req?.iSuccess) {
      setPopup(req?.resource)
    }
  }

  useEffect(() => {
    ;(async () => {
      if (isDelete) {
        fetchPopup()
      } else {
        fetchPopup()
      }
    })()
  }, [isDelete])

  const handleDelete = async (id: string) => {
    const req = await axiosDelete({path: `resources/list/${id}`})

    console.log(req)

    if (req?.isSuccess) {
      setIsDelete(true)
    }
  }
  return (
    <Fragment>
      <div className='row d-flex justify-content-start'>
        <HeadButton colsize='2' name='Create Ad' to='/media/popup/create' />
        <HeadButton colsize='2' name='Library' action={() => setShowMedia(true)} to='#' />
      </div>

      {showMedia && <Media show={showMedia} hide={() => setShowMedia(false)} />}

      <div className='row'>
        {popup.length > 0 ? (
          popup.map((item, i) => (
            <div className='col-md-4'>
              <div
                className='card shadown shadow-none rounded-0'
                style={{width: '18rem', height: 'auto', minHeight: '200px', minWidth: '200px'}}
              >
                <div
                  className='card-header d-flex justify-content-end p-0 '
                  style={{minHeight: '40px'}}
                >
                  <div className='dropdown p-0'>
                    <button
                      className='btn bg-transparent'
                      type='button'
                      data-bs-toggle='dropdown'
                      aria-expanded='false'
                    >
                      Delete
                    </button>
                    <ul className='dropdown-menu'>
                      <li>
                        <button
                          type={'button'}
                          onClick={() => handleDelete(item.id)}
                          className='dropdown-item'
                        >
                          Delete
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
                <img
                  src={item?.location}
                  className='card-img-top rounded-0'
                  style={{
                    minHeight: '200px',
                    minWidth: '200px',
                  }}
                  alt={`popup_${i}`}
                />
              </div>
            </div>
          ))
        ) : (
          <>
            <p>No Ads found.</p>
          </>
        )}
      </div>
    </Fragment>
  )
}
const CreateAd = () => {
  const [loading, setLoading] = useState(false)
  const [file, setFile] = useState<any>(null)
  const [showMedia, setShowMedia] = useState(false)
  const {handleSubmit, getFieldProps, isSubmitting, isValid, errors, touched} = useFormik({
    initialValues: {
      file: null,
    },
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setSubmitting(true)
      const data = new FormData()
      data.append('pictures', file, file.name)

      data.append('type', '3')

      const req = await axiosPost({path: `resources/image`, data})
      console.log(req)
      try {
      } catch (error) {
      } finally {
        setSubmitting(false)
        setLoading(false)
      }
    },
  })
  const handleChange = (e: any) => {
    setFile(e.target.files[0])
  }
  return (
    <Fragment>
      <div className='row d-flex justify-content-start'>
        <HeadButton colsize='2' name='Ads List' to='/media/popup/list' />
        <HeadButton colsize='2' name='Library' action={() => setShowMedia(true)} to='#' />
      </div>

      {showMedia && <Media show={showMedia} hide={() => setShowMedia(false)} />}
      <form
        onSubmit={handleSubmit}
        noValidate
        id='product_create_form'
        autoComplete='off'
        encType='multipart/form-data'
      >
        <div className='row'>
          <div className='card shadow-none'>
            <div className='card-header d-flex flex-column'>
              <div className='card-title fw-bold fs-1 mb-3'>Upload Popup</div>
            </div>
            <div className='card-body'>
              <div className='row mt-5'>
                <h5 className='mb-5 border-bottom'>Picture</h5>
                <div className='col-md-10 mb-10 '>
                  <div className='form-floating mb-3'>
                    <input
                      type='file'
                      id='tickname'
                      placeholder='Enter name'
                      {...getFieldProps('file')}
                      className={clsx(
                        'form-control',
                        {'is-invalid': touched.file && errors.file},
                        {
                          'is-valid': touched.file && !errors.file,
                        }
                      )}
                      style={{
                        height: '50px',
                      }}
                      name='file'
                      onChange={handleChange}
                    />
                  </div>
                  {touched.file && errors.file && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{errors.file}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='col-md-10 mb-10 '>
                  <button
                    disabled={isSubmitting || !isValid}
                    className='btn me-2 afri-btn-bg-primary'
                    data-status={1}
                  >
                    {!loading && <span className='indicator-label'>Publish</span>}
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Fragment>
  )
}

export {PopupList, CreateAd}
