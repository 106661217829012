import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {CreateAd, PopupList} from './Popup'
import {PageTitle} from '../../../_metronic/layout/core'

const MediaPage = () => (
  <Routes>
    <Route element={<Outlet />}>
      <Route
        path='popup/list'
        element={
          <>
            <PopupList />
          </>
        }
      />{' '}
      <Route
        path='popup/create'
        element={
          <>
            <PageTitle breadcrumbs={[]}>Add Popup</PageTitle>
            <CreateAd />
          </>
        }
      />
      <Route index element={<Navigate to='/popup/list' />} />
    </Route>
  </Routes>
)
export {MediaPage}
